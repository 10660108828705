import React, { useEffect, useState, useContext } from 'react';
import { Divider, Message } from 'semantic-ui-react';

import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authContext';
import { cfgToast } from '../../myFunc';
//import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';
import { Button as ButtonPrime } from 'primereact/button';
import { Card } from 'primereact/card';
import useAuth from '../../hooks/useAuth';
//import { InputMask } from 'primereact/inputmask';
//import GetUserPower from '../Orders/GetUserPower';
//import GetFreeDays from '../Orders/GetFreeDays';
import GetWarehouse from '../Orders/GetWarehouse';


function AppCurrentEditWorker(props) {
   const authContext = useContext(AuthContext);
   const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
   const [user_id, setuser_id] = useState(0);
   
   const defaultValues = {
      user_id: 0,
      surname: "",
      name: "",
      email: "",
      phone: ""

   }
   const defaultValuesPassword = {
      password: "",
      password2: "",
      user_id: 0,

   }

   const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });// eslint-disable-line no-unused-vars
   const { control: control2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, setValue: setValue2 } = useForm({ defaultValuesPassword });

   const [isloading, setisloading] = useState(false);// eslint-disable-line no-unused-vars
   const [isloadingPassword, setisloadingPassword] = useState(false);
   const [ErrorPassword, setErrorPassword] = useState(false);

   const getFormErrorMessage = (name) => {// eslint-disable-line no-unused-vars
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };

   const getFormErrorMessage2 = (name) => {
      return errors2[name] && <small className="p-error">{errors2[name].message}</small>
   };

   function getCurrentUserData() {
      ConnectWS("/users/me", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error("Brak autoryzacji 401", cfgToast);
         } else if (Number(response.status) === 200) {
            setValue("email", response.data.email)
            setValue("name", response.data.name)
            setValue("surname", response.data.surname)
            setValue("user_id", response.data.user_id)
            setuser_id(response.data.user_id)
            setValue("phone", response.data.phone)
            setValue2("user_id", response.data.user_id)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
      })
   }

   useEffect(() => {
      getCurrentUserData()

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps


   const submit = (data) => {// eslint-disable-line no-unused-vars
      setisloading(true)

      ConnectWS("/users/me/change_data", "put", authContext.tokenApp, null, data, function (response, logout) {
         if (logout === 1) {
            toast.error("Brak autoryzacji 401", cfgToast);
         } else if (Number(response.status) === 200) {
            let userInfo = { id: data.user_id, name:  data.name + " " + data.surname }
            setAuth(true, null, userInfo);
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp + 1)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })


   }

   const submitPassword = (data) => {
      setisloadingPassword(true)
      if (data.password === data.password2) {
         setErrorPassword(false)

         ConnectWS("/users/me/change_password", "put", authContext.tokenApp, null, data, function (response, logout) {
            if (logout === 1) {
               toast.error("Brak autoryzacji 401", cfgToast);
            } else if (Number(response.status) === 200) {
               toast.success(response.data.detail, cfgToast);
               authContext.changeUpdataApp(authContext.updataApp + 1)
            } else {
               toast.error(response, cfgToast);
               console.log(response);
            }
            setisloadingPassword(false)
         })


      } else {
         setErrorPassword('Hasła różnią się od siebie!')
         setisloadingPassword(false)
      }

   }

   const passwordFooter = (
      <React.Fragment>
         <Divider />
         <p className="mt-2">Wymagane</p>
         <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
            <li>Minimum 8 znaków</li>
            <li>Dopuszczalne znaki specjalne .!#@$&?</li> 
         </ul>
      </React.Fragment>
   );

   return (
      <>
      {/*
         <Card className="bgcard">
            <form onSubmit={handleSubmit(submit)} className="p-fluid">

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-edit" />
                     <Controller name="name" control={control} rules={{ required: 'Imie jest wymagane' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Imie*</label>
                  </span>
                  {getFormErrorMessage('name')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-edit" />
                     <Controller name="surname" control={control} rules={{ required: 'Nazwisko jest wymagane' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="surname" className={classNames({ 'p-error': errors.surname })}>Nazwisko*</label>
                  </span>
                  {getFormErrorMessage('surname')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-phone" />
                     <Controller name="phone" control={control} render={({ field, fieldState }) => (
                     <InputMask mask="999-999-999" slotChar="xxx-xxx-xxx" id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })}></InputMask>
                     )} />
                     <label htmlFor="phone" className={classNames({ 'p-error': errors.phone })}>Telefon</label>
                  </span>
                  {getFormErrorMessage('phone')}
               </div>

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-envelope" />
                     <Controller name="email" control={control}
                        rules={{ required: 'Email jest wymagany.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Błędny email, przykład: example@email.com' } }}
                        render={({ field, fieldState }) => (
                           <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                     <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>Email*</label>
                  </span>
                  {getFormErrorMessage('email')}
               </div>


               <ButtonPrime loading={isloading} type="submit" label="Zapisz dane" className="mt-2 p-button" />
            </form>


         </Card>
                        */}
         <Divider style={{ marginTop: 15, marginBottom: 15 }} horizontal>
            <span className='dc'>ZMIANA HASŁA</span>  </Divider>
         <Card className="bgcard">
            <form onSubmit={handleSubmit2(submitPassword)} className="p-fluid">


               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">
                     <Controller name="password" control={control2}
                        rules={{ required: 'Hasło jest wymagane.', pattern: { value: /^[A-Z0-9.!#@$&?]{8,20}$/i, message: 'Hasło nie spełnia wymagań' } }}
                        render={({ field, fieldState }) => (

                           <Password promptLabel="Wpisz Hasło" weakLabel="Słabe hasło" mediumLabel="Średnie hasło" strongLabel="Śilne hasło" {...field} ref={(ref) => { }} feedback={true} footer={passwordFooter} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} />

                        )}
                     />
                     <label htmlFor="password" className={classNames({ 'p-error': errors2.password })}>Hasło*</label>
                  </span>
                  {getFormErrorMessage2('password')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">
                     <Controller name="password2" control={control2}
                        rules={{ required: 'Hasło jest wymagane.', pattern: { value: /^[A-Z0-9.!#@$&?]{8,20}$/i, message: 'Hasło nie spełnia wymagań' } }}

                        render={({ field, fieldState }) => (

                           <Password promptLabel="Wpisz Hasło" weakLabel="Słabe hasło" mediumLabel="Średnie hasło" strongLabel="Śilne hasło" id={field.name} {...field} ref={(ref) => { }} feedback={true} footer={passwordFooter} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} />

                        )}
                     />
                     <label htmlFor="password2" className={classNames({ 'p-error': errors2.password2 })}>Powtórz Hasło*</label>
                  </span>
                  {getFormErrorMessage2('password2')}
               </div>

               {ErrorPassword !== false ? <Message error
                  icon='exclamation circle'
                  header={ErrorPassword}

               /> : ""}

               <ButtonPrime loading={isloadingPassword} type="submit" label="Zmiana Hasła" className="mt-2 p-button" />
            </form>
         </Card>

        {/* 
         <Divider style={{ marginTop: 15, marginBottom: 15 }} horizontal>
            <span className='dc'>URAWNIENIA</span>  </Divider>
         <GetUserPower user_id={user_id} minHeight={500} />
         <Divider style={{ marginTop: 15, marginBottom: 15 }} horizontal>
            <span className='dc'>DNIWOLNE</span>  </Divider>
         <GetFreeDays user_id={user_id} minHeight={500} />
         */} 
         <Divider style={{ marginTop: 15, marginBottom: 15 }} horizontal>
            <span className='dc'>MAGAZYN</span>  </Divider>
         <GetWarehouse user_id={user_id} minHeight={500} />
         
      </>
   );
}

export default AppCurrentEditWorker;