import React, { useState, useEffect } from 'react';

import {  Header, Icon,  Segment } from 'semantic-ui-react';

import GetWarehouse from '../../Orders/GetWarehouse';
import GetFreeDays from '../../Orders/GetFreeDays';
import GetUserPower from '../../Orders/GetUserPower';


function InfoWorker(props) {

   const [InfoWorker, setInfoWorker] = useState(null);


   useEffect(() => {
      if (props.userInfo) {
         setInfoWorker(props.userInfo)
      }

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [props.userInfo]);// eslint-disable-line react-hooks/exhaustive-deps



   return (
      <>
         <Segment textAlign='center' basic style={{ margin: 0, padding: 0 }}>
            <Header as='h4' icon>
               <Icon name='user' />
               {InfoWorker && InfoWorker.allInfo && InfoWorker.allInfo.name ? InfoWorker.allInfo.name : "n/n"} {InfoWorker && InfoWorker.allInfo && InfoWorker.allInfo.surname ? InfoWorker.allInfo.surname : "n/n"}
            </Header>
         </Segment>
         <GetUserPower user_id={InfoWorker && InfoWorker.allInfo && InfoWorker.allInfo.user_id ? InfoWorker.allInfo.user_id : 0} minHeight={500} />
         <GetFreeDays user_id={InfoWorker && InfoWorker.allInfo && InfoWorker.allInfo.user_id ? InfoWorker.allInfo.user_id : 0} minHeight={500} />
         <GetWarehouse user_id={InfoWorker && InfoWorker.allInfo && InfoWorker.allInfo.user_id ? InfoWorker.allInfo.user_id : 0} minHeight={500} />

      </>
   );
}

export default InfoWorker;