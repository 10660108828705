import React, {  useState, useEffect } from 'react'
import { MapContainer, TileLayer, Marker, Popup,useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'
import "leaflet-defaulticon-compatibility";
import { useResizeDetector } from 'react-resize-detector';

function GetMaps(props) {
  const [myLocation, setmyLocation] = useState(null)

  useEffect(() => {
    if(props.myLocation){

      setmyLocation(props.myLocation)
    }
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [props.myLocation]);// eslint-disable-line react-hooks/exhaustive-deps


  const ResizeMap = () => {
    const map = useMap();
    map.invalidateSize();
    return null;
  };
  const { ref } = useResizeDetector();

  return (
    myLocation!==null ?
    <div ref={ref} style={{ height: "100%", width: "100%", zIndex: 2 }}>
      <MapContainer center={myLocation} zoom={16} scrollWheelZoom={false} style={{ height: "100%", width: "100%" }}>
      <ResizeMap />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
        <Marker
          position={myLocation}
          draggable={false}
          animate={true}
        >
          <Popup>
            Moja pozycja {myLocation[0]+" "+myLocation[1]}
          </Popup>
        </Marker>
      </MapContainer>
    </div>
    :""
  );
}

export default GetMaps;

