import React, { Suspense,useEffect,useContext, useRef } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import NotFound from './Ui/404';
import Login from '../components/Login/Login';
import Layout from './Layout';
import AuthContext from '../context/authContext';
import { SCezardecrypt, cfgToast } from '../myFunc';
import { refreshToken } from '../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';


function RouterApp() {
    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
    const authContext = useContext(AuthContext);

    let interval = useRef(null);
    useEffect(() => { 
        interval.current = setInterval(() => {
            if(authContext.tokenApp){
                refreshToken(authContext.tokenApp, function (response, appstatus) {
                    if (appstatus === 1) {
                        setAuth(true, response);
                    } else {
                        console.log(response)
                        toast.error(response, cfgToast);
                        clearInterval(interval.current)
                        setAuth(false);
                    }
                  
                });
            }  
        }, 600000);//600000
        checkUser()
        return () => { clearInterval(interval.current) }; // use effect cleanup to set flag false, if unmounted
    }, [authContext.tokenApp]);// eslint-disable-line react-hooks/exhaustive-deps

    const checkUser = () => {
        
        let token = window.sessionStorage.getItem('token');
        let appView = JSON.parse(window.sessionStorage.getItem('appView'));
        let userInfo = window.sessionStorage.getItem('userInfo');
        if(userInfo){
            userInfo = JSON.parse(SCezardecrypt(userInfo))
        }
        if (token) {          
           // toast.info('Pomyślne wylogowanie z aplikacji', cfgToast);
            if (appView) {
                authContext.changeAppView(appView);
            }  
            setAuth(true, token,userInfo);
        }
    }

    return (

            <Suspense fallback={<p>Ładowanie</p>}>
                <Routes>

                    <Route path="/dashboard" element={
                        <>
                            {auth ?
                                <Layout /> : <Navigate to="/login" replace />} </>} />
                    <Route path="/login" element={auth ? <Navigate to="/dashboard" replace /> : <Login />} />
                    <Route path="/" element={auth ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />} />
                    <Route path='*' element={<NotFound />} />



                </Routes>
            </Suspense>

     

    )
}

export default RouterApp;