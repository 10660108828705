import React, { useEffect, useState, useContext } from 'react';
import { Segment } from 'semantic-ui-react';

import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authContext';
import { cfgToast, getColorStatus } from '../../myFunc';


import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const localizer = momentLocalizer(moment)

function AppCurrentWorkerCalendar(props) {
   const authContext = useContext(AuthContext);
   const [myEventsList, setmyEventsList] = useState([]);// eslint-disable-line no-unused-vars





   function getCalendarInfo() {
      let date_from = "2020-01-01"
      let date_to = "2100-01-01"
      ConnectWS("/users/calendar", "get", authContext.tokenApp, null, { date_from: date_from, date_to: date_to }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {

            if (response.data.length > 0) {
               let arrtmp = [];
               response.data.forEach((row, i) => {
                  arrtmp.push({
                     title: row.type === 1 ? row.name ? row.name : "ZLECENIE" : row.name ? "URLOP - " + row.name : "URLOP",
                     allDay: false,
                     start: moment(row.start_time).toDate(),
                     end: moment(row.end_time).toDate(),
                     colorEvento: row.type === 1 ? getColorStatus(row.status) : "#704f9e",
                     color: 'white'
                  })
            
               })
               setmyEventsList(arrtmp)
            }

         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }


      })


   }

   useEffect(() => {
      getCalendarInfo(props.user_id)


      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps



   return (

      <Segment>
         <Calendar
            localizer={localizer}
            events={myEventsList}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            messages={{
               next: "Następny",
               previous: "Poprzedni",
               today: "Dzisiaj",
               month: "Miesiąc",
               week: "Tydzień",
               day: "Dzień"
            }}
            eventPropGetter={(myEventsList) => {
               const backgroundColor = myEventsList.colorEvento ? myEventsList.colorEvento : 'blue';
               const color = myEventsList.color ? myEventsList.color : 'blue';
               return { style: { backgroundColor, color } }
            }}
         />
      </Segment>

   );
}

export default AppCurrentWorkerCalendar;