import dateFormat from 'dateformat';
import { Icon, Segment } from 'semantic-ui-react';

export const cfgToast = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}
export const rowHeightAGG = 40;

export const cfgToastFirebase = {
    position: "top-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}
export const smallViewTel = 770

export function checkTimeVal(DateFrom, DateTo) {
 
    if ((Date.parse(DateTo) - Date.parse(DateFrom)) <= 0) {
      return true;
    } else {
      return false;
    }
  
  }

export function changeFormatDate(mydate) {

    mydate ? mydate = dateFormat(mydate, "yyyy-mm-dd") : mydate = ""
    return mydate;

}
export function changeFormatDateTime(mydate) {

    mydate ? mydate = dateFormat(mydate, "yyyy-mm-dd'T'HH:MM:ss") : mydate = ""
    return mydate;

}
export function changeFormatDateTimeDB(mydate) {

    mydate ? mydate = dateFormat(mydate, "yyyy-mm-dd HH:MM:ss") : mydate = ""
    return mydate;

}
export function changeFormatDateTimeDBns(mydate) {

    mydate ? mydate = dateFormat(mydate, "yyyy-mm-dd HH:MM") : mydate = ""
    return mydate;

}
export let loaderAPP = <Segment basic textAlign='center'><Icon loading name='spinner' size='large' color="blue" /></Segment> 

export function SCezarencrypt(text, shift = 7) {
    var result = "";


    //loop through each caharacter in the text
    for (var i = 0; i < text.length; i++) {

        //get the character code of each letter
        var c = text.charCodeAt(i);

        // handle uppercase letters
        if (c >= 65 && c <= 90) {
            result += String.fromCharCode((c - 65 + shift) % 26 + 65);

            // handle lowercase letters
        } else if (c >= 97 && c <= 122) {
            result += String.fromCharCode((c - 97 + shift) % 26 + 97);

            // its not a letter, let it through
        } else {
            result += text.charAt(i);
        }
    }

    return result;
}

export function SCezardecrypt(text, shift = 7) {
    var result = "";
    shift = (26 - shift) % 26;
    result = SCezarencrypt(text, shift);
    return result;
}


export function getDateTimeE(co, mydate) {
    if (co === "TIME") {
        return dateFormat(mydate, "HH:MM:ss")
    } else {
        return dateFormat(mydate, "yyyy-mm-dd")
    }
}


export function returnWorkerTab(arr){
    let tmparr = []
    if(arr.length>0){
        arr.forEach((row, i) => {
            if(row.type===1){
                tmparr.push(row)
            }
        })
    } 
    return tmparr;
}

export function getColorStatus(status_id){
  
    switch (Number(status_id)) {  
        case 1: return "#2196f3"; // nowe zlecenie
        case 2: return "#2196f3"; // przyjęte
        case 3: return "#1fb53a"; // W realizacji
        case 4: return "#c95d1e"; // zawieszone
        case 5: return "#edc532"; // zakonczone
        case 6: return "#9e4441"; // zwrócone
        case 7: return "#919191"; // Zatwierdzone
        default: return "#b8b894";
    }
}

export function secondsToHms(s) {    
    var seconds = s % 60
    var minutes = ((s - s % 60) / 60) % 60   
    var hours = (s - s % 3600) / 3600   

    return `${hours.toString().padStart(2, '0') }:${minutes.toString().padStart(2, '0') }:${seconds.toString().padStart(2, '0') }`
}