import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Graph from "react-vis-network-graph"
import { Segment } from 'semantic-ui-react';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import AuthContext from '../../context/authContext';
import { cfgToast } from '../../myFunc';

function SwitchTopology(props) {
   const authContext = useContext(AuthContext);
   const [graph, setgraph] = useState(null);
   const [options, setoptions] = useState(null);
   const [loading, setloading] = useState(false);
   

   const events = {
      select: function (event) {
         var { nodes, edges } = event;// eslint-disable-line no-unused-vars
         //alert("Przełacznica: " + nodes);
      }
   };

   function getData(){
      setgraph(null)
      setloading(true)
      ConnectWS("/infiber/topology", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            
            if(response.data && response.data.nodes){
               setgraph(response.data)
            }
            
          
         } else if (Number(response.status) === 204) {

         } else {

            toast.error(response, cfgToast);
            console.log(response);
         }
         setloading(false)

      })
   }

   useEffect(() => {
      getData()
      /*
      setgraph({
         nodes: [
            { id: 1, label: "1121", title: "Przełacznica id: 112" },
            { id: 2, label: "1122", title: "Przełacznica id: 112" },
            { id: 3, label: "1123", title: "Przełacznica id: 112" },
            { id: 4, label: "1124", title: "Przełacznica id: 112" },
            { id: 5, label: "1125", title: "Przełacznica id: 112" }
         ],
         edges: [
            { from: 1, to: 2, label: "6" },
            { from: 2, to: 5, label: "2" },
            { from: 1, to: 3, label: "2" },
            { from: 2, to: 4, label: "3" }
   
         ]
      })
      */
      setoptions({
         layout: {
            hierarchical: true
         },
         edges: {
            color: "#000000"
         },height: props.minHeight ?  props.minHeight-70 : 200
      })
   }, [props.minHeight]);// eslint-disable-line react-hooks/exhaustive-deps


   return (
      <Segment loading={loading}>
         {graph ? 
         <Graph
            graph={graph}
            options={options}
            events={events}
          
         />
         : "BRAK URZĄDZEŃ"}
      </Segment>
   );
}

export default SwitchTopology;