import React, {  useEffect, useContext } from 'react';

import { cfgToast } from '../../../myFunc';

import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import AuthContext from '../../../context/authContext';
import { FileUpload } from 'primereact/fileupload';

import { toast } from 'react-toastify';


function AddFileCommisionToCommision(props) {
   const authContext = useContext(AuthContext);
   
   useEffect(() => {

     
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [props.dataAddFiles]);// eslint-disable-line react-hooks/exhaustive-deps

   const customBase64Uploader = async (event) => {
      if(event.files.length>0){
         event.files.forEach((row)=>{
            callbackPhoto(row)
         })
      }
      
      function callbackPhoto(nfile){
         
         var bodyFormData = new FormData(); 
         bodyFormData.append('commision_id', props.commision_id);
         bodyFormData.append('file_name', nfile.name ? nfile.name : "jakasnazwa");
         bodyFormData.append('file', nfile);

         ConnectWS("commisions/files_worker", "post", authContext.tokenApp, bodyFormData, null, function (response, logout) {
            if (logout === 1) {
               toast.error(response, cfgToast);
            } else if (Number(response.status) === 200) {       
               toast.success(response.data.detail, cfgToast);
               authContext.changeUpdataApp(authContext.updataApp + 1)
            } else {
               toast.error(response, cfgToast);
               console.log(response);
            }
   
         })
           
      } 
      
  }
 

   return (
      <>
        <FileUpload  multiple customUpload uploadHandler={customBase64Uploader} />
      </>
   );
}

export default AddFileCommisionToCommision;