import React, {  useEffect, useContext } from 'react';

import { cfgToast } from '../../../myFunc';

import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import AuthContext from '../../../context/authContext';
import { FileUpload } from 'primereact/fileupload';
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify';


function AddFileCommision({setopenAddFiles,dataAddFiles}) {
   const authContext = useContext(AuthContext);
   
   useEffect(() => {

      
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [dataAddFiles]);// eslint-disable-line react-hooks/exhaustive-deps

   const resizeFile = (file) =>
      new Promise((resolve) => {
         Resizer.imageFileResizer(
            file,
            1000,
            1000,
            "JPEG",
            100,
            0,
            (uri) => {
               resolve(uri);
            },
            "file"
         );
      });


   const customBase64Uploader = async (event) => {
      // convert file to base64 encoded
      const file = event.files[0];
      try {
         if (file.type.includes("image")) {
            const image = await resizeFile(file);
            callbackPhoto(image)
         } else {
            callbackPhoto(file)
         }

      } catch (err) {
         callbackPhoto(file)
      }


      function callbackPhoto(newFileUri) {
         let jsonOBJtmp = { ...dataAddFiles }
         jsonOBJtmp.file_path = newFileUri

         var bodyFormData = new FormData();
         bodyFormData.append('commision_file_id', jsonOBJtmp.commision_file_id);
         bodyFormData.append('file_name', jsonOBJtmp.file_name);
         bodyFormData.append('file', newFileUri);

         ConnectWS("commisions/upload_file", "post", authContext.tokenApp, bodyFormData, null, function (response, logout) {
            if (logout === 1) {
               toast.error(response, cfgToast);
            } else if (Number(response.status) === 200) {
               setopenAddFiles(false)
               toast.success(response.data.detail, cfgToast);
               authContext.changeUpdataApp(authContext.updataApp + 1)
            } else {
               toast.error(response, cfgToast);
               console.log(response);
            }

         })

      }

   }


   return (
      <>
         Dodaj plik: {dataAddFiles ? dataAddFiles.description : ""}
         <FileUpload accept="image/*" customUpload uploadHandler={customBase64Uploader} />

      </>
   );
}

export default AddFileCommision;