import React, {  useEffect } from 'react';



import {  Header, Icon, Segment } from 'semantic-ui-react';

import GetWarehouse from '../../Orders/GetWarehouse';


function InfoClient(props) {





   useEffect(() => {
    
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps





   return (
      <>
         <Segment textAlign='center' basic style={{ margin: 0, padding: 0 }}>
            <Header as='h4' icon>
               <Icon name='users' />
               {props.userInfo.allInfo.name} {props.userInfo.allInfo.surname}

            </Header>
         </Segment>


         <GetWarehouse client_id={props.userInfo.allInfo.client_id} minHeight={500}/>
      </>
   );
}

export default InfoClient;