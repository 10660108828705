import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { InputText } from 'primereact/inputtext';


function AGGridActivity(props) {
    const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
    const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars


    useEffect(() => {

        setGridApi(props.gridApi)
        setGridColumnApi(props.gridColumnApi)


        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [props.gridApi, props.gridColumnApi]);// eslint-disable-line react-hooks/exhaustive-deps




    const onBtnExport = useCallback((gridApi) => {
        gridApi.exportDataAsCsv({
            skipColumnGroupHeaders: false,
            skipColumnHeaders: false,
        });
    }, []);


    function quickSearch(val, appGrid) {
        if (val.length > 1) {
            appGrid.setQuickFilter(val);
        } else {
            appGrid.setQuickFilter(null);
        }

    }

    const sizeToFit = useCallback((appGrid) => {
        appGrid.sizeColumnsToFit();
    }, []);

    const autoSizeAll = useCallback((skipHeader, appGrid) => {
        const allColumnIds = [];
        appGrid.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        appGrid.autoSizeColumns(allColumnIds, skipHeader);
    }, []);// eslint-disable-line 




    return (
        <Grid style={{ margin: 0, paddingTop: 5 }}>
            <Grid.Row className='clearmp'>
                <Grid.Column width={9} className='clearmp'>
                    {props.quickSearchHidden === true ? "" :
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText style={{ width: "100%" }} id="lefticon" placeholder="Szukaj" onChange={(e) => quickSearch(e.target.value, gridApi)} />
                        </span>}
                </Grid.Column>
                <Grid.Column width={7} textAlign="right" className='clearmp'>

                    <Icon bordered name="chevron left" className='cursorPointer iconbutton' onClick={() => sizeToFit(gridApi)} />
                    <Icon bordered name="chevron right" className='cursorPointer iconbutton' onClick={() => autoSizeAll(false, gridColumnApi)} />
                    <Icon bordered name="download" title="POBIERZ PLIK CSV" className='cursorPointer iconbutton' onClick={() => onBtnExport(gridApi)} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default AGGridActivity;