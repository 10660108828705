

/*

 <Card style={{ marginBottom: 10 }}>
       <AGGrid AGGridData={[
        {make: "Toyota", model: "Celica", price: 35000, adadadadadadadadadadad:"asdasdasdasd" ,ba: "asdasdasd"},
        {make: "Ford", model: "Mondeo", price: 32000, adadadadadadadadadadad:"asdasdasdasd" ,ba: "asdasdasd"},
        {make: "Porsche", model: "Boxster", price: 72000, adadadadadadadadadadad:"asdasdasdasd" ,ba: "asdasdasd"},
        {make: "Toyota", model: "Celica", price: 35000, adadadadadadadadadadad:"asdasdasdasd" ,ba: "asdasdasd"},
        {make: "Ford", model: "Mondeo", price: 32000, adadadadadadadadadadad:"asdasdasdasd" ,ba: "asdasdasd"},
        {make: "Porsche", model: "Boxster", price: 72000, adadadadadadadadadadad:"asdasdasdasd" ,ba: "asdasdasd"},
        {make: "Toyota", model: "Celica", price: 35000, adadadadadadadadadadad:"asdasdasdasd" ,ba: "asdasdasd"},
        {make: "Ford", model: "Mondeo", price: 32000, adadadadadadadadadadad:"asdasdasdasd" ,ba: "asdasdasd"},
        {make: "Porsche", model: "Boxster", price: 72000, adadadadadadadadadadad:"asdasdasdasd" ,ba: "asdasdasd"},
        {make: "Toyota", model: "Celica", price: 35000, adadadadadadadadadadad:"asdasdasdasd" ,ba: "asdasdasd"},
        {make: "Ford", model: "Mondeo", price: 32000, adadadadadadadadadadad:"asdasdasdasd" ,ba: "asdasdasd"},
        {make: "Porsche", model: "Boxster", price: 72000, adadadadadadadadadadad:"asdasdasdasd" ,ba: "asdasdasd"},
    ]} GridHeight={300} AGGridDataColumnDef={[{ field: 'make', filter: 'agMultiColumnFilter'},
    { field: 'make', filter: 'agDateColumnFilter'},
    { field: 'price', filter: 'agNumberColumnFilter'},
    { field: 'adadadadadadadadadadad', filter: 'agNumberColumnFilter'},
    { field: 'ba', filter: 'agNumberColumnFilter'}]}/>
          </Card>
          */

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { cfgToast, changeFormatDate } from '../../myFunc';
import {  Grid, Icon, Label } from 'semantic-ui-react';
import { Button as ButtonPrime } from 'primereact/button';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authContext';
import { SelectButton } from 'primereact/selectbutton';
import { AG_GRID_LOCALE_PL } from '../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import AGGridActivity from '../AGGrid/AGGridActivity';
import { filterParams } from '../AGGrid/functions';
import { confirmAlert } from 'react-confirm-alert';

function SwitchAlarms(props) {

    const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
    const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
    const [rowData, setRowData] = useState(null);

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const statusEvent = props => {
        switch (props.data.active) {
            case 1: return <Label style={{ width: 80, height: 25, fontSize: 10, textAlign: "center" }} color='red'>ALARM</Label>;
            default: return <Label style={{ width: 80, height: 25, fontSize: 10, textAlign: "center", padding: 2 }} color='grey'>ALARM <br /> obsłużony</Label>;
        }


    };
    const sendedNotification = props => {
        switch (props.data.notification) {
            case 1: return <Icon name="mail" color='green' size='large' title="Powiadomienie wysłane" />;
            default: return <Icon name="mail" color='grey' size='large' title="Powiadomienie niewysłane" />
        }


    };

    

    const clikcAlarm = props => {


        const clikcLedA = () => {
            confirmAlert({
                title: 'Czy chcesz potwierdzić obsługę alarmu?',
                message: 'Proszę potwierdzić',
                buttons: [
                    {
                        label: 'Tak',
                        onClick: () => clikcLedACC()
                    },
                    {
                        label: 'Nie',
                        onClick: () => ''
                    }
                ]
            });

            function clikcLedACC() {
                ConnectWS("/infiber/ports", "put", authContext.tokenApp, null, null, function (response, logout) {
                    if (logout === 1) {
                    } else if (Number(response.status) === 200) {


                    } else {

                        toast.error(response, cfgToast);
                        console.log(response);
                    }
                    getData()


                })
            }



        };

        return props.data.active === 1 ? <Icon name="alarm" color='red' size='large' title="Obsłuż alarm" className='cursorPointer' onClick={clikcLedA} />
            : <Icon name="check circle" color='grey' size='large' title="Alarm obsłużony" />
    };


    const authContext = useContext(AuthContext);
    const [value1, setValue1] = useState('Off');
    const options = ['Off', 'Live'];
    const [form, setForm] = useState({
        dateFrom: new Date(Date.now() - (3600 * 1000 * 24)),
        dateTo: new Date(),
    });
    const [isloading, setisloading] = useState(false);

    function getData() {

        if(gridApi && value1 !== "Live" && gridApi.destroyCalled === false){
            gridApi.showLoadingOverlay();
         }
         let dateFrom = changeFormatDate(form.dateFrom) + " 00:00:00"
         let date_to = changeFormatDate(form.dateTo) + " 23:59:59"
         if (value1 === "Live") {
            setForm({
               dateFrom: new Date(Date.now() - (3600 * 1000 * 24)),
               dateTo: new Date(),
            })
            dateFrom = changeFormatDate(new Date(Date.now() - (3600 * 1000 * 24))) + " 00:00:00"
            date_to = changeFormatDate(new Date()) + " 23:59:59"
         }
   
         setisloading(true)
         let data = { date_from: dateFrom, date_to: date_to }
         ConnectWS("/infiber/alarms", "get", authContext.tokenApp, null, data, function (response, logout) {
            if (logout === 1) {
               toast.error(response, cfgToast);
               if(gridApi && value1 !== "Live"){
                  gridApi.showNoRowsOverlay();
               }
            } else if (Number(response.status) === 200) {
               if(gridApi && value1 !== "Live"){
                  gridApi.hideOverlay();
               }
               if(response.data.length>0){
                setRowData(response.data)
               }else{
                setRowData([])
               }
               
            } else {
               if(gridApi && value1 !== "Live"){
                  gridApi.showNoRowsOverlay();
               }
               toast.error(response, cfgToast);
               console.log(response);
            }
            setisloading(false)
   
         })



    }

    let interval = useRef(null);

    useEffect(() => {
        getData()
        if (value1 === "Live") {

            interval.current = setInterval(() => {
                getData()
            }, 5000);
        } else {
            clearInterval(interval.current)
        }

        return () => { clearInterval(interval.current) }; // use effect cleanup to set flag false, if unmounted
    }, [value1]);// eslint-disable-line react-hooks/exhaustive-deps



    return (

        <Card className="bgcard" style={{ marginBottom: 10, minHeight: props.minHeight }}>

            <Grid stackable>
                <Grid.Row >
                    <Grid.Column width={5} className="p-fluid">
                        <span className="p-float-label">
                            <Calendar value={form.dateFrom} onChange={(e) => setForm({ ...form, dateFrom: e.value })} dateFormat="yy-mm-dd" showIcon />
                            <label htmlFor="date">Data od:</label>
                        </span>
                    </Grid.Column>

                    <Grid.Column width={5} className="p-fluid">
                        <span className="p-float-label">
                            <Calendar value={form.dateTo} onChange={(e) => setForm({ ...form, dateTo: e.value })} dateFormat="yy-mm-dd" showIcon />
                            <label htmlFor="date">Data do:</label>
                        </span>
                    </Grid.Column>

                    <Grid.Column width={2} className="p-fluid">

                        <SelectButton style={{ height: 33 }} value={value1} options={options} onChange={(e) => e.value ? setValue1(e.value) : ""} />

                    </Grid.Column>
                    <Grid.Column width={4} className="p-fluid">
                        <ButtonPrime loading={isloading} disabled={value1 === "Live"} onClick={() => getData()} label="Pobierz dane" className="p-button" />

                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
            <div
                id="myGrid"
                style={{
                    height: props.minHeight - 130,
                    // height:  '100%',
                    width: '100%',
                    marginTop: 5
                }}
                className="ag-theme-balham"
            >
                <AgGridReact
                    //  rowHeight={rowHeightAGG}
                    rowSelection={'single'}

                    suppressRowClickSelection={true}
                    animateRows={true}
                    pagination={true}
                    defaultColDef={{
                        editable: false,
                        sortable: true,
                        filter: false,
                        width: 100,
                        minWidth: 100,
                        wrapText: true,
                        autoHeight: true,
                        resizable: true,
                        floatingFilter: false,
                        flex: 1,
                    }}
                    frameworkComponents={{
                        statusEvent: statusEvent,
                        clikcAlarm: clikcAlarm,
                        sendedNotification:sendedNotification
                    }}
                    rowClassRules={{
                        'row-error-blink': function (params) {
   
                           if (params.data) {
                              return params.data.active === 1 ? true : false
                           }
                           return false
   
                        }
                     }}

                    getRowId={function (data) {
                        return data.data.alarm_id

                    }}

                    localeText={AG_GRID_LOCALE_PL}
                    rowData={rowData}
                    onGridReady={onGridReady}
                >
                    <AgGridColumn cellRenderer='statusEvent'
                        width={110}
                        minWidth={110}
                        flex={0}
                    />

                    <AgGridColumn field="created_at" headerName='Data' width={150}
                        minWidth={150}
                        flex={0} filter="agDateColumnFilter"
                        filterParams={filterParams}
                    />

                    <AgGridColumn field="site_name" headerName='Nazwa obiektu' filter='agTextColumnFilter'
                    />
        
                    <AgGridColumn field="device_name" headerName='Nazwa urządzenia' filter='agTextColumnFilter' />

                    <AgGridColumn field="description" headerName='Szczegóły' filter='agTextColumnFilter' />
                     <AgGridColumn cellRenderer='sendedNotification' 
                        width={45}
                        minWidth={45}
                        flex={0}
                    />
                    <AgGridColumn cellRenderer='clikcAlarm'
                        width={45}
                        minWidth={45}
                        flex={0}
                    />

                </AgGridReact>
            </div>




        </Card>

    );
}

export default SwitchAlarms;