import React, { useState, useContext } from 'react';
import { Card } from 'primereact/card';
import { toast } from 'react-toastify';
import { cfgToast } from '../../../myFunc';
import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Divider } from 'semantic-ui-react';
import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import AuthContext from '../../../context/authContext';
import { Button as ButtonPrime } from 'primereact/button';
import { Password } from 'primereact/password';
import { InputMask } from 'primereact/inputmask';

function AddWorker(props) {
   const authContext = useContext(AuthContext);
   const defaultValues = {
      username: "",
      surname: "",
      name: "",
      email: "",
      phone: "",
      password: "",
      days_off: "",
      working_time: "",
      type:1,

   }
   const [isloading, setisloading] = useState(false);
   const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
   const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };


   const passwordFooter = (
      <React.Fragment>
         <Divider />
         <p className="mt-2">Wymagane</p>
         <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
            <li>Minimum 8 znaków</li>
            <li>Dopuszczalne znaki specjalne .!#@$&?</li>     
         </ul>
      </React.Fragment>
   );

   const submit = (data) => {
      setisloading(true)

      ConnectWS("/users", "post", authContext.tokenApp, null, data, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp + 1)
            reset()
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })


   }


   return (
      <>
         <Card className="bgcard">
            <form onSubmit={handleSubmit(submit)} className="p-fluid">

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="name" control={control} rules={{ required: 'Imie jest wymagane' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Imie*</label>
                  </span>
                  {getFormErrorMessage('name')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="surname" control={control} rules={{ required: 'Nazwisko jest wymagane' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="surname" className={classNames({ 'p-error': errors.surname })}>Nazwisko*</label>
                  </span>
                  {getFormErrorMessage('surname')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="username" control={control} rules={{ required: 'Login jest wymagany' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="username" className={classNames({ 'p-error': errors.username })}>Login*</label>
                  </span>
                  {getFormErrorMessage('username')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-phone" />
                     <Controller name="phone" control={control} render={({ field, fieldState }) => (
                        <InputMask mask="999999999" slotChar="xxxxxxxxx" id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="phone" className={classNames({ 'p-error': errors.phone })}>Telefon</label>
                  </span>
                  {getFormErrorMessage('phone')}
               </div>

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-envelope" />
                     <Controller name="email" control={control}
                        rules={{ required: 'Email jest wymagany.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Błędny email, przykład: example@email.com' } }}
                        render={({ field, fieldState }) => (
                           <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                     <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>Email*</label>
                  </span>
                  {getFormErrorMessage('email')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                  <i className="pi pi-calendar" />
                     <Controller name="days_off" control={control} rules={{ required: 'Ilość dni wolnych jest wymagana', pattern: { value: /^[0-9]{1,2}$/i, message: 'Błędna ilość dni' } }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="days_off" className={classNames({ 'p-error': errors.days_off })}>Ilość dni wolnych*</label>
                  </span>
                  {getFormErrorMessage('days_off')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-clock" />
                     <Controller name="working_time" control={control} rules={{ required: 'Czas pracy jest wymagany' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="working_time" className={classNames({ 'p-error': errors.working_time })}>Czas pracy* [min]</label>
                  </span>
                  {getFormErrorMessage('working_time')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">
                     <Controller name="password" control={control}
                        rules={{ required: 'Hasło jest wymagane.', pattern: { value: /^[A-Z0-9.!#@$&?]{8,20}$/i, message: 'Hasło nie spełnia wymagań' } }}

                        render={({ field, fieldState }) => (

                           <Password promptLabel="Wpisz Hasło" weakLabel="Słabe hasło" mediumLabel="Średnie hasło" strongLabel="Śilne hasło" id={field.name} {...field} ref={(ref) => { }} feedback={true} footer={passwordFooter} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} />

                        )}
                     />
                     <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>Hasło*</label>
                  </span>
                  {getFormErrorMessage('password')}
               </div>


               <ButtonPrime loading={isloading} type="submit" label="Dodaj serwisanta" className="mt-2 p-button" />
            </form>
         </Card>
      </>
   );
}

export default AddWorker;