import React, { useRef, useState, useEffect } from 'react'
import styles from './CountDownTimer.module.css';
import { Icon, Message } from 'semantic-ui-react'
import moment from 'moment/moment';

function TimerApp(props) {
  let interval = useRef(null);

  const [rDays, setRDays] = useState(9999);
  const [rHour, setRHour] = useState(9999);
  const [rMin, setRMin] = useState(9999);
  const [rSec, setRSec] = useState(9999);
  const [functStatus, setfunctStatus] = useState(0);

  function getTimeTL() {
    /*
    let date1 = new Date();
    let date2 = new Date(props.endDate);
    var timediff = date2 - date1;
    */

    var date1 = moment(props.startDate); //todays date
    var date2 = moment(new Date()); // another date
    let timedifftmp = moment.duration(date2.diff(date1));
    let timediff = timedifftmp._milliseconds

    
    let remainingDays = 9999
    let remainingHour = 9999
    let remainingMinute = 9999
    let remainingSecond = 9999

    if (timediff > 0) {
      remainingDays = Math.floor(timediff / (1000 * 60 * 60 * 24))
      remainingHour = Math.floor((timediff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      remainingMinute = Math.floor((timediff % (1000 * 60 * 60)) / (1000 * 60))
      remainingSecond = Math.floor((timediff % (1000 * 60)) / 1000)
    }


    setRDays(remainingDays);
    setRHour(remainingHour);
    setRMin(remainingMinute);
    setRSec(remainingSecond);
    return 0


  }


  useEffect(() => {
    if (functStatus === 0) {
      interval.current = setInterval(function () {
        setfunctStatus(getTimeTL());
      }, 1000);
    } else {
      clearInterval(interval.current)
    }
    return () => { clearInterval(interval.current) }; // use effect cleanup to set flag false, if unmounted
  }, [props.startDate]);// eslint-disable-line react-hooks/exhaustive-deps


  return (


    <div className={`${styles.countdown}`}>

      {
        functStatus === 0 ?
          <div className="countdown">
            {props.textValue}
            <ul >
              <li><span>{rDays === 9999 ? <Icon loading name='clock outline' /> : ("0" + rDays).slice(-2)}</span>DNI</li>
              <li><span>{rHour === 9999 ? <Icon loading name='clock outline' /> : ("0" + rHour).slice(-2)}</span>GODZIN</li>
              <li><span>{rMin === 9999 ? <Icon loading name='clock outline' /> : ("0" + rMin).slice(-2)}</span>MINUT</li>
              <li><span>{rSec === 9999 ? <Icon loading name='clock outline' /> : ("0" + rSec).slice(-2)}</span>SEKUND</li>
            </ul>
          </div>
          : props.beautifulText === "1" ? <div style={{ marginTop: 5 }}>{props.textValue}<br /> <Message compact
            success
            header={props.textValueFinish}

          /></div> : props.textValueFinish
      }

    </div>





  );
}

export default TimerApp;

