import React, { useState, useContext, useEffect, useRef } from 'react';
import { Grid, Icon, Label, Segment } from 'semantic-ui-react';
import AGGridActivity from '../AGGrid/AGGridActivity';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_PL } from '../AGGrid/LanguageAGG';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authContext';
import { cfgToast } from '../../myFunc';
import { confirmAlert } from 'react-confirm-alert';

import SwitchDiagram, { ErrorFallback, prepareJsonTabToDiag } from './SwitchDiagram/SwitchDiagram';
import { filterParams } from '../AGGrid/functions';
import { ErrorBoundary } from 'react-error-boundary'




function SwitchConnections(props) {
   const authContext = useContext(AuthContext);




   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars

   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);
   const [rowDataPrepareDiag, setrowDataPrepareDiag] = useState(null);
   const [loadingDraw, setloadingDraw] = useState(false);

   const [connMastertmp, setconnMastertmp] = useState(0);


   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };



   let interval = useRef(null);
   const [value1, setValue1] = useState('Live');// eslint-disable-line no-unused-vars



   useEffect(() => {
      getData()
      if (value1 === "Live") {
         interval.current = setInterval(() => {
            getData()
         }, 10000);
      } else {
         clearInterval(interval.current)
      }

      return () => { clearInterval(interval.current) }; // use effect cleanup to set flag false, if unmounted
   }, [value1, authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps



   function getData() {
     
      /*
      setRowData([{ connSet: 1, connectionsType: "external", connMaster: "1675332332625", created_at: "2023-02-02 11:05:51.000000", c_point_a_id: null, c_point_b_id: 7, portAAlive: -1, portBAlive: 1, connection_id: 23, pointAPort: null, pointBPort: 1, pointALabel: "TestStartA", pointBLabel: "123" },
      { connSet: 1, connectionsType: "internal", connMaster: "1675332332625", created_at: "2023-02-02 11:05:51.000000", c_point_a_id: 7, c_point_b_id: 8, portAAlive: 1, portBAlive: 1, connection_id: 24, pointAPort: 1, pointBPort: 2, pointALabel: 123, pointBLabel: 1234 },
      { connSet: 1, connectionsType: "external", connMaster: "1675332332625", created_at: "2023-02-02 11:05:51.000000", c_point_a_id: 8, c_point_b_id: null, portAAlive: 1, portBAlive: -1, connection_id: 25, pointAPort: 2, pointBPort: null, pointALabel: 1234, pointBLabel: "KoniecTestB" },
      { connSet: 1, connectionsType: "external", connMaster: "1675332350014", created_at: "2023-02-02 11:06:55.000000", c_point_a_id: null, c_point_b_id: 7, portAAlive: -1, portBAlive: 1, connection_id: 26, pointAPort: null, pointBPort: 1, pointALabel: "TestStartA", pointBLabel: 123 },
      { connSet: 1, connectionsType: "peer", connMaster: "1675332350014", created_at: "2023-02-02 11:06:55.000000", c_point_a_id: 7, c_point_b_id: 7, portAAlive: 0, portBAlive: 1, connection_id: 27, pointAPort: 1, pointBPort: 2, pointALabel: 123, pointBLabel: 123 },
      { connSet: 1, connectionsType: "peer", connMaster: "1675332350014", created_at: "2023-02-02 11:06:55.000000", c_point_a_id: 7, c_point_b_id: 7, portAAlive: 1, portBAlive: 1, connection_id: 28, pointAPort: 3, pointBPort: 4, pointALabel: 123, pointBLabel: 123 },
      { connSet: 1, connectionsType: "internal", connMaster: "1675332350014", created_at: "2023-02-02 11:06:55.000000", c_point_a_id: 8, c_point_b_id: 8, portAAlive: 1, portBAlive: 1, connection_id: 29, pointAPort: 2, pointBPort: 4, pointALabel: 1234, pointBLabel: 1234 },
      { connSet: 1, connectionsType: "external", connMaster: "1675332350014", created_at: "2023-02-02 11:06:55.000000", c_point_a_id: 8, c_point_b_id: null, portAAlive: 1, portBAlive: -1, connection_id: 30, pointAPort: 2, pointBPort: null, pointALabel: 1234, pointBLabel: "KoniecTestB" },
      ])
      return
*/
      ConnectWS("/infiber/connection", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            if (response.data && response.data.length > 0) {
               setRowData(response.data)
            } else {
               setRowData([])
            }
         } else {
            setRowData([])
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }




   const connSetIcon = props => {

      switch (props.data.connSet) {
         case 1: return <Icon name="arrows alternate horizontal" color='grey' size='large' title="Połączenie pojedyńcze" />;
         case 2: return <Icon name="exchange" color='grey' size='large' title="Połączenie podwójne" />;
         default: return ""
      }
   };




   function DelObjectRow(cmaster) {
      confirmAlert({
         title: 'Czy chcesz skasować Połączenie?',
         message: 'Proszę potwierdzić',
         buttons: [
            {
               label: 'Tak',
               onClick: () => DelObjectRowACC(cmaster)
            },
            {
               label: 'Nie',
               onClick: () => ''
            }
         ]
      });
      function DelObjectRowACC(connMaster) {
         ConnectWS("/infiber/connection", "delete", authContext.tokenApp, null, { connMaster: connMaster }, function (response, logout) {
            if (logout === 1) {
            } else if (Number(response.status) === 200) {
               toast.success(response.data.detail, cfgToast);
            } else {

               toast.error(response, cfgToast);
               console.log(response);
            }
            getData()


         })
      }
   };







   function onSelectionChanged() {
      if (gridApi) {
   

         var selectedRows = gridApi.getSelectedRows();
         let connMaster = selectedRows[0].connMaster

         if (connMaster !== connMastertmp) {
            setconnMastertmp(connMaster)

            let tmparr1 = []
            let tmparr2 = []
            gridApi.forEachNode(function (node) {
               if (node.data.connMaster === connMaster && node.data.connSet === 1) {
                  tmparr1.push({ connMaster: node.data.connMaster, connSet: 1, connectionsType: node.data.connectionsType, pointAID: node.data.pointALabel, pointAPort: node.data.pointAPort ? node.data.pointAPort : node.data.connSet , aliveA: node.data.portAAlive, aliveB: node.data.portBAlive, pointBID: node.data.pointBLabel, pointBPort: node.data.pointBPort ? node.data.pointBPort : node.data.connSet })
               } else if (node.data.connMaster === connMaster && node.data.connSet === 2) {
                  tmparr2.push({ connMaster: node.data.connMaster, connSet: 2, connectionsType: node.data.connectionsType, pointAID: node.data.pointALabel, pointAPort: node.data.pointAPort ? node.data.pointAPort : node.data.connSet, aliveA: node.data.portAAlive, aliveB: node.data.portBAlive, pointBID: node.data.pointBLabel, pointBPort: node.data.pointBPort ? node.data.pointBPort : node.data.connSet })

               }

            });
            let tmp = prepareJsonTabToDiag(tmparr1, tmparr2, tmparr2.length > 0 ? true : false)
            setrowDataPrepareDiag(null)
            setloadingDraw(true)
            setTimeout(() => {
               setloadingDraw(false)
               setrowDataPrepareDiag(tmp)
            }, 200);
         }

      }
   };

   const cellClass = (params) => {

      if (params.column.colId === "pointBPort") {
         return params.data.portBAlive === 0 ? 'cell-red' : params.data.portBAlive === 1 ? 'cell-green' : '';
      } else if (params.column.colId === "pointAPort") {
         return params.data.portAAlive === 0 ? 'cell-red' : params.data.portAAlive === 1 ? 'cell-green' : '';
      } else {
         return ""
      }

   };



   return (
      <>
         <Segment className='bgcard'>
            <Grid stackable >
               <Grid.Row >

                  <Grid.Column width={16} >
                     <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
                  </Grid.Column>
               </Grid.Row>
            </Grid>

            <div
               id="myGrid"
               style={{
                  height: 500,
                  // height:  '100%',
                  width: '100%',
                  marginTop: 5
               }}
               className="ag-theme-balham"
            >
               <AgGridReact

                  onSelectionChanged={onSelectionChanged}
                  rowSelection={'single'}
                  animateRows={true}
                  // pagination={true}
                  defaultColDef={{
                     editable: false,
                     sortable: true,
                     filter: false,
                     width: 120,
                     minWidth: 120,
                     wrapText: true,
                     autoHeight: true,
                     resizable: true,
                     floatingFilter: false,
                     flex: 1,
                  }}
                  frameworkComponents={{
                     connSetIcon: connSetIcon,
                  }}
                  rowClassRules={{
                     'row-external': function (params) {

                        if (params.data) {
                           return params.data.connectionsType === "external" && params.data.pointAPort === null && params.data.connSet===1
                        }
                        return false

                     },
                     'row-external2': function (params) {

                        if (params.data) {
                           return params.data.connectionsType === "external" && params.data.pointAPort === null && params.data.connSet===2
                        }
                        return false

                     }
                  }}
                  
                  getRowId={function (data) {
                     return data.data.connection_id

                  }}
                  localeText={AG_GRID_LOCALE_PL}
                  rowData={rowData}
                  onGridReady={onGridReady}
               >


                  <AgGridColumn width={150}
                     minWidth={150} flex={0} field="connMaster" headerName='Master' filter='agNumberColumnFilter' />
                  <AgGridColumn cellRenderer='connSetIcon'
                     width={45}
                     minWidth={45}
                     flex={0}
                  />
                  <AgGridColumn width={150}
                     minWidth={150} flex={0} field="connectionsType" headerName='Typ połączenia' filter='agTextColumnFilter' />
                  <AgGridColumn field="pointALabel" headerName='Punkt A' filter='agTextColumnFilter' />
                  <AgGridColumn width={150}
                     minWidth={150} flex={0} field="pointAPort" headerName='Punkt A port' filter='agNumberColumnFilter' cellClass={cellClass} />

                  <AgGridColumn field="pointBLabel" headerName='Punkt B' filter='agTextColumnFilter' />
                  <AgGridColumn width={150}
                     minWidth={150} flex={0} field="pointBPort" headerName='Punkt B port' filter='agNumberColumnFilter' cellClass={cellClass} />


                  <AgGridColumn field="created_at" width={190}
                     minWidth={190} flex={0} headerName='Ostatni odczyt' filter='agDateColumnFilter' filterParams={filterParams} />







               </AgGridReact>
            </div>

         </Segment>
         <Segment style={{ minHeight: 300 }} loading={loadingDraw} className='bgcard'>
            {rowDataPrepareDiag !== null ?
               <>
                  <Label color='blue' horizontal>
                     MASTER: {connMastertmp}
                  </Label> <Icon name="trash" className='cursorPointer' onClick={() => DelObjectRow(connMastertmp)} />
                  <ErrorBoundary FallbackComponent={ErrorFallback}
                     onReset={() => {
                        // reset the state of your app so the error doesn't happen again
                     }}>
                     <SwitchDiagram schemaJson={rowDataPrepareDiag} showLegends={false} minHeight={370} />
                  </ErrorBoundary>
               </> : ""}
         </Segment>
      </>
   );
}

export default SwitchConnections;