import React, { useRef, useState, useEffect, useContext } from 'react';
import Timeline, {
    TimelineHeaders,
    SidebarHeader,
    DateHeader
} from 'react-calendar-timeline'
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container'
import moment from 'moment';
import { Button, Grid, Icon, Modal, Segment } from 'semantic-ui-react';
import ModalUI from '../../layout/ModalUI';
import { Calendar } from 'primereact/calendar';
import { Button as ButtonPrime } from 'primereact/button';
import AuthContext from '../../context/authContext';
import { toast } from 'react-toastify';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { cfgToast, changeFormatDate, getColorStatus, returnWorkerTab } from '../../myFunc';
import ViewCommision from '../Commissions/Commissions/ViewCommision';

function OrdersCalendars(props) {
    const authContext = useContext(AuthContext);
    const ref = useRef(null)
    const ref1 = useRef(null)
    const [open, setOpen] = useState({ open: false, itemId: null })
    const [height, setHeight] = useState(0)
    const [groups, setgroups] = useState([])
    const [items, setitems] = useState([])
    const [isloading, setisloading] = useState(false);// eslint-disable-line no-unused-vars
    const [form, setForm] = useState({
        dateFrom: new Date(Date.now() - (3600 * 1000 * 24 * 7)),
        dateTo: new Date(Date.now() + (3600 * 1000 * 24 * 7)),
    });

    function getAllUsers() {
        ConnectWS("/users", "get", authContext.tokenApp, null, null, function (response, logout) {
            if (logout === 1) {
                toast.error(response, cfgToast);
            } else if (Number(response.status) === 200) {

                let tmparr = []
                returnWorkerTab(response.data).forEach((row, i) => {
                    tmparr.push({ id: row.user_id, title: row.name + " " + row.surname })
                })
                setgroups(tmparr)
                getAllCommisions()
            } else {
                toast.error(response, cfgToast);
                console.log(response);
            }

        })
    }
    function getAllCommisions() {
        let date_from = changeFormatDate(form.dateFrom)
        let date_to = changeFormatDate(form.dateTo)
        ConnectWS("/users/calendar", "get", authContext.tokenApp, null, { date_from: date_from, date_to: date_to }, function (response, logout) {
            if (logout === 1) {
                toast.error(response, cfgToast);
            } else if (Number(response.status) === 200) {

                if (response.data.length > 0) {
                    let arrtmp = [];
                    response.data.forEach((row, i) => {

                        arrtmp.push({
                            id: i+"/"+row.group,
                            type: row.type,
                            group: row.id,
                            title: row.type === 1 ? row.name ? row.name : "ZLECENIE" : row.name ? "URLOP - " + row.name : "URLOP",
                            start_time: moment(row.start_time),
                            end_time: moment(row.end_time),
                            itemProps: {
                                style: {
                                    background: row.type === 1 ? getColorStatus(row.status_id) : "#704f9e",
                                    borderColor: row.type === 1 ? getColorStatus(row.status_id) : "#704f9e",
                                }
                            }
                        })



                        //item-type-past
                        //item-type-holiday
                        //item-type-current               
                    })
                    setitems(arrtmp)
                }



            } else {
                toast.error(response, cfgToast);
                console.log(response);
            }


        })
    }


    useEffect(() => {
        if (ref1 && ref1.current) {
            setHeight(ref1.current.clientHeight)
        }
        getAllUsers()
        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [form]);// eslint-disable-line react-hooks/exhaustive-deps

    function getItemById(id) {
        return items.filter(
            function (data) { return data.id === id && data.type === 1 }
        );
    }

    function OpenModalInfo(itemId) {
       
        let found = getItemById(itemId)
        if (found.length > 0) {
            setOpen({ open: true, itemId: found[0] })
        }

    }

    function openNewCommision(groupId, time, e) {
        let dateFrom = time
        changeView({ appViewCategory: 'asd', appView: 'commission_addCommission', appModal: 1, appViewName: "Dodaj zlecenie", appViewIcon: 'calendar plus', props: JSON.stringify({ dateFrom: dateFrom, user_id: groupId }) })
    }


    const changeView = (co) => {
        if (co.appModal === true || co.appModal === 1) {
            authContext.changeAppModal(co);
        } else {
            authContext.changeAppView(co);
            window.sessionStorage.setItem('appView', JSON.stringify(co));
        }
    }
    return (
        <div>
            <Modal
                onClose={() => setOpen({ open: false, itemId: null })}
                open={open.open}
                centered={false}
                size="fullscreen"
            >
                <Modal.Header>
                    <ModalUI propsUiName={"Podgląd zlecenia"} />

                </Modal.Header>

                <Modal.Content className='bg'>
                    <ViewCommision commision_id={open.itemId ? open.itemId.id.split("/")[1] : 0} />
                </Modal.Content>
                <Modal.Actions>
                    <Button size='tiny' onClick={() => setOpen({ open: false, itemId: null })}>
                        ZAMKNIJ PODGLĄD
                    </Button>

                </Modal.Actions>
            </Modal>

            <Segment raised style={{ marginBottom: 10, height: props.minHeight > 700 ? props.minHeight : 550, overflow: "none" }}>

                <div style={{ marginBottom: 5, marginTop: 5 }} ref={ref1}>
                    <Grid stackable>
                        <Grid.Row >
                            <Grid.Column width={2} className="p-fluid">
                                <Icon name="zoom-in" className='cursorPointer' bordered onClick={() => ref.current.changeZoom(0.75)} />
                                <Icon name="zoom-out" className='cursorPointer' bordered onClick={() => ref.current.changeZoom(1.25)} />
                            </Grid.Column>
                            <Grid.Column width={5} className="p-fluid">
                                <span className="p-float-label">
                                    <Calendar value={form.dateFrom} onChange={(e) => setForm({ ...form, dateFrom: e.value })} dateFormat="yy-mm-dd" showIcon />
                                    <label htmlFor="date">Data od:</label>
                                </span>
                            </Grid.Column>

                            <Grid.Column width={5} className="p-fluid">
                                <span className="p-float-label">
                                    <Calendar value={form.dateTo} onChange={(e) => setForm({ ...form, dateTo: e.value })} minDate={form.dateFrom} dateFormat="yy-mm-dd" showIcon />
                                    <label htmlFor="date">Data do:</label>
                                </span>
                            </Grid.Column>

                            <Grid.Column width={4} className="p-fluid">
                                <ButtonPrime loading={isloading} onClick={() => getAllUsers()} label="Pobierz dane" className="p-button" />

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                {groups.length > 0 ?
                    <div style={{ marginBottom: 10, height: props.minHeight > 700 ? props.minHeight - height - 10 : 550 - height, overflowY: "scroll" }}>
                        <Timeline

                            ref={ref}
                            canMove={false}
                            canResize={false}
                            groups={groups}
                            items={items}
                            resizeDetector={containerResizeDetector}
                            itemHeightRatio={0.8}
                            lineHeight={50}
                            onItemDoubleClick={(itemId, e, time) => { OpenModalInfo(itemId) }}
                            onCanvasDoubleClick={(groupId, time, e) => { openNewCommision(groupId, time, e) }}
                            defaultTimeStart={form.dateFrom}
                            defaultTimeEnd={form.dateTo}
                        >
                            <TimelineHeaders className="calendarsticky">
                                <SidebarHeader>
                                    {({ getRootProps }) => {
                                        return <div {...getRootProps()}></div>;
                                    }}
                                </SidebarHeader>
                                <DateHeader unit="primaryHeader" />
                                <DateHeader />
                            </TimelineHeaders>
                        </Timeline>
                    </div>
                    : ""}

            </Segment>
        </div>
    );
}

export default OrdersCalendars;