import React, { useEffect, useState, useContext } from 'react';
import { Card } from 'primereact/card';
import { toast } from 'react-toastify';
import { cfgToast } from '../../../../myFunc';
import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { ConnectWS } from '../../../../ConnectWS/ConnectWS';
import AuthContext from '../../../../context/authContext';
import { Button as ButtonPrime } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';

function AddItem(props) {
   const authContext = useContext(AuthContext);
   const defaultValues = {
      name: "",
      description: "",
      short_name: "",
      ean_number: "",
      serial_number: "",
      warehouse_idtmp: 0,
      unit: "",
      quantity: 0
   }

   const [isloading, setisloading] = useState(false);
   const [items, setitems] = useState([]);
   const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
   const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };

   const unit = [
      { label: 'Sztuk', value: 'Sztuk' },
      { label: 'Kilometry', value: 'Kilometry' },
      { label: 'Metry', value: 'Metry' },
   ];



   function getWarehouse() {
      ConnectWS("/warehouse", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            if (response.data.length > 0) {
               let tmparr = []
               response.data.forEach((row, i) => {

                  tmparr.push({ label: row.description, value: row })

               })
               setitems(tmparr);
            }
         } else {
            toast.error(response, cfgToast);
            //  console.log(response);
         }
      
      })
   }

   useEffect(() => {

      getWarehouse()
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps


   const [filteredItems, setFilteredItems] = useState(null);

   const searchItems = (event) => {
      //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
      let query = event.query;
      let _filteredItems = [];

      for (let i = 0; i < items.length; i++) {
         let item = items[i];
         if (item.label.toLowerCase().indexOf(query.toLowerCase()) === 0) {
            _filteredItems.push(item);
         }
      }
    
      setFilteredItems(_filteredItems);
   }



   const submit = (data) => {
     

      setisloading(true)
      ConnectWS("/warehouse/element", "post", authContext.tokenApp, null, {...data,warehouse_id:data.warehouse_idtmp.value.warehouse_id}, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp + 1)
            reset()
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })


   }


   return (
      <>
         <Card className="bgcard">
            <form onSubmit={handleSubmit(submit)} className="p-fluid">

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label ">

                     <Controller name="name" control={control} rules={{ required: 'Nazwa elementu jest wymagana' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Nazwa elementu*</label>
                  </span>
                  {getFormErrorMessage('name')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label ">

                     <Controller name="description" control={control} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="description" className={classNames({ 'p-error': errors.description })}>Opis</label>
                  </span>
                  {getFormErrorMessage('description')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label ">

                     <Controller name="short_name" control={control} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="short_name" className={classNames({ 'p-error': errors.short_name })}>Krótka nazwa</label>
                  </span>
                  {getFormErrorMessage('short_name')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label ">

                     <Controller name="ean_number" control={control} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="ean_number" className={classNames({ 'p-error': errors.ean_number })}>EAN</label>
                  </span>
                  {getFormErrorMessage('ean_number')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">

                     <Controller name="serial_number" control={control} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="serial_number" className={classNames({ 'p-error': errors.serial_number })}>Numer seryjny</label>
                  </span>
                  {getFormErrorMessage('serial_number')}
               </div>

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label ">
                     <Controller name="warehouse_idtmp" control={control} rules={{ required: 'Magazyn jest wymagany' }} render={({ field, fieldState }) => (
                        <AutoComplete suggestions={filteredItems} completeMethod={searchItems} virtualScrollerOptions={{ itemSize: 38 }} field="label" dropdown id={field.name} value={field.value} onChange={(e) => {
                           field.onChange(e.value)
                        }} className={classNames({ 'p-invalid': fieldState.invalid })} dropdownAriaLabel="Wybierz magazyn" forceSelection />
                     )} />
                     <label htmlFor="warehouse_idtmp" className={classNames({ 'p-error': errors.warehouse_idtmp })}>Magazyn główny</label>
                  </span>
                  {getFormErrorMessage('warehouse_idtmp')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">

                     <Controller name="unit" control={control} rules={{ required: 'Jednostka jest wymagana' }} render={({ field, fieldState }) => (
                        <Dropdown options={unit} id={field.name} value={field.value} onChange={(e) => {
                           field.onChange(e.value)
                        }} className={classNames({ 'p-invalid': fieldState.invalid })} placeholder="Jednostka" />

                     )} />
                     <label htmlFor="unit" className={classNames({ 'p-error': errors.unit })}>Jednostka</label>
                  </span>
                  {getFormErrorMessage('unit')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label ">
                     <Controller name="quantity" control={control} rules={{ required: 'Ilość jest wymagana.', min: { value: 1, message: 'Błędna ilość min 1' } }} render={({ field, fieldState }) => (
                        <InputNumber mode="decimal" inputId="horizontal" id={field.name} value={field.value} onValueChange={(e) => field.onChange(e.value)} showButtons buttonLayout="horizontal" step={1}
                           decrementButtonClassName="p-button-danger" className={classNames({ 'p-invalid': fieldState.invalid })} incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />

                     )} />
                     <label htmlFor="quantity" className={classNames({ 'p-error': errors.quantity })}>Ilość</label>
                  </span>
                  {getFormErrorMessage('quantity')}
               </div>


               <ButtonPrime loading={isloading} type="submit" label="Dodaj element" className="mt-2 p-button" />
            </form>

         </Card>
      </>
   );
}

export default AddItem;