import React from 'react';

const AuthContext = React.createContext({
    isAuthenticated: false,
    appView:null,
    appViewModal:null,
    userInfo:"",
    tokenApp:null,
    updataApp:1,
    clientInfo:null,
    login:() => {},
    logout:() => {},
    changeUserInfo:(val) => {},
    changeAppView:(val) => {},
    changeAppModal:(val) => {},
    changeTokenApp:(val) => {},
    changeUpdataApp:(val) => {},
    changeClientInfo:(val) => {},
});

export default AuthContext;