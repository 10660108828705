import React, { useEffect, useState, useContext } from 'react';
import { Button, Divider, Dropdown, Grid, Icon, Label, Segment, Step } from 'semantic-ui-react';
import { AG_GRID_LOCALE_PL } from '../../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/authContext';
import { cfgToast,  changeFormatDateTimeDBns,  } from '../../../myFunc';


import AGGridActivity from '../../AGGrid/AGGridActivity';
import { Calendar } from 'primereact/calendar';

import Clients from '../Clients/Client';
import AddSubCommision from './AddSubCommision';

function AddCommision(props) {
   const authContext = useContext(AuthContext);
   const [onlyView, setonlyView] = useState(false);// eslint-disable-line no-unused-vars
   const [activeIndex, setActiveIndex] = useState(1);
   const [serviceOptions, setserviceOptions] = useState([]);

   const [form1, setForm1] = useState({
      dateFrom: null,
      dateTo: null,
   });



   const [form2, setForm2] = useState({
      clientInfo: null,
      typeService: null,
   });

   const [form3, setForm3] = useState({
      masterServiceman: null,
      subServiceman: null,
   });

   //typ zlecenia
   function getCommisionsTypes() {
      ConnectWS("/commisions/types", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            let tmparr = []
            response.data.forEach((row, i) => {

               tmparr.push({ key: i, value: JSON.stringify({ commision_type_id: row.commision_type_id, description: row.description }), text: row.description })

            })
            setForm2({ ...form2, typeService: tmparr[0].value })
            setserviceOptions(tmparr);

         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         //onSelectionChanged()

      })
   }

   // Instalator master
   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };

   const onSelectionChanged = () => {
      if (gridApi) {
         var selectedRows = gridApi.getSelectedRows();
         if (selectedRows.length === 1) {
            /*
            if (selectedRows[0].availability === false) {
               toast.warn("Proszę wybrać innego serwisanta, ten jest niedostępny", cfgToast);
               gridApi.forEachNode(function (node) {
                  if (node.data) {
                     node.setSelected(false);
                  }
               });
               return
            }*/
            let masterServiceman = selectedRows[0]
            setForm3({ ...form3, masterServiceman: masterServiceman })
         }
         else {
            setForm3({ ...form3, masterServiceman: null })

         }
      }
   };



   function SelectedRowTabMasterServiceman() {
      if (null !== gridApi && gridApi.destroyCalled === false && null !== form3.masterServiceman) {
         gridApi.forEachNode(function (node) {
            if (node.data) {
               node.setSelected(node.data['user_id'] === form3.masterServiceman.user_id);
            }
         });
      }
   }

   function selectMasterServiceManCalendar(user_id) {
      if (null !== gridApi && gridApi.destroyCalled === false && null !== user_id) {
         gridApi.forEachNode(function (node) {
            if (node.data) {
               node.setSelected(node.data['user_id'] === user_id);
            }
         });
      }
   }


   function getMasterServiceman() {

      //setRowData([{ id: 1, name: "aadsdsd sdaasd  ", otherCommission: "", available: 1 }, { id: 2, name: "aadsdsd sda", otherCommission: "Inne zlecenie", available: 0 }])
      ConnectWS("/users/worker_availability", "get", authContext.tokenApp, null, { date_from: changeFormatDateTimeDBns(form1.dateFrom), date_to: changeFormatDateTimeDBns(form1.dateTo) }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            setRowData(response.data)
            setTimeout(function () { SelectedRowTabMasterServiceman() }, 200);
            if (props.user_id) {
               setTimeout(function () { selectMasterServiceManCalendar(props.user_id) }, 200);
            }
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         //onSelectionChanged()

      })
   }





   //subinstalator
   const [gridApi1, setGridApi1] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi1, setGridColumnApi1] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData1, setRowData1] = useState(null);

   const onGridReady1 = (params) => {
      setGridApi1(params.api);
      setGridColumnApi1(params.columnApi);
   };

   function getSubServiceman() {

      //setRowData1([{ id: 1, name: "aadsdsd sda", otherCommission: "", available: 1 }, { id: 2, name: "aadsdsd sda", otherCommission: "Inne zlecenie", available: 0 }, { id: 3, name: "aadsdsd sda", otherCommission: "", available: 1 }, { id: 4, name: "aadsdsd sda", otherCommission: "", available: 1 },])

      setTimeout(function () { SelectedRowTabSubServiceman() }, 200);
      ConnectWS("/users/worker_availability", "get", authContext.tokenApp, null, { date_from: changeFormatDateTimeDBns(form1.dateFrom), date_to: changeFormatDateTimeDBns(form1.dateTo) }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            setRowData1(response.data)

         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         //onSelectionChanged()

      })
   }

   const onSelectionChanged1 = () => {
      if (gridApi1) {
         var selectedRows = gridApi1.getSelectedRows();
         if (selectedRows.length >= 1) {
            /*
            if (selectedRows[0].availability === false) {
               toast.warn("Proszę wybrać innego serwisanta, ten jest niedostępny", cfgToast);
               gridApi1.forEachNode(function (node) {
                  if (node.data) {
                     node.setSelected(false);
                  }
               });
               return
            }
            */
            let subServiceman = selectedRows
            setForm3({ ...form3, subServiceman: subServiceman })
         }
         else {
            setForm3({ ...form3, subServiceman: null })

         }
      }
   };

   function SelectedRowTabSubServiceman() {
      if (null !== gridApi1 && gridApi1.destroyCalled === false && null !== form3.subServiceman && form3.subServiceman.length > 0) {
         gridApi1.forEachNode(function (node) {
            if (node.data) {
               for (var i = 0; i < form3.subServiceman.length; i++) {
                  if (node.data['user_id'] === form3.subServiceman[i].user_id) {
                     node.setSelected(true);
                  }
               }

            }
         });
      }
   }



   const isactiveIcon = props => {
      let ret
      props.data.availability ? ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='green' title="DOSTĘPNY" >
         DOSTĘPNY
      </Label> : ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='red' title="NIEDOSTĘPNY" >
         NIEDOSTĘPNY
      </Label>
      return ret
   };

   const cellStyle = (props) => {
      return props.data.availability === 0 ? { 'pointer-events': 'none', opacity: '0.3' } : { opacity: '1' }
   };


   useEffect(() => {

      if (props.onlyView !== null) {
         setonlyView(props.onlyView)
      }
      if (props.dateFrom) {
         setForm1({ ...form1, dateFrom: new Date(props.dateFrom) })
      }


      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (activeIndex === 2) {
         getCommisionsTypes()
      }
      if (activeIndex === 3 && null !== gridApi1 && gridApi1.destroyCalled === false) {
         getSubServiceman()
      }
      if (activeIndex === 3 && null !== gridApi && gridApi.destroyCalled === false) {
         getMasterServiceman()
      }
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [activeIndex, gridApi1, gridApi]);// eslint-disable-line react-hooks/exhaustive-deps



   function changeStep(co) {
      if (co < 0 && activeIndex > 1) {
         setActiveIndex(activeIndex + co)
         return
      }
      if (co > 0) {
         if (activeIndex === 1) {
            if (form1.dateFrom === null || form1.dateTo === null) {
               toast.warning('Wybierz daty', cfgToast);
               return
            }
            if (form1.dateTo < form1.dateFrom) {
               toast.warning('Wybierz poprawne daty', cfgToast);
               return
            }

         }
         if (activeIndex === 2) {
            if (authContext.clientInfo === null) {
               toast.warning('Wybierz klienta', cfgToast);
               return
            } else {
               setForm2({ ...form2, clientInfo: authContext.clientInfo })
            }
            if (form2.typeService === null || form2.typeService === undefined) {
               toast.warning('Wybierz typ serwisu', cfgToast);
               return
            }

         }
         if (activeIndex === 3) {

            if (form3.masterServiceman === null || form3.masterServiceman === undefined) {
               toast.warning('Wybierz głównego serwisanta', cfgToast);
               return
            }

         }
         setActiveIndex(activeIndex + co)
      }
   }



   return (
      <div style={{ width: "100%", padding: 5, marginBottom: 10 }}>  {activeIndex === 4 ? "" : <Step.Group widths={4}>
         <Step active={activeIndex === 1 ? true : false} completed={activeIndex > 1 ? true : false}>
            <Icon name='clock' />
            <Step.Content>
               <Step.Title>Data serwisu</Step.Title>
               <Step.Description>{activeIndex > 1 ? <><Icon name="calendar" color='grey' />{changeFormatDateTimeDBns(form1.dateFrom)}<br /><Icon name="calendar" color='grey' />{changeFormatDateTimeDBns(form1.dateTo)}</> : "Czas rozpoczęcia i zakończenia"}</Step.Description>
            </Step.Content>
         </Step>
         <Step active={activeIndex === 2 ? true : false} completed={activeIndex > 2 ? true : false}>
            <Icon name='users' />
            <Step.Content>
               <Step.Title>Klient</Step.Title>
               <Step.Description>{activeIndex > 2 ? <><Icon name='users' /> {form2.clientInfo.name}<br /><Icon name='wrench' /> {form2.typeService ? JSON.parse(form2.typeService).description : ""}</> : "Wybranie klienta oraz typu zlecenia"}</Step.Description>
            </Step.Content>
         </Step>
         <Step active={activeIndex === 3 ? true : false} completed={activeIndex > 3 ? true : false}>
            <Icon name='user' />
            <Step.Content>
               <Step.Title>Serwisant</Step.Title>
               <Step.Description>{activeIndex > 3 ? <><Icon name='user' /> {form3.masterServiceman ? form3.masterServiceman.name : "??"}<br /><Icon name='users' /> {form3.subServiceman && form3.subServiceman.length > 0 ? form3.subServiceman.length : "Nie wybrano"}</> : "Wybranie serwisanta/-ów"}</Step.Description>
            </Step.Content>
         </Step>
         <Step active={activeIndex === 4 ? true : false} completed={activeIndex > 4 ? true : false}>
            <Icon name='info circle' />
            <Step.Content>
               <Step.Title>Zlecenie</Step.Title>
               <Step.Description>Dodanie szczegółów zlecenia</Step.Description>
            </Step.Content>
         </Step>

      </Step.Group>
      }
         {activeIndex === 1 ?
            <Segment className='bgcard'>
               <Label color='blue' ribbon style={{ marginBottom: 5 }}>
                  Zlecenie
               </Label>
               <Grid stackable style={{ margin: 5 }} >
                  <Grid.Row stretched >
                     <Grid.Column style={{ paddingLeft: 0 }} width={8} className="p-fluid">
                        <span className="p-float-label">
                           <Calendar value={form1.dateFrom} onChange={(e) => setForm1({ ...form1, dateFrom: e.value })} dateFormat="yy-mm-dd" showTime showIcon />
                           <label htmlFor="date">Data od:</label>
                        </span>
                     </Grid.Column>
                     <Grid.Column style={{ paddingRight: 0 }} width={8} className="p-fluid">
                        <span className="p-float-label">
                           <Calendar value={form1.dateTo} onChange={(e) => setForm1({ ...form1, dateTo: e.value })} minDate={form1.dateFrom} dateFormat="yy-mm-dd" showTime showIcon />
                           <label htmlFor="date">Data do:</label>
                        </span>
                     </Grid.Column>
                  </Grid.Row>
               </Grid>
            </Segment>
            : ""
         }

         {activeIndex === 2 ?

            <Segment className='bgcard'>
               <Label color='blue' ribbon style={{ marginBottom: 5 }}>
                  Klient oraz typ serwisu
               </Label>
               <Divider horizontal>TYP zlecenia</Divider>
               <Dropdown
                  placeholder='Typ zlecenia'
                  fluid
                  search
                  selection
                  value={form2.typeService}
                  onChange={(e, d) => setForm2({ ...form2, typeService: d.value })}
                  options={serviceOptions}

               />
               <Divider horizontal>KLIENT</Divider>
               <Clients minHeight={500} onlyChoose={true} onlyActive={true} />

            </Segment>

            : ""
         }

         {activeIndex === 3 ?
            <Segment className='bgcard'>
               <Label color='blue' ribbon style={{ marginBottom: 5 }}>
                  Główny serwisant
               </Label>
               <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
               <div
                  id="myGrid"
                  style={{
                     height: 300,
                     // height:  '100%',
                     width: '100%',
                     marginTop: 5
                  }}
                  className="ag-theme-balham"
               >
                  <AgGridReact
                     //rowHeight={rowHeightAGG}
                     rowSelection={'single'}
                     onSelectionChanged={onSelectionChanged}
                     suppressRowClickSelection={true}

                     animateRows={true}
                     pagination={true}
                     defaultColDef={{
                        editable: false,
                        sortable: true,
                        filter: false,
                        width: 100,
                        minWidth: 100,
                        wrapText: true,
                        autoHeight: true,
                        resizable: true,
                        floatingFilter: false,
                        flex: 1,

                     }}
                     frameworkComponents={{
                        isactiveIcon: isactiveIcon,
                     }}

                     getRowId={function (data) {
                        return data.data.user_id

                     }}
                     localeText={AG_GRID_LOCALE_PL}
                     rowData={rowData}
                     onGridReady={onGridReady}
                  >

                     <AgGridColumn
                        checkboxSelection={true}
                        field="name" headerName='Imie' filter='agTextColumnFilter'
                        cellStyle={cellStyle}
                     />
                     <AgGridColumn
                        field="surname" headerName='Nazwisko' filter='agTextColumnFilter'
                        cellStyle={cellStyle}
                     />
                     <AgGridColumn
                        field="email" headerName='Email' filter='agTextColumnFilter'
                        cellStyle={cellStyle}
                     />
                     <AgGridColumn
                        field="phone" headerName='Telefon' filter='agTextColumnFilter'
                        cellStyle={cellStyle}
                     />

                     <AgGridColumn cellRenderer='isactiveIcon'
                        headerName='Dostępność'
                        width={150}
                        minWidth={150}
                        flex={0}
                     />

                  </AgGridReact>
               </div>
               <Label color='blue' ribbon style={{ marginBottom: 5, marginTop: 10 }}>
                  Pozostali serwisanci
               </Label>
               <AGGridActivity gridApi={gridApi1} gridColumnApi={gridColumnApi1} />
               <div
                  id="myGrid1"
                  style={{
                     height: 300,
                     // height:  '100%',
                     width: '100%',
                     marginTop: 5
                  }}
                  className="ag-theme-balham"
               >
                  <AgGridReact
                     //rowHeight={rowHeightAGG}
                     rowSelection={'multiple'}
                     onSelectionChanged={onSelectionChanged1}
                     suppressRowClickSelection={true}

                     animateRows={true}
                     pagination={true}
                     defaultColDef={{
                        editable: false,
                        sortable: true,
                        filter: false,
                        width: 100,
                        minWidth: 100,
                        wrapText: true,
                        autoHeight: true,
                        resizable: true,
                        floatingFilter: false,
                        flex: 1,
                     }}
                     frameworkComponents={{
                        isactiveIcon: isactiveIcon,
                     }}

                     getRowId={function (data) {
                        return data.data.user_id

                     }}
                     localeText={AG_GRID_LOCALE_PL}
                     rowData={rowData1}
                     onGridReady={onGridReady1}
                  >


                     <AgGridColumn
                        checkboxSelection={true}
                        field="name" headerName='Imie' filter='agTextColumnFilter'
                        cellStyle={cellStyle}
                     />
                     <AgGridColumn

                        field="surname" headerName='Nazwisko' filter='agTextColumnFilter'
                        cellStyle={cellStyle}
                     />
                     <AgGridColumn
                        field="email" headerName='Email' filter='agTextColumnFilter'
                        cellStyle={cellStyle}
                     />
                     <AgGridColumn
                        field="phone" headerName='Telefon' filter='agTextColumnFilter'
                        cellStyle={cellStyle}
                     />
                     <AgGridColumn cellRenderer='isactiveIcon'
                        headerName='Dostępność'
                        width={150}
                        minWidth={150}
                        flex={0}
                     />
                  </AgGridReact>
               </div>
            </Segment>
            : ""
         }

         {activeIndex === 4 ?
            <AddSubCommision form1={form1} form2={form2} form3={form3} />
            : ""
         }
         <Grid style={{ marginTop: 10 }}   >
            <Grid.Row className='clearmp' columns={2} >
               <Grid.Column  >
                  <Button disabled={activeIndex < 2 ? true : false} onClick={() => changeStep(-1)} color='grey' fluid icon labelPosition='left'>
                     <Icon name='left arrow' />
                     POPRZEDNIE
                  </Button>
               </Grid.Column>
               <Grid.Column >
                  <Button disabled={activeIndex > 3 ? true : false} onClick={() => changeStep(+1)} color='grey' fluid icon labelPosition='right'>
                     NASTĘPNE
                     <Icon name='right arrow' />
                  </Button>
               </Grid.Column>
            </Grid.Row>
         </Grid>

      </div>
   );
}

export default AddCommision;