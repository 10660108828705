import React, { useEffect, useState, useContext } from 'react';
import { Grid, Icon, Segment } from 'semantic-ui-react';
import { AG_GRID_LOCALE_PL } from '../../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/authContext';
import { cfgToast, changeFormatDate, secondsToHms } from '../../../myFunc';
import AGGridActivity from '../../AGGrid/AGGridActivity';
import { Calendar } from 'primereact/calendar';
import { Button as ButtonPrime } from 'primereact/button';
import CommissionWorkerReportDetails from './CommissionWorkerReportDetails';

function CommissionWorkerReport(props) {
   const authContext = useContext(AuthContext);
   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);
   const [isWorker, setWorker] = useState(false);// eslint-disable-line no-unused-vars



   const [isloading1, setisloading1] = useState(false) // eslint-disable-line no-unused-vars
   const [isloading, setisloading] = useState(false);// eslint-disable-line no-unused-vars



   const [form, setForm] = useState({
      dateFrom: new Date(Date.now() - (3600 * 1000 * 24 * 31)),
      dateTo: new Date(Date.now() + (3600 * 1000 * 24 * 7)),
   });

   const [form1, setForm1] = useState({
      worker_id: 0,
      allInfo: null
   });

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
      if (rowData === null) {
         params.api.showNoRowsOverlay();
      }
   };



   function getWorkerReport() {
      setisloading1(true)

      //const items = Array.from({ length: 100 }).map((_, i) => ({ commision_id: i + 1, iddb: i + 1, idforeign: (i + 1) + 5000, name: `Nazwa ${i + 1}`, opis: `Opis ${i + 1}`, client: `klient ${i + 1}`, typzlecenia: "Serwis", status: "Zakończony", datadodania: "2023-03-03 12:00:00", datastartu: "2023-03-03 12:00:00", datakonca: "2023-03-03 12:00:00", datazatwierdzenia: "2023-03-03 12:00:00", datamodyfikacji: "2023-03-03 12:00:00", overtime: Math.floor(2 * Math.random()), statusCommissions: Math.floor(5 * Math.random()) }));
      //setRowData(items)
      if (gridApi && gridApi.destroyCalled === false) {
         gridApi.showLoadingOverlay();
      }
      ConnectWS("/commisions/worker_report", "get", authContext.tokenApp, null, { scheduled_start_at: changeFormatDate(form.dateFrom), scheduled_end_at: changeFormatDate(form.dateTo) }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
            if (gridApi && gridApi.destroyCalled === false) {
               gridApi.showNoRowsOverlay();
            }
         } else if (Number(response.status) === 200) {
            if (Number(process.env.REACT_APP_API_LOCALE) === 1) {
               if (gridApi && gridApi.destroyCalled === false) {
                  gridApi.showNoRowsOverlay();
               }
            } else {
               if (response.data.length > 0) {
                  setRowData(response.data)
                  if (gridApi && gridApi.destroyCalled === false) {
                     gridApi.hideOverlay();
                  }
               } else {
                  if (gridApi && gridApi.destroyCalled === false) {
                     gridApi.showNoRowsOverlay();
                  }
               }

            }

         } else {
            toast.error(response, cfgToast);
            console.log(response);
            if (gridApi && gridApi.destroyCalled === false) {
               gridApi.showNoRowsOverlay();
            }
         }

         setisloading1(false)
      })
   }


   useEffect(() => {
      if (authContext.userInfo && null !== authContext.userInfo.worker && authContext.userInfo.worker === 1) {
         setWorker(true)
      } else {
         setWorker(false)
      }
      getWorkerReport()
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps





   const WRovertime = props => {
      return props.data.overtime ? secondsToHms(props.data.overtime) : ""
   };

   const WRworktime = props => {
      return props.data.worktime ? secondsToHms(props.data.worktime) : ""
   };


   const iconWT = props => {
      return <Icon name='time' color='grey' size='large' className='cursorPointer' onClick={() => setForm1({ ...form1, worker_id: props.data.worker_id, allInfo: props.data })} />
   };


   return (
      <div style={{ minHeight: props.minHeight }}>

         {form1.worker_id > 0 ? <Segment  >
            <ButtonPrime label="Powrót" className="p-button-secondary" onClick={() => setForm1({
               worker_id: 0,
               allInfo: null
            })}></ButtonPrime>
            <CommissionWorkerReportDetails allInfo={form1} minHeight={props.minHeight} scheduled_start_at={changeFormatDate(form.dateFrom)} scheduled_end_at={changeFormatDate(form.dateTo)} />
         </Segment>
            :
            <Segment  >
               <Grid stackable style={{ minHeight: props.minHeight }}>
                  <Grid.Row>
                     <Grid.Column >
                        <Grid className='clearmp' stackable >
                           <Grid.Row stretched >

                              <Grid.Column style={{ paddingLeft: 0 }} width={5} className="p-fluid">
                                 <span className="p-float-label">
                                    <Calendar value={form.dateFrom} onChange={(e) => setForm({ ...form, dateFrom: e.value })} dateFormat="yy-mm-dd" showIcon />
                                    <label htmlFor="date">Data od:</label>
                                 </span>
                              </Grid.Column>
                              <Grid.Column style={{ paddingRight: 0 }} width={5} className="p-fluid">
                                 <span className="p-float-label">
                                    <Calendar value={form.dateTo} onChange={(e) => setForm({ ...form, dateTo: e.value })} minDate={form.dateFrom} dateFormat="yy-mm-dd" showIcon />
                                    <label htmlFor="date">Data do:</label>
                                 </span>
                              </Grid.Column>

                              <Grid.Column style={{ paddingRight: 0 }} width={6} className="p-fluid">
                                 <ButtonPrime loading={isloading} onClick={() => getWorkerReport()} label="Pobierz zlecenia" className="p-button" />
                              </Grid.Column>

                           </Grid.Row >
                        </Grid>
                        <AGGridActivity quickSearchHidden={false} gridApi={gridApi} gridColumnApi={gridColumnApi} />
                        <div
                           id="myGrid"
                           style={{
                              height: props.minHeight - 130,
                              // height:  '100%',
                              width: '100%',
                              marginTop: 5
                           }}
                           className="ag-theme-balham"
                        >
                           <AgGridReact
                              //rowHeight={rowHeightAGG}

                              suppressRowClickSelection={true}

                              animateRows={true}
                              pagination={true}
                              defaultColDef={{
                                 editable: false,
                                 sortable: true,
                                 filter: false,
                                 width: 100,
                                 minWidth: 100,
                                 //przyśpiesza odświeżanie
                                 //wrapText: true,
                                 //autoHeight: true,
                                 resizable: true,
                                 floatingFilter: false,
                                 flex: 1,
                              }}
                              frameworkComponents={{
                                 WRovertime: WRovertime,
                                 WRworktime: WRworktime,
                                 iconWT: iconWT
                              }}

                              getRowId={function (data) {
                                 return data.data.worker_id + data.data.work_month

                              }}
                              localeText={AG_GRID_LOCALE_PL}
                              rowData={rowData}
                              onGridReady={onGridReady}
                           >
                              <AgGridColumn cellRenderer='iconWT'
                                 width={49}
                                 minWidth={49}
                                 flex={0}
                              />
                              <AgGridColumn field="worker_name" headerName='Serwisant' filter='agTextColumnFilter' />
                              <AgGridColumn
                                 field="work_month" headerName='Miesiąc' filter='agTextColumnFilter' />



                            


                              <AgGridColumn cellRenderer='WRworktime'
                                 filter='agTextColumnFilter'
                                 headerName='Czas pracy'
                              />
                              <AgGridColumn field='worktime'
                                 filter='agNumberColumnFilter'
                                 headerName='Czas pracy [s]'
                              />
                              <AgGridColumn cellRenderer='WRovertime'
                                 filter='agTextColumnFilter'
                                 headerName='Czas nadgodzin'
                              />
                              <AgGridColumn field='overtime'
                                 filter='agNumberColumnFilter'
                                 headerName='Czas nadgodzin [s]'
                              />



                           </AgGridReact>
                        </div>
                     </Grid.Column>

                  </Grid.Row>


               </Grid>



            </Segment>
         }
      </div>
   );
}

export default CommissionWorkerReport;