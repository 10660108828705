import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { Button, Grid, Icon, Label, Segment } from 'semantic-ui-react';
import { cfgToast } from '../../../myFunc';
import AuthContext from '../../../context/authContext';
import '../../../rackserver.css'
import { useForm, Controller } from 'react-hook-form';
import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button as Buttonprime } from 'primereact/button';
import AGGridActivity from '../../AGGrid/AGGridActivity';
import { AG_GRID_LOCALE_PL } from '../../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import AGGridModalSwitch from '../../AGGrid/Modal/AGGridModalSwitch';
import { confirmAlert } from 'react-confirm-alert';




function SwitchServerRackMod({ seteditServerRack, editServerRack, msite_id, getCabinets }) {
   const authContext = useContext(AuthContext);
   //edit cabinet_slots

   const defaultValuesCS = {
      slot_nr: 0,
      u_nr: 0,
      device_id: "0",
      label: ""
   }
   const { control: control2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, setValue: setValue2 } = useForm({ defaultValuesCS });
   const getFormErrorMessage2 = (name) => {
      return errors2[name] && <small className="p-error">{errors2[name].message}</small>
   };

   const submitADDcabinet_slots = (data) => {
      data = { ...data, cabinet_id: editServerRack.cabinet_id }
      ConnectWS("/infiber/cabinet_slots", "post", authContext.tokenApp, null, data, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);

         } else if (Number(response.status) === 204) {

         } else {

            toast.error(response, cfgToast);
            console.log(response);
         }
         getCabinets(msite_id)
         getCabinet_slots(data.cabinet_id)
         setValue2("slot_nr", 0)
         setValue2("u_nr", 0)
         setValue2("device_id", 0)
         setValue2("label", "")
      })
   }



   const defaultValuesCabinet = {
      cabinet_name: "",
      slots_numbers: 0,
      site_id: 0,
      cabinet_id: 0,
      enabled: 1
   }
   const { control: control3, formState: { errors: errors3 }, handleSubmit: handleSubmit3, setValue: setValue3 } = useForm({ defaultValuesCabinet });
   const getFormErrorMessage3 = (name) => {
      return errors3[name] && <small className="p-error">{errors3[name].message}</small>
   };

   const submitADDcabinet_mod = (data) => {
      ConnectWS("/infiber/cabinet","put", authContext.tokenApp, null, data, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);

            //   setaddServerRack(false)
            // reset()
            getCabinets(data.site_id)
            
         } else if (Number(response.status) === 204) {

         } else {

            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }




   const [checked, setChecked] = useState(1);// eslint-disable-line no-unused-vars
   const [rowDataCS, setrowDataCS] = useState(null);
   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };

   const delRowIcon = props => {
      const DelObjectRow = () => {
         /*
         confirmAlert({
            title: 'Czy chcesz skasować rekord?',
            message: 'Proszę potwierdzić',
            buttons: [
               {
                  label: 'Tak',
                  onClick: () => DelObjectRowACC(props.data)
               },
               {
                  label: 'Nie',
                  onClick: () => ''
               }
            ]
         });
         */
         DelObjectRowACC(props.data)
      };

      return <Icon name="trash" color='grey' size='large' className='cursorPointer' onClick={DelObjectRow} />
   };

   function DelObjectRowACC(data) {

      ConnectWS("/infiber/cabinet_slots", "delete", authContext.tokenApp, null, data, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            //getData()
            getCabinet_slots(editServerRack.cabinet_id)

         } else {
            getCabinet_slots(editServerRack.cabinet_id)
            toast.error(response, cfgToast);
            console.log(response);
         }
         getCabinets(msite_id)

      })
   }

   function getCabinet_slots(cabinet_id) {

      ConnectWS("/infiber/cabinet_slots", "get", authContext.tokenApp, null, { cabinet_id: cabinet_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {

            if (response.data.length > 0) {
               setrowDataCS(response.data)
            } else {
               setrowDataCS([])
            }


         } else if (Number(response.status) === 204) {

            setrowDataCS([])

         } else {
            setrowDataCS([])
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }



   useEffect(() => {

      getCabinet_slots(editServerRack.cabinet_id)
      setValue3("cabinet_id", editServerRack.cabinet_id)
      setValue3("site_id", msite_id)
      setValue3("cabinet_name", editServerRack.cabinet_name)
      setValue3("slots_numbers", editServerRack.cabinet_slots)
      setValue3("enabled", 1)

      setChecked(1)


      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps

   const [oopenModalGetId, setoopenModalGetId] = useState(false)

   const handleChangeObject = (jsonobject, tmpname) => {
      console.log(jsonobject, tmpname)
      setValue2("device_id", jsonobject.device_id)
      setValue2("label", jsonobject.device_name)
   }

   function openModalGetId(tmpname) {
      setoopenModalGetId(tmpname)
   }

   const stateDevice = props => {

      if (props.data.device_id) {
         return <Icon name="hdd" color='grey' size='large' />;
      } else {
         return <Icon name="hdd outline" color='grey' size='large' />;
      }



   };


   function delRack() {
      confirmAlert({
         title: 'Czy chcesz usunąć szafę?',
         message: 'Proszę potwierdzić',
         buttons: [
            {
               label: 'Tak',
               onClick: () => delRackAcc()
            },
            {
               label: 'Nie',
               onClick: () => ''
            }
         ]
      });

      function delRackAcc() {
         setChecked(0)  
         ConnectWS("/infiber/cabinet","delete", authContext.tokenApp, null, {cabinet_id:editServerRack.cabinet_id}, function (response, logout) {
            if (logout === 1) {
            } else if (Number(response.status) === 200) {
               toast.success(response.data.detail, cfgToast);
               getCabinets(msite_id)
               seteditServerRack(null)
            } else if (Number(response.status) === 204) {
   
            } else {
   
               toast.error(response, cfgToast);
               console.log(response);
            }
   
         })
        

      }
   }


   return (
      <>
         <AGGridModalSwitch openModal={oopenModalGetId} handleChangeObject={handleChangeObject} />
         <Segment className='bgcard'>
            <Label as='a' color='blue' ribbon>
               EDYCJA SZAFY
            </Label>
            <form style={{ marginTop: 25 }} onSubmit={handleSubmit3(submitADDcabinet_mod)} className="p-fluid">

               <Grid stackable >
                  <Grid.Row  >
                     <Grid.Column width={7} className="p-fluid">

                        <span className="p-float-label">
                           <Controller name="cabinet_name" control={control3} rules={{ required: 'Nazwa jest wymagana', pattern: { value: /^.{3,253}$/i, message: 'Min 3 znaki' } }} render={({ field, fieldState }) => (
                              <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                           )} />
                           <label htmlFor="cabinet_name" className={classNames({ 'p-error': errors3.cabinet_name })}>Nazwa szafy*</label>
                        </span>
                        {getFormErrorMessage3('cabinet_name')}

                     </Grid.Column>
                    

                     <Grid.Column width={5} className="p-fluid">

                        <span className="p-float-label">
                           <Controller name="slots_numbers" control={control3} rules={{ required: 'Ilość slotów jest wymagana', pattern: { value: /^[0-9]{1,2}$/i, message: 'Błędna ilość slotów' } }}

                              render={({ field, fieldState }) => (

                                 <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />

                              )}
                           />
                           <label htmlFor="slots_numbers" className={classNames({ 'p-error': errors3.slots_numbers })}>Ilość slotów*</label>
                        </span>
                        {getFormErrorMessage3('slots_numbers')}


                     </Grid.Column>
                     <Grid.Column width={1} className="p-fluid">
                        {/* 
                        <div className="field-checkbox" style={{ paddingTop: 3 }}>
                           <Controller name="enabled" control={control3} rules={{ required: false }} render={({ field, fieldState }) => (
                              <InputSwitch inputId={field.name} onChange={(e) => {
                                 field.onChange(e.value ? 1 : 0)
                                 setChecked(e.value ? 1 : 0)
                              }} checked={field.value ? true : false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                           )} />
                           <label htmlFor="enabled" className={classNames({ 'p-error': errors3.accept })}>{checked ? "AKTYWNA" : "DO SKASOWANIA"}</label>

                        </div>
                        */}
                        <Icon name="trash" size='large' style={{ marginTop: 5 }} onClick={() => delRack()} className="cursorPointer" title="Usuń szafę" />
                     </Grid.Column>
                    
                     <Grid.Column width={3} className="p-fluid">
                        <Buttonprime type="submit" label="EDYTUJ SZAFĘ" />

                     </Grid.Column>
                  </Grid.Row>

               </Grid>

            </form>
         </Segment>
         <Segment className='bgcard'>
            <Label as='a' color='grey' ribbon>
               DODANIE URZĄDZENIA
            </Label>
            <form style={{ marginTop: 25 }} onSubmit={handleSubmit2(submitADDcabinet_slots)} className="p-fluid">

               <Grid stackable >
                  <Grid.Row columns={5} >
                     <Grid.Column className="p-fluid">

                        <span className="p-float-label">
                           <Controller name="label" control={control2} rules={{ required: 'Nazwa jest wymagana', pattern: { value: /^.{3,253}$/i, message: 'Min 3 znaki' } }} render={({ field, fieldState }) => (
                              <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                           )} />
                           <label htmlFor="label" className={classNames({ 'p-error': errors2.label })}>Nazwa urządzenia*</label>
                        </span>
                        {getFormErrorMessage2('label')}

                     </Grid.Column>
                     <Grid.Column className="p-fluid">
                        <Buttonprime label="Szukaj" type="button" icon="pi pi-search" onClick={() => openModalGetId({ open: true, name: "STARTB" })} className="p-button-secondary" />
                     </Grid.Column>
                     <Grid.Column className="p-fluid">

                        <span className="p-float-label">
                           <Controller name="slot_nr" control={control2} rules={{ required: 'Ilość slotów jest wymagana', pattern: { value: /^[0-9]{1,2}$/i, message: 'Błędna ilość slotów' } }}

                              render={({ field, fieldState }) => (

                                 <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />

                              )}
                           />
                           <label htmlFor="slot_nr" className={classNames({ 'p-error': errors2.slot_nr })}>Pozycja w szafie*</label>
                        </span>
                        {getFormErrorMessage2('slot_nr')}


                     </Grid.Column>
                     <Grid.Column className="p-fluid">

                        <span className="p-float-label">
                           <Controller name="u_nr" control={control2} rules={{ required: 'Ilość slotów jest wymagana', pattern: { value: /^[0-9]{1,2}$/i, message: 'Błędna ilość slotów' } }}

                              render={({ field, fieldState }) => (

                                 <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />

                              )}
                           />
                           <label htmlFor="u_nr" className={classNames({ 'p-error': errors2.u_nr })}>Ilość U*</label>
                        </span>
                        {getFormErrorMessage2('u_nr')}


                     </Grid.Column>

                     <Grid.Column className="p-fluid">
                        <Buttonprime type="submit" label="DODAJ URZĄDZENIE" className='p-button-secondary' />

                     </Grid.Column>
                  </Grid.Row>
               </Grid>

            </form>
         </Segment>
         <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
         <div
            id="myGrid"
            style={{
               height: 300,
               // height:  '100%',
               width: '100%',
               marginTop: 5
            }}
            className="ag-theme-balham"
         >
            <AgGridReact
               //rowHeight={rowHeightAGG}
               rowSelection={'single'}
               suppressRowClickSelection={true}

               animateRows={true}
               pagination={true}
               defaultColDef={{
                  editable: false,
                  sortable: true,
                  filter: false,
                  width: 100,
                  minWidth: 100,
                  resizable: true,
                  floatingFilter: false,
                  flex: 1,
               }}
               frameworkComponents={{
                  delRowIcon: delRowIcon,
                  stateDevice: stateDevice
               }}

               getRowId={function (data) {
                  return data.data.slot_nr

               }}
               localeText={AG_GRID_LOCALE_PL}
               rowData={rowDataCS}
               onGridReady={onGridReady}
            >

               <AgGridColumn cellRenderer='stateDevice'
                  width={50}
                  minWidth={50}
                  flex={0}
               />
               <AgGridColumn field="label" headerName='Nazwa' filter='agTextColumnFilter' />

               <AgGridColumn field="slot_nr" headerName='Pozycja w szafie' filter='agTextColumnFilter' />
               <AgGridColumn field="u_nr" headerName='Ilość U' filter='agTextColumnFilter' />
               <AgGridColumn cellRenderer='delRowIcon'
                  width={50}
                  minWidth={50}
                  flex={0}
               />


            </AgGridReact>
         </div>


         <Button style={{ marginTop: 10, marginBottom: 10 }} fluid onClick={() => seteditServerRack(null)}>ZAMKNIJ EDYCJĘ SZAFY</Button>
      </>
   );
}

export default SwitchServerRackMod;