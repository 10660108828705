import React, { useState, useEffect, useContext } from 'react';

import { cfgToast } from '../../myFunc';
import { Grid, Icon, Label, Message, Segment } from 'semantic-ui-react';
import { Button as ButtonPrime } from 'primereact/button';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authContext';

import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';



function AppSettings(props) {
   const authContext = useContext(AuthContext);
   const [isloading, setisloading] = useState(false);
   const [ismail, setismail] = useState(true);
   const defaultValues = {
      setting_id: 0,
      port: "",
      server: "",
      email_user: "",
      email_password: "",
   }
   const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });
   const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };

   const submit = (data) => {
      setisloading(true)

      ConnectWS("/infiber/settings/mail", "post", authContext.tokenApp, null, data, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            getDataMail()
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })


   }

   function getDataMail() {
      ConnectWS("/infiber/settings/mail", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {

            if (!response.data.setting_id) {

               setismail(false)
            } else {
               setismail(true)
               setValue("setting_id", response.data.setting_id)
               setValue("port", response.data.port)
               setValue("server", response.data.server)
               setValue("email_user", response.data.email_user)
               setValue("email_password", response.data.email_password)
            }


         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })


   }



   useEffect(() => {
      getDataMail()

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps



   return (

      <Segment className="bgcard" >
         <Label ribbon>
            Ustawienia maila: Serwer SMTP
         </Label>
         {ismail ? ""
            :
            <Message>
               <Icon name="mail" /> Brak wprowadzonej konfiguracji serwera SMTP
            </Message>
         }
         <form onSubmit={handleSubmit(submit)} className="p-fluid">
            <Grid stackable style={{ marginTop: 5 }}>
               <Grid.Row >
                  <Grid.Column width={4} className="p-fluid">
                     <div className="field" style={{ paddingTop: 10 }}>
                        <span className="p-float-label p-input-icon-right">
                           <i className="pi pi-file-edit" />
                           <Controller name="server" control={control} rules={{ required: 'Serwer jest wymagany' }} render={({ field, fieldState }) => (
                              <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                           )} />
                           <label htmlFor="server" className={classNames({ 'p-error': errors.server })}>Serwer*</label>
                        </span>
                        {getFormErrorMessage('server')}
                     </div>
                  </Grid.Column>

                  <Grid.Column width={4} className="p-fluid">
                     <div className="field" style={{ paddingTop: 10 }}>
                        <span className="p-float-label p-input-icon-right">
                           <i className="pi pi-file-edit" />
                           <Controller name="port" control={control} rules={{ required: 'Port jest wymagany', pattern: { value: /^[0-9]{1,9}$/i, message: 'Błędny port' } }} render={({ field, fieldState }) => (
                              <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                           )} />
                           <label htmlFor="port" className={classNames({ 'p-error': errors.port })}>Port*</label>
                        </span>
                        {getFormErrorMessage('port')}
                     </div>
                  </Grid.Column>

                  <Grid.Column width={4} className="p-fluid">
                     <div className="field" style={{ paddingTop: 10 }}>
                        <span className="p-float-label p-input-icon-right">
                           <i className="pi pi-file-edit" />
                           <Controller name="email_user" control={control} rules={{ required: 'Użytkownik jest wymagany' }} render={({ field, fieldState }) => (
                              <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                           )} />
                           <label htmlFor="email_user" className={classNames({ 'p-error': errors.email_user })}>Użytkownik*</label>
                        </span>
                        {getFormErrorMessage('email_user')}
                     </div>

                  </Grid.Column>
                  <Grid.Column width={4} className="p-fluid">
                     <div className="field" style={{ paddingTop: 10 }}>
                        <span className="p-float-label p-input-icon-right">
                           <i className="pi pi-file-edit" />
                           <Controller name="email_password" control={control} rules={{ required: 'Hasło jest wymagane' }} render={({ field, fieldState }) => (
                              <Password id={field.name} {...field} ref={(ref) => { }} feedback={false} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} />

                           )} />
                           <label htmlFor="email_password" className={classNames({ 'p-error': errors.email_password })}>Hasło*</label>
                        </span>
                        {getFormErrorMessage('email_password')}
                     </div>
                  </Grid.Column>
               </Grid.Row>
            </Grid>


            <ButtonPrime loading={isloading} type="submit" label="Zapisz dane" className="mt-2 p-button" />
         </form>


      </Segment>

   );
}

export default AppSettings;