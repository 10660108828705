import axios from 'axios'


const instance = axios.create(
    {
        //baseURL: process.env.REACT_APP_API,
        // eslint-disable-next-line
        baseURL: process.env.REACT_APP_API_URL_DYNAMIC==1 ?  window.BASE_URL_API : process.env.REACT_APP_URL_API,
        
    }
);

instance.defaults.timeout = 60000;

export default instance;