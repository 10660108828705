import { useRef, useState, useEffect } from 'react'
import dateFormat from 'dateformat';

function MyTimerLive(props) {
  let interval = useRef(null);


  const [mdate, setmdate] = useState(dateFormat(new Date(),"HH:MM:ss"));
  const [functStatus, setfunctStatus] = useState(0);

  function getTimeTL() {
    setmdate(dateFormat(new Date(),"HH:MM:ss"))
    return 1;
  }


  useEffect(() => {
    if (functStatus === 0) {
      interval.current = setInterval(function () {
        setfunctStatus(getTimeTL());
      }, 1000);
    } else {
      clearInterval(interval.current)
    }
    return () => { clearInterval(interval.current) }; // use effect cleanup to set flag false, if unmounted
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  return (
    mdate
  );
}

export default MyTimerLive;

