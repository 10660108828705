import React, { useState, useEffect, useContext } from 'react';

import { Button, Grid, Header, Icon, Image, Modal } from 'semantic-ui-react';
import { cfgToast, smallViewTel } from '../../../myFunc';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import infiberlogolb from '../../../assets/images/in-fiberlb.png';
import AGGridActivity from '../AGGridActivity';
import { AG_GRID_LOCALE_PL } from '../../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import AuthContext from '../../../context/authContext';
import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';

const AGGridModalSwitch = (props) => {
  const authContext = useContext(AuthContext);
  const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
  const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
  const [rowData, setRowData] = useState(null);
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [open, setOpen] = useState(false)

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };


  function getData() {
    ConnectWS("/infiber/device", "get", authContext.tokenApp, null, null, function (response, logout) {
      if (logout === 1) {
      } else if (Number(response.status) === 200) {
        if (response.data.length > 0) {
          setRowData(response.data)
        } else {
          setRowData([])
        }

      } else {
        toast.error(response, cfgToast);
        console.log(response);
      }

      
    })
  }


  useEffect(() => {

    if (props.openModal.open === true) {
      getData()
      setOpen(true)
    }

    //authContext.changeUserName(mview);
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [props.openModal]);// eslint-disable-line react-hooks/exhaustive-deps


  const onSelectionChanged = () => {
    if (gridApi) {
      var selectedRows = gridApi.getSelectedRows();
      if (selectedRows.length === 1) {
        props.handleChangeObject(selectedRows[0], props.openModal)
      }
      else {

      }
    }
  };


  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      centered={false}
    >
      <Modal.Header>
        <Grid verticalAlign='middle'>
          <Grid.Row >
            <Grid.Column width={12} verticalAlign="middle">
              <Header as={smallViewTel < width ? 'h3' : 'h4'} textAlign='center'>
                <Icon name={"search"} color="blue" />
                <Header.Content>Wyszukaj urządzenie</Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right" >
              <Image floated='right' size='small' centered src={infiberlogolb} alt="IN_FIBER" />
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Modal.Header>

      <Modal.Content className='bg'>
        <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
        <div
          id="myGrid"
          style={{
            height: 400,
            // height:  '100%',
            width: '100%',
            marginTop: 5
          }}
          className="ag-theme-balham"
        >
          <AgGridReact
            //rowHeight={rowHeightAGG}
            rowSelection={'single'}
            onSelectionChanged={onSelectionChanged}
            suppressRowClickSelection={false}

            animateRows={true}
            pagination={true}
            defaultColDef={{
              editable: false,
              sortable: true,
              filter: false,
              width: 100,
              minWidth: 100,
              resizable: true,
              floatingFilter: false,
              flex: 1,
            }}


            getRowId={function (data) {
              return data.data.device_id

            }}
            localeText={AG_GRID_LOCALE_PL}
            rowData={rowData}
            onGridReady={onGridReady}
          >
            <AgGridColumn headerCheckboxSelection={true}
              checkboxSelection={true}
              field="device_name" headerName='Nazwa' filter='agTextColumnFilter' />

            <AgGridColumn field="description" headerName='Opis' filter='agTextColumnFilter' />
            <AgGridColumn field="id" headerName='Id' filter='agTextColumnFilter' />
            <AgGridColumn field="ip_address" headerName='IP' filter='agTextColumnFilter' />
          



          </AgGridReact>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button size='tiny' onClick={() => setOpen(false)}>
          ZAMKNIJ
        </Button>

      </Modal.Actions>
    </Modal>
  );
};

export default AGGridModalSwitch;