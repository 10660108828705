import React, { useState, useEffect, useContext } from 'react';

import { cfgToast, getColorStatus } from '../../../myFunc';

import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/authContext';
import { Button, Card, Grid, Header, Icon, Image, Label, Message, Modal, Segment, Tab, Table } from 'semantic-ui-react';
import noPic from '../../../assets/images/no-image.png';
import serviceManPic from '../../../assets/images/serviceman.svg';
import serviceManPicS from '../../../assets/images/serviceman.png';
import ModalUI from '../../../layout/ModalUI';
import EditCommision from './EditCommision';
import GetLoadingTemplate from '../../../layout/GetLoadingTemplate';
import { Markup } from 'interweave';
import { confirmAlert } from 'react-confirm-alert';
import AddFileCommision from './AddFileCommision';
import { Knob } from 'primereact/knob';
import GetMaps from '../../../layout/GetMaps';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ToggleButton } from 'primereact/togglebutton';
import { Image as ImagePrime } from 'primereact/image';
import CloseCommision from './CloseCommision';
import moment from 'moment';
import ViewCommisionInputTextAddWorker from './ViewCommisionInputTextAddWorker';

function ViewCommision(props) {
   const authContext = useContext(AuthContext);
   const [isWorker, setWorker] = useState(false);
   const [onlyView, setonlyView] = useState(false); // eslint-disable-line no-unused-vars
   const [commision_id, setcommision_id] = useState(0);

   const [open, setOpen] = useState(false)
   const [gjsonData, setgjsonData] = useState(null)
   const [gjsonString, setgjsonString] = useState(null)
   const [gjsonMethod, setgjsonMethod] = useState(null)
   const [gjsonInputType, setgjsonInputType] = useState(null)

   const [propsUiNameData, setpropsUiNameData] = useState(null);

   const [jsonCommision, setjsonCommision] = useState(null)
   const [jsonWorkers, setjsonWorkers] = useState(null)
   const [jsonFiles, setjsonFiles] = useState(null)
   const [jsonFiles2, setjsonFiles2] = useState(null)
   const [jsonClient, setjsonClient] = useState(null)
   const [jsonElements, setjsonElements] = useState(null)
   const [jsonWorked, setjsonWorked] = useState([])
   const [jsonFields, setjsonFields] = useState(null)

   const [isloading, setisloading] = useState(false);

   const [openAddFiles, setopenAddFiles] = useState(false)
   const [dataAddFiles, setdataAddFiles] = useState(null)
   const [openModalCC, setopenModalCC] = useState(false)


   const [panesHistoryWork, setpanesHistoryWork] = useState([])

   const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars

   const [showMaps, setshowMaps] = useState(false)

   const [commisionStatus, setcommisionStatus] = useState(0); // eslint-disable-line no-unused-vars
   /*
Nowe zlecenie	1	0
Przyjęte	2	1
W realizacji	3	1
Zawieszone	4	1
Zakończone	5	1
Zwrócone	6	0
Zatwierdzone	7	0

   */



   function getCommisions(mcommision_id) {
      setisloading(true)
      ConnectWS("/commisions", "get", authContext.tokenApp, null, { commision_id: mcommision_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            getClient(mcommision_id)
            getWorkers(mcommision_id)
            getFiles(mcommision_id)
            setjsonCommision(response.data[0])
            setcommisionStatus(response.data[0].status_id)
            getFilesWorker(mcommision_id)
            getCommisionElements(mcommision_id)
            getWorkerActivityHistory(mcommision_id)
            getListArea(mcommision_id)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)

      })
   }


   function getCommisionElements(mcommision_id) {
      setisloading(true)
      ConnectWS("/commisions/elements", "get", authContext.tokenApp, null, { commision_id: mcommision_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            // getWorkers(mcommision_id)
            setjsonElements(response.data)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)

      })
   }

   function getWorkerActivityHistory(mcommision_id) {
      setisloading(true)
      ConnectWS("/commisions/commision_activity_history", "get", authContext.tokenApp, null, { commision_id: mcommision_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            let tmparr = []
            let workTime = 0
            if (response.data.length > 0) {
               response.data.forEach((row, i) => {
                  // zabezpieczenie że tylko serwisant widzi swoja pracę w zleceniu
                  if (authContext.userInfo && null !== authContext.userInfo.worker && authContext.userInfo.worker === 1 && authContext.userInfo.name.toLowerCase() !== row.name.toLowerCase()) {
                     return
                  }
                  if (row.data.length > 0) {
                     workTime = 0
                     row.data.forEach((row1, i) => {
                        if (row1.worktime) {
                           workTime = workTime + moment.duration(row1.worktime).asSeconds()
                        }

                     })
                  }
                  tmparr.push({ ...row, fullTimeWork: moment.utc(workTime * 1000).format('HH:mm:ss') })
               })

               setjsonWorked(tmparr)
               reDrawPanes(tmparr)
            }

         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)

      })
   }


   function getClient(mcommision_id) {
      setisloading(true)
      ConnectWS("/commisions/client", "get", authContext.tokenApp, null, { commision_id: mcommision_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            // getWorkers(mcommision_id)
            setjsonClient(response.data[0])
            authContext.changeClientInfo(response.data[0])
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)

      })
   }

   function getWorkers(mcommision_id) {
      setisloading(true)
      ConnectWS("/commisions/worker", "get", authContext.tokenApp, null, { commision_id: mcommision_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            if (response.data.length > 0) {
               let arrtmpOwner = [];
               let arrtmp = [];
               response.data.forEach((row) => {
                  if (row.owner) {
                     arrtmpOwner.push({ ...row })
                  } else {
                     arrtmp.push({ ...row })
                  }


               })
               console.log({ worker: arrtmp, workerOwner: arrtmpOwner })
               setjsonWorkers({ worker: arrtmp, workerOwner: arrtmpOwner })

            }



         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })
   }

   function getFiles(mcommision_id) {
      ConnectWS("/commisions/files", "get", authContext.tokenApp, null, { commision_id: mcommision_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            // getWorkers(mcommision_id)
            setjsonFiles(response.data)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }

   function getListArea(mcommision_id) {
      ConnectWS("/commisions/fields", "get", authContext.tokenApp, null, { commision_id: mcommision_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            // getWorkers(mcommision_id)
            setjsonFields(response.data)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }


      })
   }

   function getFilesWorker(mcommision_id) {
      setisloading(true)
      ConnectWS("/commisions/files_worker", "get", authContext.tokenApp, null, { commision_id: mcommision_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            // getWorkers(mcommision_id)
            setjsonFiles2(response.data)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)

      })
   }

   function checkCommisionIsClose() {
      if (jsonCommision && (jsonCommision.status_id === 5 || jsonCommision.status_id === 7)) {
         return true
      }
      return false
   }

   function checkCommisionReadyIsClose() {
      if (getPercentCommision() >= 100) {
         return true
      }
      return false
   }

   function reDrawPanes(jsonTMP) {
      let arrtmppanes = [];
      jsonTMP.forEach((row) => {
         arrtmppanes.push({
            menuItem: row.name, render: () => <Tab.Pane>
               {row.data && row.data.length > 0 ? <>
                  <div className="panel-wrapper">
                     <a href="#show" className="show btn" id="show">Pokaż wszystkie dane</a>
                     <a href="#hide" className="hide btn" id="hide">Ukryj dane</a>
                     <div className="panel">
                        <div style={{ overflowX: "auto", width: width < 700 ? (width - 110) : "100%" }} >
                           <Table celled padded striped textAlign='center' size='small' compact unstackable >
                              <Table.Header>
                                 <Table.Row>
                                    <Table.HeaderCell >Początek pracy</Table.HeaderCell>
                                    <Table.HeaderCell>Lokalizacja start</Table.HeaderCell>
                                    <Table.HeaderCell>Koniec pracy</Table.HeaderCell>
                                    <Table.HeaderCell>Lokalizacja koniec</Table.HeaderCell>
                                    <Table.HeaderCell>Czas pracy</Table.HeaderCell>
                                 </Table.Row>
                              </Table.Header>

                              <Table.Body>
                                 {row.data && row.data.length > 0 ?
                                    <>
                                       {row.data.map((row1, i) =>
                                          <Table.Row key={i} positive={row1.worktime === null ? true : false}>
                                             <Table.Cell >{row1.start_at}</Table.Cell>
                                             <Table.Cell >
                                                {row1.location_start === null ? "" : showMaps ? <div style={{ height: 150, minWidth: 250 }}> <GetMaps myLocation={[row1.location_start.lat, row1.location_start.lon]} /> </div> : row1.location_start.lat + ", " + row1.location_start.lon}
                                             </Table.Cell>
                                             <Table.Cell >{row1.end_at}</Table.Cell>
                                             <Table.Cell >
                                                {row1.location_end === null ? "" : showMaps ? <div style={{ height: 150, minWidth: 250 }}> <GetMaps myLocation={[row1.location_end.lat, row1.location_end.lon]} /> </div> : row1.location_end.lat + ", " + row1.location_end.lon}
                                             </Table.Cell>
                                             <Table.Cell >{row1.worktime}</Table.Cell>
                                          </Table.Row>
                                       )}
                                       <Table.Row >
                                          <Table.Cell />
                                          <Table.Cell />
                                          <Table.Cell />
                                          <Table.Cell />
                                          <Table.Cell ><b>{row.fullTimeWork}<br />Razem czas pracy</b></Table.Cell>
                                       </Table.Row>
                                    </>
                                    : ""}


                              </Table.Body>
                           </Table>
                        </div>
                     </div>
                     <div className="fade"></div>
                  </div>
               </>
                  :
                  <Message>
                     <Message.Header>Brak informacji</Message.Header>
                     <p>
                        Nie zarejestrowano jeszcze zadnej aktywności dla tego instalatora w tym zleceniu.
                     </p>
                  </Message>
               }

            </Tab.Pane>
         })

      })
      setpanesHistoryWork(arrtmppanes)
   }

   useEffect(() => {
      if (jsonWorked && jsonWorked.length > 0) {
         reDrawPanes(jsonWorked)
      }

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [showMaps]);// eslint-disable-line react-hooks/exhaustive-deps


   useEffect(() => {
      if (props.isWorker) {
         setWorker(props.isWorker)
      }
      if (props.commision_id) {
         //console.log(props.commision_id)
         setcommision_id(props.commision_id)
         getCommisions(props.commision_id)
      }
      /* Po co to było?
      if (commision_id) {
         getCommisions(commision_id)
      }*/

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [props.isWorker, props.commision_id, authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps


   const noValue = "n/n"

   function getEditIcon(jsonData, jsonName, jsonString, jsonMethod, jsonInputType, munlock = true) {
      if (onlyView) {
         return ""
      } else if (checkCommisionIsClose() && munlock === true) {
         return <Icon name="edit" className='closeCommisionIcon' title="Zlecenie zostało zakończone" />
      } else {
         return <Icon className='editCommisionIcon' name="edit" onClick={() => openModalEdit(jsonData, jsonName, jsonString, jsonMethod, jsonInputType)} />
      }

   }

   function openModalEdit(jsonData, jsonName, jsonString, jsonMethod, jsonInputType) {
      setpropsUiNameData(jsonName)
      setgjsonData(jsonData)
      setgjsonString(jsonString)
      setgjsonMethod(jsonMethod)
      setgjsonInputType(jsonInputType)
      setOpen(true)
   }

   function delElements(commision_warehouse_id) {
      confirmAlert({
         title: "Czy chcesz skasować element?",
         message: 'Proszę potwierdzić',
         buttons: [
            {
               label: 'Tak',
               onClick: () => delElementsAcc(commision_warehouse_id)
            },
            {
               label: 'Nie',
               onClick: () => ''
            }
         ]
      });

      function delElementsAcc(commision_warehouse_id) {
         ConnectWS("/commisions/elements", "delete", authContext.tokenApp, null, { commision_warehouse_id: commision_warehouse_id }, function (response, logout) {
            if (logout === 1) {
            } else if (Number(response.status) === 200) {

               toast.success(response.data.detail, cfgToast);
               authContext.changeUpdataApp(authContext.updataApp + 1)
            } else {
               toast.error(response, cfgToast);
               console.log(response);
            }

         })
      }
   }

   function delCommision() {
      confirmAlert({
         title: "Czy chcesz skasować zlecenie nr:" + commision_id,
         message: 'Proszę potwierdzić',
         buttons: [
            {
               label: 'Tak',
               onClick: () => delCommisionAcc()
            },
            {
               label: 'Nie',
               onClick: () => ''
            }
         ]
      });

      function delCommisionAcc() {

         ConnectWS("/commisions", "delete", authContext.tokenApp, null, { commision_id: commision_id }, function (response, logout) {
            if (logout === 1) {
            } else if (Number(response.status) === 200) {
               if (props.setOpenType) {
                  props.setOpenType(0)
               }
               toast.success(response.data.detail, cfgToast);
               authContext.changeUpdataApp(authContext.updataApp + 1)
            } else {
               toast.error(response, cfgToast);
               console.log(response);
            }

         })
      }

   }

   function getPercentCommision() {
      try {
         if ((jsonFiles && jsonFiles.length > 0) || (jsonFields && jsonFields.length > 0)) {
            let isFile = 0
            if (jsonFiles && jsonFiles.length > 0) {
               jsonFiles.forEach((row) => {
                  if (row.file_path) {
                     isFile++
                  }
               })
            }
            let isFields = 0
            if (jsonFields && jsonFields.length > 0) {
               jsonFields.forEach((row) => {
                  if (row.value) {
                     isFields++
                  }
               })
            }
            let a = parseInt(isFile) + parseInt(isFields)
            let all = parseInt(jsonFiles && jsonFiles.length > 0 ? jsonFiles.length : 0) + parseInt(jsonFields && jsonFields.length > 0 ? jsonFields.length : 0)
            let ret = ((parseInt(a) / parseInt(all)) * 100).toFixed()
            ret = ret > 100 ? 100 : ret
            return ret;
         }
         return 101;
      } catch (err) {
         console.log(err);
         return 0;
      }

   }

   function delFilesWorkers(commision_file_worker_id) {
      ConnectWS("/commisions/files_worker", "delete", authContext.tokenApp, null, { commision_file_worker_id: commision_file_worker_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp + 1)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }




   return (
      <>
         <Modal
            onClose={() => setOpen(false)}
            open={open}
            centered={false}
         >
            <Modal.Header>
               <ModalUI propsUiName={"Edycja zlecenia"} />
            </Modal.Header>

            <Modal.Content className='bg'>
               <Segment className='bgcard' >
                  <Label color='blue' ribbon style={{ marginBottom: 10 }}>
                     {propsUiNameData}
                  </Label>
                  <EditCommision setOpen={setOpen} isWorker={isWorker} commision_id={commision_id} jsonData={gjsonData} jsonString={gjsonString} jsonMethod={gjsonMethod} jsonInputType={gjsonInputType} dateFrom={jsonCommision ? jsonCommision.scheduled_start_at : null} dateTo={jsonCommision ? jsonCommision.scheduled_end_at : null} />
               </Segment>


            </Modal.Content>
            <Modal.Actions>
               <Button size='tiny' onClick={() => setOpen(false)}>
                  ZAMKNIJ
               </Button>

            </Modal.Actions>
         </Modal>
         <Modal
            onClose={() => ""}
            open={openAddFiles}
            centered={false}
         >
            <Modal.Header>
               <ModalUI propsUiName={"Dodaj plik"} />
            </Modal.Header>

            <Modal.Content className='bg'>
               <AddFileCommision setopenAddFiles={setopenAddFiles} dataAddFiles={dataAddFiles} />
            </Modal.Content>
            <Modal.Actions>
               <Button size='tiny' onClick={() => setopenAddFiles(false)}>
                  ZAMKNIJ
               </Button>

            </Modal.Actions>
         </Modal>
         <Modal
            onClose={() => ""}
            open={openModalCC}
            centered={false}
         >
            <Modal.Header>
               <ModalUI propsUiName={"Zakończ zlecenie"} />
            </Modal.Header>

            <Modal.Content className='bg'>
               <CloseCommision commision_id={commision_id} jsonCommision={jsonCommision} setopenModalCC={setopenModalCC} />
            </Modal.Content>
            <Modal.Actions>
               <Button size='tiny' onClick={() => setopenModalCC(false)}>
                  ZAMKNIJ
               </Button>

            </Modal.Actions>
         </Modal>
         {isloading ?
            <GetLoadingTemplate lineNumber={3} />
            :
            <>
               <Segment className='bgcard' loading={isloading}>
                  <div style={{ display: 'inline-block', width: '100%', borderBottom: '1px solid #2196f3', marginBottom: 10 }}>
                     <h3> <Icon name="wrench" color="blue" />PRZEGLĄD ZLECENIA NR:{commision_id} {isWorker ? "" : checkCommisionIsClose() ? <Icon color='grey' title="Zlecenie zostało zakończone" name="trash" /> : <Icon className='editCommisionIcon' onClick={(() => delCommision())} name="trash" />}</h3>
                  </div>


                  <Grid stackable verticalAlign='middle'>
                     <Grid.Row stretched>
                        <Grid.Column width={11}>
                           <Table basic='very' celled compact >
                              <Table.Body>
                                 <Table.Row>
                                    <Table.Cell width={4} style={{ position: "relative" }}>

                                       <Header as='h4' >
                                          <Header.Content>
                                             {isWorker ? jsonCommision && (jsonCommision.status_id === 1 || jsonCommision.status_id === 2) ? getEditIcon(jsonCommision, "Status zlecenia", "status_id", "/commisions/commision_status", "comboStatus", false) : "" : getEditIcon(jsonCommision, "Status zlecenia", "status_id", "/commisions/commision_status", "comboStatus", false)} Status
                                          </Header.Content>
                                       </Header>

                                    </Table.Cell>
                                    <Table.Cell><Label style={{ width: 200, height: 25, fontSize: 10, textAlign: "center", backgroundColor: getColorStatus(jsonCommision ? jsonCommision.status_id : noValue), color: "#fff" }} title={"status"} >{jsonCommision ? jsonCommision.status : noValue}</Label></Table.Cell>
                                 </Table.Row>
                                 <Table.Row>
                                    <Table.Cell width={4}>
                                       <Header as='h4' >
                                          <Header.Content>
                                             {isWorker ? "" : getEditIcon(jsonCommision, "Typ zlecenia", "type_id", "/commisions", "comboTypCommision")}  Typ zlecenia
                                          </Header.Content>
                                       </Header>
                                    </Table.Cell>
                                    <Table.Cell>{jsonCommision ? jsonCommision.type : noValue}</Table.Cell>
                                 </Table.Row>
                                 <Table.Row>
                                    <Table.Cell width={4}>
                                       <Header as='h4' >
                                          <Header.Content>
                                             {isWorker ? "" : getEditIcon(jsonCommision, "Nazwa zlecenia", "name", "/commisions", "input")}  Nazwa zlecenia
                                          </Header.Content>
                                       </Header>
                                    </Table.Cell>
                                    <Table.Cell>{jsonCommision ? jsonCommision.name : noValue}</Table.Cell>
                                 </Table.Row>

                                 <Table.Row>
                                    <Table.Cell width={4}>
                                       <Header as='h4' >
                                          <Header.Content>
                                             {isWorker ? "" : getEditIcon(jsonCommision, "Data rozpoczęcia serwisu", "scheduled_start_at", "/commisions", "date")} Data rozpoczęcia serwisu
                                          </Header.Content>
                                       </Header>
                                    </Table.Cell>
                                    <Table.Cell>{jsonCommision ? jsonCommision.scheduled_start_at : noValue}</Table.Cell>
                                 </Table.Row>
                                 <Table.Row>
                                    <Table.Cell width={4}>
                                       <Header as='h4' >
                                          <Header.Content>
                                             {isWorker ? "" : getEditIcon(jsonCommision, "Planowana data zakończenia serwisu", "scheduled_end_at", "/commisions", "date")}  Planowana data zakończenia serwisu
                                          </Header.Content>
                                       </Header>
                                    </Table.Cell>
                                    <Table.Cell>{jsonCommision ? jsonCommision.scheduled_end_at : noValue}</Table.Cell>
                                 </Table.Row>
                                 <Table.Row>
                                    <Table.Cell width={4}>
                                       <Header as='h4' >
                                          <Header.Content>
                                             {isWorker ? "" : getEditIcon(jsonCommision, "Identyfikator zlecenia", "extserviceid", "/commisions", "input")} Identyfikator zlecenia
                                          </Header.Content>
                                       </Header>
                                    </Table.Cell>
                                    <Table.Cell>{jsonCommision ? jsonCommision.extserviceid : noValue}</Table.Cell>
                                 </Table.Row>
                                 <Table.Row>
                                    <Table.Cell width={4}>
                                       <Header as='h4' >
                                          <Header.Content>
                                             {isWorker ? "" : getEditIcon(jsonCommision, "Adres usługi", "description_worker", "/commisions", "input")}  Adres usługi
                                          </Header.Content>
                                       </Header>
                                    </Table.Cell>
                                    <Table.Cell>{jsonCommision ? jsonCommision.description_worker : noValue}</Table.Cell>
                                 </Table.Row>
                                 <Table.Row>
                                    <Table.Cell width={4}>
                                       <Header as='h4' >
                                          <Header.Content>
                                             {isWorker ? "" : getEditIcon(jsonCommision, "Nadgodziny", "overtime", "/commisions", "overtime")}  Nadgodziny
                                          </Header.Content>
                                       </Header>
                                    </Table.Cell>
                                    <Table.Cell>{jsonCommision ? jsonCommision.overtime === 1 ? <Icon name="check" /> : <Icon name="x" /> : noValue}{jsonCommision ? jsonCommision.overtime === 1 ? "TAK" : "NIE" : noValue}</Table.Cell>
                                 </Table.Row>
                                 <Table.Row>
                                    <Table.Cell width={4}>
                                       <Header as='h4' >
                                          <Header.Content>
                                             Data utworzenia zlecenia
                                          </Header.Content>
                                       </Header>
                                    </Table.Cell>
                                    <Table.Cell>{jsonCommision ? jsonCommision.created_at : noValue}</Table.Cell>
                                 </Table.Row>
                                 <Table.Row>
                                    <Table.Cell width={4}>
                                       <Header as='h4' >
                                          <Header.Content>
                                             Data modyfikacji zlecenia
                                          </Header.Content>
                                       </Header>
                                    </Table.Cell>
                                    <Table.Cell>{jsonCommision ? jsonCommision.modified_at : noValue}</Table.Cell>
                                 </Table.Row>
                              </Table.Body>
                           </Table>
                        </Grid.Column>
                        <Grid.Column width={5}>
                           <div className="card text-center">
                              {getPercentCommision() > 100 ? <>
                                 <h5>Brak wymaganych plików</h5>
                                 <Knob value={100} size={150} readOnly />
                              </>
                                 : <>
                                    <h5>Zlecenie wykonane w %</h5>
                                    <Knob value={getPercentCommision()} size={150} readOnly />
                                 </>}


                           </div>
                        </Grid.Column>
                     </Grid.Row>
                  </Grid>
                  <div style={{ display: 'inline-block', width: '100%', borderBottom: '1px solid #2196f3', marginBottom: 10, marginTop: 10 }}>
                     <h5><Icon link name="info circle" color="blue" />INFORMACJE O KLIENCIE {isWorker ? "" : getEditIcon(jsonCommision, "Klient", "client_id", "/commisions", "client")}</h5>
                  </div>
                  <Table basic='very' celled compact >
                     <Table.Body>
                        <Table.Row>

                           <Table.Cell width={4}>
                              <Header as='h4' >
                                 <Header.Content>
                                    Nazwa klienta
                                 </Header.Content>
                              </Header>
                           </Table.Cell>
                           <Table.Cell>{jsonClient ? jsonClient.name : noValue}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                           <Table.Cell width={4}>
                              <Header as='h4' >
                                 <Header.Content>
                                    Adres klienta
                                 </Header.Content>
                              </Header>
                           </Table.Cell>
                           <Table.Cell>{jsonClient ? jsonClient.address : noValue}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                           <Table.Cell width={4}>
                              <Header as='h4' >
                                 <Header.Content>
                                    Opis klienta
                                 </Header.Content>
                              </Header>
                           </Table.Cell>
                           <Table.Cell>{jsonClient ? jsonClient.description : noValue}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                           <Table.Cell width={4}>
                              <Header as='h4' >
                                 <Header.Content>
                                    Nip
                                 </Header.Content>
                              </Header>
                           </Table.Cell>
                           <Table.Cell>{jsonClient ? jsonClient.nip : noValue}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                           <Table.Cell width={4}>
                              <Header as='h4' >
                                 <Header.Content>
                                    Identyfikator zewnętrzny
                                 </Header.Content>
                              </Header>
                           </Table.Cell>
                           <Table.Cell>{jsonClient ? jsonClient.external_id : noValue}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                           <Table.Cell width={4}>
                              <Header as='h4' >
                                 <Header.Content>
                                    Kontakt techniczny
                                 </Header.Content>
                              </Header>
                           </Table.Cell>
                           <Table.Cell>{jsonClient ? jsonClient.technical_contact : noValue}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                           <Table.Cell width={4}>
                              <Header as='h4' >
                                 <Header.Content>
                                    Kontakt
                                 </Header.Content>
                              </Header>
                           </Table.Cell>
                           <Table.Cell><Icon name="mobile" />{jsonClient ? jsonClient.phone : noValue} <br /><Icon name="mail" />{jsonClient ? jsonClient.email : noValue}</Table.Cell>
                        </Table.Row>

                     </Table.Body>
                  </Table>
                  <div style={{ display: 'inline-block', width: '100%', borderBottom: '1px solid #2196f3', marginBottom: 10, marginTop: 10 }}>
                     <h5><Icon link name="info circle" color="blue" />OPIS ZLECENIA {isWorker ? "" : getEditIcon(jsonCommision, "Opis", "description", "/commisions", "description")}</h5>
                  </div>
                  {jsonCommision && jsonCommision.description && jsonCommision.description.length > 5 ?
                     <Segment>
                        <Markup content={jsonCommision ? jsonCommision.description : noValue} />
                     </Segment>
                     : "Brak opisu"}


                  {jsonFiles2 !== null && jsonFiles2.length > 0 ? <>LISTA PLIKÓW:{isWorker ? "" : getEditIcon(jsonFiles2, "Lista plików", "Serwisanci", "/commisions/files_workers", "filesWorkers")}<br /></> : ""}
                  {jsonFiles2 !== null && jsonFiles2.length > 0 ?
                     jsonFiles2.map((row, i) =>
                        <>{isWorker ? "" : checkCommisionIsClose() ? <Icon color='grey' title="Zlecenie zostało zakończone" name="trash" /> : <Icon name="trash" className='cursorPointer' color='orange' onClick={() => delFilesWorkers(row.commision_file_worker_id)} />}<a href={row.file_path + "&token=" + authContext.tokenApp} target="_blank" rel='noreferrer'><Icon name="file" />{row.file_name}</a><br /></>
                     )
                     : ""}


                  <div style={{ display: 'inline-block', width: '100%', borderBottom: '1px solid #2196f3', marginBottom: 10, marginTop: 10 }}>
                     <h5><Icon link name="info circle" color="blue" />INFORMACJE O SERWISANTACH {isWorker ? "" : getEditIcon(jsonWorkers, "Serwisanci", "Serwisanci", "/commisions/worker", "workers")}</h5>
                  </div>
                  <Grid centered stackable divided>
                     <Grid.Row  >
                        <Grid.Column width={5}>
                           <Card.Group centered>

                              {jsonWorkers !== null && jsonWorkers.workerOwner.length > 0 ?
                                 jsonWorkers.workerOwner.map((row, i) =>
                                    <Card key={i} color='brown' className='bgb' >
                                       <Card.Content>
                                          <Image
                                             circular
                                             floated='right'
                                             size='mini'
                                             className='commisionSMImage'
                                             src={serviceManPic}
                                          />
                                          <Card.Header>{row.name} {row.surname}</Card.Header>
                                          <Card.Meta>Główny serwisant</Card.Meta>
                                          <Card.Description>
                                             <p><Icon name="mobile" />{row.phone ? row.phone : "Brak"}<br />
                                                <Icon name="mail" />{row.email ? row.email : "Brak"}</p>
                                          </Card.Description>
                                       </Card.Content>

                                    </Card>
                                 )
                                 : ""}

                           </Card.Group>

                        </Grid.Column>
                        <Grid.Column width={11}>


                           {jsonWorkers !== null && jsonWorkers.worker.length > 0 ?
                              <Card.Group centered={true}>
                                 {jsonWorkers.worker.map((row, i) =>
                                    <Card key={i} color='grey' className='bgb'>
                                       <Card.Content>
                                          <Image
                                             circular
                                             floated='right'
                                             size='mini'
                                             className='commisionSMImage'
                                             src={serviceManPicS} />
                                          <Card.Header>{row.name} {row.surname}</Card.Header>
                                          <Card.Meta>Serwisant</Card.Meta>
                                          <Card.Description>
                                             <p><Icon name="mobile" />{row.phone ? row.phone : "Brak"}<br />
                                                <Icon name="mail" />{row.email ? row.email : "Brak"}</p>
                                          </Card.Description>
                                       </Card.Content>

                                    </Card>
                                 )
                                 }</Card.Group>
                              : <Message>
                                 <Message.Header>Brak informacji</Message.Header>
                                 <p>
                                    Do zlecenia nie zostali dodani pomocnicy dla głownego serwisanta
                                 </p>
                              </Message>}



                        </Grid.Column>



                     </Grid.Row>
                  </Grid>
               </Segment>
               <Segment className='bgcard' loading={isloading} color='grey'>
                  <Label as='a' color='grey' ribbon>
                     UZUPEŁNIA SERWISANT
                  </Label>
                  <div style={{ display: 'inline-block', width: '100%', borderBottom: '1px solid #2196f3', marginBottom: 10, marginTop: 10 }}>
                     <h5><Icon link name="info circle" color="blue" />INFORMACJE O WYKORZYSTANYCH ELEMENTACH Z MAGAZYNU {isWorker ? "" : getEditIcon(jsonCommision, "Elementy", "Elementy", "/commisions/elements", "elements")}</h5>
                  </div>


                  {jsonElements !== null && jsonElements.length > 0 ?
                     <div style={{ overflowX: "auto", width: width < 700 ? (width - 110) : "100%" }} >
                        <Table celled padded striped textAlign='center' size='small' compact unstackable>
                           <Table.Header>
                              <Table.Row textAlign='center'>
                                 <Table.HeaderCell>Element</Table.HeaderCell>

                                 <Table.HeaderCell>Ilość użyta podczas serwisu</Table.HeaderCell>
                                 <Table.HeaderCell>Jednostka</Table.HeaderCell>
                                 <Table.HeaderCell></Table.HeaderCell>
                              </Table.Row>
                           </Table.Header>

                           <Table.Body >
                              {jsonElements ? jsonElements.map((row, i) =>
                                 <Table.Row key={i}>
                                    <Table.Cell textAlign='center'>
                                       {row.name}
                                    </Table.Cell>

                                    <Table.Cell textAlign='center'>{row.quantity}</Table.Cell>
                                    <Table.Cell textAlign='center'>
                                       {row.unit}
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>{isWorker ? "" : checkCommisionIsClose() ? <Icon color='grey' title="Zlecenie zostało zakończone" name="trash" /> : <Icon name="trash" className='cursorPointer' color='orange' onClick={() => delElements(row.commision_warehouse_id)} />}</Table.Cell>

                                 </Table.Row>
                              ) : ""}


                           </Table.Body>
                        </Table>
                     </div>
                     : <Message icon info>
                        <Icon name='circle info' />
                        <Message.Content>
                           <Message.Header>Brak informacji o elementach</Message.Header>
                           Główny serwisant nie dodał informacji o użytych elementach w zleceniu.
                        </Message.Content>
                     </Message>}

                  <div style={{ display: 'inline-block', width: '100%', borderBottom: '1px solid #2196f3', marginBottom: 20, marginTop: 20 }}>
                     <h5><Icon link name="info circle" color="blue" />INFORMACJE O ZDJĘCIACH {isWorker ? "" : getEditIcon(jsonFiles, "Lista plików", "files", "/commisions/files", "files")}</h5>
                  </div>
                  {jsonFiles !== null && jsonFiles.length > 0 ?
                     <Card.Group centered>

                        {jsonFiles.map((row, i) =>
                           <Card key={i} color={row.file_path !== null ? 'blue' : 'grey'} className='bgb'>
                              <Card.Content>
                                 <ImagePrime className='imgPrimeCard' src={row.file_path !== null ? row.file_path + "&token=" + authContext.tokenApp : noPic} alt="Image" width="100%" preview={row.file_path !== null ? true : false} />

                                 <Card.Header style={{ width: 150, fontSize: 14 }}><Icon name="file" color="grey" />{row.description}</Card.Header>
                                 <Card.Meta><span className='dateCardFile'><Icon name="file" />{row.created_at}</span></Card.Meta>
                                 <Button size='tiny' onClick={() => {
                                    setopenAddFiles(true)
                                    setdataAddFiles(row)
                                 }}>{row.file_path === null ? "DODAJ PLIK" : "ZMIEŃ PLIK"}</Button>
                              </Card.Content>

                           </Card>
                        )}

                     </Card.Group>
                     : "Brak informacji"}
                  <div style={{ display: 'inline-block', width: '100%', borderBottom: '1px solid #2196f3', marginBottom: 20, marginTop: 20 }}>
                     <h5><Icon link name="info circle" color="blue" />INFORMACJE OPISOWE {isWorker ? "" : getEditIcon(jsonFields, "Lista pól z opisami", "fields", "/commisions/fields", "fields")}</h5>
                  </div>
                  {jsonFields !== null && jsonFields.length > 0 ?
                     <ViewCommisionInputTextAddWorker jsonFields={jsonFields} getListArea={getListArea} commision_id={commision_id} checkCommisionIsClose1={checkCommisionIsClose}/>
                     : "Brak informacji"}

               </Segment>
               <div style={{ display: 'inline-block', width: '100%', borderBottom: '1px solid #2196f3', marginBottom: 15, marginTop: 15 }}>
                  <h5><Icon link name="info circle" color="blue" />ZAMYKANIE ZLECENIA</h5>
               </div>
               <Button fluid style={{ marginTop: 5 }} color={checkCommisionIsClose() ? 'grey' : checkCommisionReadyIsClose() ? 'red' : 'orange'} disabled={checkCommisionIsClose() ? true : checkCommisionReadyIsClose() ? false : true} onClick={() => setopenModalCC(true)}>{checkCommisionIsClose() ? 'ZLECENIE ZOSTAŁO ZAKOŃCZONE' : checkCommisionReadyIsClose() ? 'ZAKOŃCZ ZLECENIE' : 'ZLECENIE NIE ZOSTAŁO JESZCZE WYKONANE W 100%'}</Button>



               <div style={{ display: 'inline-block', width: '100%', borderBottom: '1px solid #2196f3', marginBottom: 15, marginTop: 15 }}>
                  <h5><Icon link name="info circle" color="blue" />HISTORIA CZASU PRACY</h5>
               </div>
               <ToggleButton style={{ marginBottom: 10 }} checked={showMaps} onChange={(e) => setshowMaps(e.value)} onLabel="Ukryj mapę" offLabel="Pokaż mapę" onIcon="pi pi-eye-slash" offIcon="pi pi-map" aria-label="Confirmation" />

               <Tab menu={{ fluid: true, secondary: true, pointing: true, inverted: true, className: "wrappedTab", color: "blue" }} panes={panesHistoryWork} />

            </>
         }
      </>
   );
}

export default ViewCommision;