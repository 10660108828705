import React, { useState, useContext, useEffect } from 'react';
import { Grid, GridColumn, Label, Segment } from 'semantic-ui-react';

import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authContext';
import { cfgToast } from '../../myFunc';
import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Button as ButtonPrime } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiStateCheckbox } from 'primereact/multistatecheckbox';

function SwitchAddDevices({ editDeviceData, setOpen, seteditDeviceData }) {
   const authContext = useContext(AuthContext);

   const optionsIn = [
      { value: -1, icon: 'pi pi-power-off' },
      { value: 0, icon: 'pi pi-sort-down' },
      { value: 1, icon: 'pi pi-sort-up' }
   ];

   const defaultValues = {
      device_id: 0,
      device_name: "",
      description: "",
      id: "",
      owner: "",
      ip_address: "",
      notification: 0,
      enabled: 1,
      enabled_sensor: 0,
      slot_count: null,
      auth: "",
      in1: 0,
      in2: 0,
      in3: 0,
      out1: 0,
      out2: 0,


   }
   const [checkedenabled, setCheckedenabled] = useState(1);
   const [checkedenabled_sensor, setCheckedenabled_sensor] = useState(0);
   const [checkednotification, setCheckednotification] = useState(1);

   const [in1, setin1] = useState(0);
   const [in2, setin2] = useState(0);
   const [in3, setin3] = useState(0);
   const [out1, setout1] = useState(0);
   const [out2, setout2] = useState(0);

   const [isloading, setisloading] = useState(false);
   const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });
   const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };

   const submit = (data) => {
      setisloading(true)

      ConnectWS("/infiber/device", editDeviceData !== null ? "put" : "post", authContext.tokenApp, null, data, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(editDeviceData === null ? "Urządzenie zostało dodane" : "Urządzenie zostało zmodyfikowane", cfgToast);
            authContext.changeUpdataApp(authContext.updataApp + 1)
            // reset()
            setOpen(false)
            seteditDeviceData(null)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })


   }

   useEffect(() => {
      if (editDeviceData) {
         console.log(editDeviceData)
         setValue("device_id", editDeviceData.device_id)
         setValue("device_name", editDeviceData.device_name)
         setValue("description", editDeviceData.description)
         setValue("id", editDeviceData.id)
         setValue("owner", editDeviceData.owner)
         setValue("ip_address", editDeviceData.ip_address)
         setValue("notification", editDeviceData.notification)
         setValue("enabled", editDeviceData.enabled)
         setValue("slot_count", editDeviceData.slot_count)
         setValue("auth", editDeviceData.auth)
         setCheckedenabled(editDeviceData.enabled ? 1 : 0)
         setCheckednotification(editDeviceData.notification ? 1 : 0)

         setValue("in1", editDeviceData.in1)
         setValue("in2", editDeviceData.in2)
         setValue("in3", editDeviceData.in3)
         setValue("out1", editDeviceData.out1)
         setValue("out2", editDeviceData.out2)

         setValue("enabled_sensor", editDeviceData.enabled_sensor)
         setCheckedenabled_sensor(editDeviceData.enabled_sensor ? 1 : 0)

         setin1(editDeviceData.in1)
         setin2(editDeviceData.in2)
         setin3(editDeviceData.in3)

         setout1(editDeviceData.out1)
         setout2(editDeviceData.out2)

      } else {
         reset()
      }

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps


   function getNameState(co,nr){
      if(co===-1){
         return `WEJŚCIE ${nr} WYŁĄCZONE`
      }else if(co===0){
         return `WEJŚCIE ${nr} AKTYWNE STANEM NISKIM`
      }else if(co===1){
         return `WEJŚCIE ${nr} AKTYWNE STANEM WYSOKIM`
      }else{
         return `WEJŚCIE ${nr} ?`
      }
   }

   return (
      <>

         <Segment className="bgcard">
            <form onSubmit={handleSubmit(submit)} className="p-fluid">

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">
                     <Controller name="device_name" control={control} rules={{ required: 'Nazwa jest wymagana' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="device_name" className={classNames({ 'p-error': errors.device_name })}>Nazwa*</label>
                  </span>
                  {getFormErrorMessage('device_name')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">
                     <Controller name="description" control={control} rules={{ required: 'Opis jest wymagany' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="description" className={classNames({ 'p-error': errors.description })}>Opis*</label>
                  </span>
                  {getFormErrorMessage('description')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">
                     <Controller name="id" control={control} rules={{ required: 'Id jest wymagane' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="id" className={classNames({ 'p-error': errors.id })}>Id*</label>
                  </span>
                  {getFormErrorMessage('id')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">
                     <Controller name="owner" control={control} rules={{ required: 'Właściciel urządzenia jest wymagany' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="owner" className={classNames({ 'p-error': errors.owner })}>Właściciel urządzenia</label>
                  </span>
                  {getFormErrorMessage('owner')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">
                     <Controller name="ip_address" control={control} rules={{ required: 'IP jest wymagane', pattern: { value: /^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))$/i, message: 'Błędna adres ip' } }} render={({ field, fieldState }) => (
                        <InputText placeholder='xxx.xxx.xxx.xxx' id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="ip_address" className={classNames({ 'p-error': errors.ip_address })}>IP*</label>
                  </span>
                  {getFormErrorMessage('ip_address')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">
                     <Controller name="slot_count" control={control} rules={{ required: 'Ilość slotów jest wymagana', pattern: { value: /^[0-9]{1,2}$/i, message: 'Błędna ilość slotów' } }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="slot_count" className={classNames({ 'p-error': errors.slot_count })}>Ilość slotów U*</label>
                  </span>
                  {getFormErrorMessage('slot_count')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">
                     <Controller name="auth" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="auth" className={classNames({ 'p-error': errors.auth })}>Auth</label>
                  </span>
                  {getFormErrorMessage('auth')}
               </div>

               <Grid columns={2} stackable stretched>
                  <GridColumn>
                     <Segment>
                        <Label color='blue' ribbon>
                           Wejścia
                        </Label>
                        {/* 
                        <div className="field-checkbox" style={{ paddingTop: 10 }}>
                           <Controller name="in1" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                              <InputSwitch inputId={field.name} onChange={(e) => {
                                 field.onChange(e.value ? 1 : 0)
                                 setin1(e.value ? 1 : 0)
                              }} checked={field.value ? true : false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                           )} />
                           <label htmlFor="in1" className={classNames({ 'p-error': errors.accept })}>{in1 ? "WEJŚCIE 1 AKTYWNE" : "WEJŚCIE 1 NIEAKTYWNE"}</label>

                        </div>
                        <div className="field-checkbox" style={{ paddingTop: 10 }}>
                           <Controller name="in2" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                              <InputSwitch inputId={field.name} onChange={(e) => {
                                 field.onChange(e.value ? 1 : 0)
                                 setin2(e.value ? 1 : 0)
                              }} checked={field.value ? true : false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                           )} />
                           <label htmlFor="in2" className={classNames({ 'p-error': errors.accept })}>{in2 ? "WEJŚCIE 2 AKTYWNE" : "WEJŚCIE 2 NIEAKTYWNE"}</label>

                        </div>
                        <div className="field-checkbox" style={{ paddingTop: 10 }}>
                           <Controller name="in3" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                              <InputSwitch inputId={field.name} onChange={(e) => {
                                 field.onChange(e.value ? 1 : 0)
                                 setin3(e.value ? 1 : 0)
                              }} checked={field.value ? true : false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                           )} />
                           <label htmlFor="in3" className={classNames({ 'p-error': errors.accept })}>{in3 ? "WEJŚCIE 3 AKTYWNE" : "WEJŚCIE 3 NIEAKTYWNE"}</label>

                        </div>
                        */}
                        <div className="field-checkbox" style={{ paddingTop: 10 }}>
                           <Controller name="in1" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                              <MultiStateCheckbox  value={in1} inputId={field.name} onChange={(e) => {
                                 field.onChange(e.value===null ? -1 : e.value)
                                 setin1(e.value===null ? -1 : e.value)
                              }}  options={optionsIn} optionValue="value" />
                           )} />
                           <label htmlFor="in1" className={classNames({ 'p-error': errors.accept })}>{getNameState(in1,1)}</label>

                        </div>
                        <div className="field-checkbox" style={{ paddingTop: 10 }}>
                           <Controller name="in2" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                              <MultiStateCheckbox  value={in2} inputId={field.name} onChange={(e) => {
                                 field.onChange(e.value===null ? -1 : e.value)
                                 setin2(e.value===null ? -1 : e.value)
                              }}  options={optionsIn} optionValue="value" />
                           )} />
                           <label htmlFor="in2" className={classNames({ 'p-error': errors.accept })}>{getNameState(in2,2)}</label>

                        </div>
                        <div className="field-checkbox" style={{ paddingTop: 10 }}>
                           <Controller name="in3" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                              <MultiStateCheckbox  value={in3} inputId={field.name} onChange={(e) => {
                                 field.onChange(e.value===null ? -1 : e.value)
                                 setin3(e.value===null ? -1 : e.value)
                              }}  options={optionsIn} optionValue="value" />
                           )} />
                           <label htmlFor="in3" className={classNames({ 'p-error': errors.accept })}>{getNameState(in3,3)}</label>

                        </div>
                     </Segment>
                  </GridColumn>
                  <GridColumn>
                     <Segment>
                        <Label color='blue' ribbon>
                           Wyjścia
                        </Label>
                        <div className="field-checkbox" style={{ paddingTop: 10 }}>
                           <Controller name="out1" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                              <InputSwitch inputId={field.name} onChange={(e) => {
                                 field.onChange(e.value ? 1 : 0)
                                 setout1(e.value ? 1 : 0)
                              }} checked={field.value ? true : false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                           )} />
                           <label htmlFor="out1" className={classNames({ 'p-error': errors.accept })}>{out1 ? "WYJŚCIE 1 AKTYWNE" : "WYJŚCIE 1 NIEAKTYWNE"}</label>

                        </div>
                        <div className="field-checkbox" style={{ paddingTop: 10 }}>
                           <Controller name="out2" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                              <InputSwitch inputId={field.name} onChange={(e) => {
                                 field.onChange(e.value ? 1 : 0)
                                 setout2(e.value ? 1 : 0)
                              }} checked={field.value ? true : false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                           )} />
                           <label htmlFor="out2" className={classNames({ 'p-error': errors.accept })}>{out2 ? "WYJŚCIE 2 AKTYWNE" : "WYJŚCIE 2 NIEAKTYWNE"}</label>

                        </div>
                     </Segment>
                  </GridColumn>
                 
               </Grid>

               <Segment>

                  <div className="field-checkbox" style={{ paddingTop: 10 }}>
                     <Controller name="notification" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                        <InputSwitch inputId={field.name} onChange={(e) => {
                           field.onChange(e.value ? 1 : 0)
                           setCheckednotification(e.value ? 1 : 0)
                        }} checked={field.value ? true : false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="notification" className={classNames({ 'p-error': errors.accept })}>{checkednotification ? "POWIADOMIENIA AKTYWNE" : "POWIADOMIENIA NIEAKTYWNE"}</label>

                  </div>
                  <div className="field-checkbox" style={{ paddingTop: 10 }}>
                     <Controller name="enabled" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                        <InputSwitch inputId={field.name} onChange={(e) => {
                           field.onChange(e.value ? 1 : 0)
                           setCheckedenabled(e.value ? 1 : 0)
                        }} checked={field.value ? true : false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="enabled" className={classNames({ 'p-error': errors.accept })}>{checkedenabled ? "URZĄDZENIE AKTYWNE" : "URZĄDZENIE NIEAKTYWNE"}</label>

                  </div>
                  <div className="field-checkbox" style={{ paddingTop: 10 }}>
                           <Controller name="enabled_sensor" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                              <InputSwitch inputId={field.name} onChange={(e) => {
                                 field.onChange(e.value ? 1 : 0)
                                 setCheckedenabled_sensor(e.value ? 1 : 0)
                              }} checked={field.value ? true : false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                           )} />
                           <label htmlFor="enabled_sensor" className={classNames({ 'p-error': errors.accept })}>{checkedenabled_sensor ? "CZUJNIK ZAMONTOWANY" : "BRAK CZUJNIKA"}</label>

                        </div>
               </Segment>
               <ButtonPrime loading={isloading} type="submit" label={editDeviceData === null ? "Dodaj urządzenie" : "Modyfikuj urządzenie"} className="mt-2 p-button" />
            </form>
         </Segment>
      </>
   );
}

export default SwitchAddDevices;