import React, { useState, useContext, useEffect } from 'react';
import { Card } from 'primereact/card';
import { toast } from 'react-toastify';
import { cfgToast, changeFormatDate } from '../../../myFunc';
import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';

import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import AuthContext from '../../../context/authContext';
import { Button as ButtonPrime } from 'primereact/button';

import { Calendar } from 'primereact/calendar';

function AddPermissions(props) {
   const authContext = useContext(AuthContext);
   const defaultValues = {
      name: "",
      start_at: "",
      end_at: "",
      user_id: 0

   }
   const [isloading, setisloading] = useState(false);
   const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });
   const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };

   useEffect(() => {

      if (props.user_id) {
         setValue("user_id", props.user_id)
      }

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [props.user_id]);// eslint-disable-line react-hooks/exhaustive-deps



   const submit = (data) => {
      setisloading(true)
      let tmp = { ...data }
      tmp.start_at = changeFormatDate(data.start_at)
      tmp.end_at = changeFormatDate(data.end_at)

      ConnectWS("/users/user_power", "post", authContext.tokenApp, null, tmp, function (response, logout) {
         if (logout === 1) {
            toast.error("Brak autoryzacji 401", cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            // authContext.changeUpdataApp(authContext.updataApp + 1)
            props.setappType(0)
            reset()
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })


   }


   return (
      <>
         <Card className="bgcard">
            <form onSubmit={handleSubmit(submit)} className="p-fluid">

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="name" control={control} rules={{ required: 'Imie jest wymagane' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Nazwa*</label>
                  </span>
                  {getFormErrorMessage('name')}
               </div>

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="start_at" control={control} rules={{ required: 'Data jest wymagana' }} render={({ field, fieldState }) => (
                        <Calendar dateFormat="yy-mm-dd" ref={(ref) => { }} id={field.name} value={field.value} onChange={(e) => {
                           field.onChange(e.value)
                        }} className={classNames({ 'p-invalid': fieldState.invalid })}></Calendar>
                     )} />
                     <label htmlFor="start_at" className={classNames({ 'p-error': errors.start_at })}>Data od*</label>
                  </span>
                  {getFormErrorMessage('start_at')}
               </div>

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="end_at" control={control} rules={{ required: 'Data jest wymagana' }} render={({ field, fieldState }) => (
                        <Calendar dateFormat="yy-mm-dd" ref={(ref) => { }} id={field.name} value={field.value} onChange={(e) => {
                           field.onChange(e.value)
                        }} className={classNames({ 'p-invalid': fieldState.invalid })}></Calendar>
                     )} />
                     <label htmlFor="end_at" className={classNames({ 'p-error': errors.end_at })}>Data do*</label>
                  </span>
                  {getFormErrorMessage('end_at')}
               </div>


               <ButtonPrime loading={isloading} type="submit" label="Zapisz dane" className="mt-2 p-button" />
            </form>
         </Card>
      </>
   );
}

export default AddPermissions;