import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Grid, Icon, Label, Modal, Segment } from 'semantic-ui-react';
import AGGridActivity from '../AGGrid/AGGridActivity';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_PL } from '../AGGrid/LanguageAGG';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authContext';
import { cfgToast } from '../../myFunc';
import ModalUIHeader from '../../layout/ModalUI';
import SwitchAddDevices from './SwitchAddDevices';
import { confirmAlert } from 'react-confirm-alert';

function SwitchDevices(props) {
   const authContext = useContext(AuthContext);
   const [open, setOpen] = useState(false)

   const [editDeviceData, seteditDeviceData] = useState(null)

   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };

   const statusIcon = props => {

      if (props.data.enabled === 1) {
         if (props.data.alive === 1) {
            return <Icon name="hdd" color='green' size='large' />;
         } else {
            return <Icon name="hdd" color='red' size='large' />;
         }
      } else {
         return <Icon name="hdd" color='grey' size='large' />;
      }


   };



   let interval = useRef(null);
   const [value1, setValue1] = useState('Live');// eslint-disable-line no-unused-vars



   useEffect(() => {
      getData()
      if (value1 === "Live") {
         interval.current = setInterval(() => {
            getData()
         }, 10000);
      } else {
         clearInterval(interval.current)
      }

      return () => { clearInterval(interval.current) }; // use effect cleanup to set flag false, if unmounted
   }, [value1, authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps


   function getData() {

      ConnectWS("/infiber/device", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            if (response.data.length > 0) {
               setRowData(response.data)
            } else {
               setRowData([])
            }

         } else {
            setRowData([])
            toast.error(response, cfgToast);
            console.log(response);
         }



      })
   }



   const isactiveIcon = props => {
      let ret
      props.data.notification ? ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='green' title="Aktywne" >
         AKTYWNE
      </Label> : ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='grey' title="Nieaktywne" >
         NIEAKTYWNE
      </Label>
      return ret
   };


   const inAll = props => {
      let ret = []
      if (props.data.in1 === 1) {
         ret.push(<Icon name="chevron circle up" size='large' color='green' title="Wejście 1 właczone stan wysoki" />)
      }else if (props.data.in1 === 0) {
         ret.push(<Icon name="chevron circle down" size='large' color='green' title="Wejście 1 wyłaczone stan niski" />)
      } 
      else {
         ret.push(<Icon name="power off" size='large' color='grey' title="Wejście 1 wyłaczone" />)
      }

      if (props.data.in2 === 1) {
         ret.push(<Icon name="chevron circle up" size='large' color='green' title="Wejście 1 właczone stan wysoki" />)
      }else if (props.data.in2 === 0) {
         ret.push(<Icon name="chevron circle down" size='large' color='green' title="Wejście 1 wyłaczone stan niski" />)
      } 
      else {
         ret.push(<Icon name="power off" size='large' color='grey' title="Wejście 1 wyłaczone" />)
      }

      if (props.data.in3 === 1) {
         ret.push(<Icon name="chevron circle up" size='large' color='green' title="Wejście 1 właczone stan wysoki" />)
      }else if (props.data.in3 === 0) {
         ret.push(<Icon name="chevron circle down" size='large' color='green' title="Wejście 1 wyłaczone stan niski" />)
      } 
      else {
         ret.push(<Icon name="power off" size='large' color='grey' title="Wejście 1 wyłaczone" />)
      }

      return <>{ret}</>
   };


   const outAll = props => {

      let ret = []
      if (props.data.out1 === 1) {
         ret.push(<Icon name="check circle" size='large' color='green' title="Wyjście 1 właczone" />)
      } else {
         ret.push(<Icon name="power off" size='large' color='grey' title="Wyjście 1 wyłaczone" />)
      }

      if (props.data.out2 === 1) {
         ret.push(<Icon name="check circle" size='large' color='green' title="Wyjście 2 właczone" />)
      } else {
         ret.push(<Icon name="power off" size='large' color='grey' title="Wyjście 2 wyłaczone" />)
      }



      return <>{ret}</>
   };






   const iconSensorEnabled = props => {
      //device_sensors
      let ret = ""
      if (props.data.enabled_sensor===1) {
         ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='blue' title="CZUJNIK ZAMONTOWANY" >
         ZAMONTOWANY
      </Label>
      }else{
         ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='grey' title="BRAK CZUJNIKA" >
         BRAK
      </Label>
      }
      return ret
   };

   const iconSensors = props => {


      //device_sensors
      let ret = []

      if (props.data.device_sensors.length > 0) {
         props.data.device_sensors.forEach((row, i) => {

            if (row.value > 80) {
               ret.push(<Label style={{ minWidth: 50, textAlign: "center" }} title={row.sensor_name}><span key={i + row.sensor_name} style={{ color: "red" }}> {row.value} {row.unit} </span></Label>)
            } else if (row.value > 50) {
               ret.push(<Label style={{ minWidth: 50, textAlign: "center" }} title={row.sensor_name}><span key={i + row.sensor_name} style={{ color: "orange" }}> {row.value} {row.unit} </span></Label>)
            } else if (row.value > 0) {
               ret.push(<Label style={{ minWidth: 50, textAlign: "center" }} title={row.sensor_name}><span key={i + row.sensor_name} style={{ color: "green" }}> {row.value} {row.unit} </span></Label>)
            } else {
               ret.push(<Label style={{ minWidth: 50, textAlign: "center" }} title={row.sensor_name}><span key={i + row.sensor_name} style={{ color: "grey" }}> {row.value} {row.unit} </span></Label>)
            }
         })
      }
      return <>{ret}</>
   };



   const delRowIcon = props => {
      const DelObjectRow = () => {
         confirmAlert({
            title: 'Czy chcesz skasować rekord?',
            message: 'Proszę potwierdzić',
            buttons: [
               {
                  label: 'Tak',
                  onClick: () => DelObjectRowACC(props.data)
               },
               {
                  label: 'Nie',
                  onClick: () => ''
               }
            ]
         });
      };

      return <Icon name="trash" color='grey' size='large' className='cursorPointer' onClick={DelObjectRow} />
   };

   function DelObjectRowACC(data) {
      ConnectWS("/infiber/device", "delete", authContext.tokenApp, null, { device_id: data.device_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            getData()
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }

   const editRowIcon = props => {
      const editRowIconA = () => {
         seteditDeviceData(props.data)
         setOpen(true)
      };

      return <Icon name="edit" color='grey' size='large' className='cursorPointer' onClick={editRowIconA} />
   };

   return (
      <> <Modal
         onClose={() => setOpen(false)}
         onOpen={() => setOpen(true)}
         open={open}


         centered={false}
      >
         <Modal.Header>
            <ModalUIHeader propsUiName={editDeviceData === null ? "Dodaj urządzenie" : "Edytuj urządzenie"} />

         </Modal.Header>

         <Modal.Content className='bg'>
            <SwitchAddDevices editDeviceData={editDeviceData} setOpen={setOpen} seteditDeviceData={seteditDeviceData} />
         </Modal.Content>
         <Modal.Actions>
            <Button size='tiny' onClick={() => {
               seteditDeviceData(null)
               setOpen(false)
            }}>
               ZAMKNIJ
            </Button>

         </Modal.Actions>
      </Modal>
         <Segment className='bgcard'>
            <Grid stackable >
               <Grid.Row >
                  <Grid.Column width={3} style={{ marginTop: 3 }}>

                     <Button fluid size='tiny' className='appbtnmtd' color='blue' onClick={() => setOpen(true)}>Dodaj urządzenie</Button>
                  </Grid.Column>
                  <Grid.Column width={13} >
                     <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
                  </Grid.Column>
               </Grid.Row>
            </Grid>

            <div
               id="myGrid"
               style={{
                  height: props.minHeight - 80,
                  // height:  '100%',
                  width: '100%',
                  marginTop: 5
               }}
               className="ag-theme-balham"
            >
               <AgGridReact



                  animateRows={true}
                  //pagination={true}
                  defaultColDef={{
                     editable: false,
                     sortable: true,
                     filter: false,
                     width: 120,
                     minWidth: 120,
                     wrapText: true,
                     autoHeight: true,
                     resizable: true,
                     floatingFilter: false,
                     flex: 1,
                  }}
                  frameworkComponents={{
                     statusIcon: statusIcon,
                     isactiveIcon: isactiveIcon,
                     delRowIcon: delRowIcon,
                     editRowIcon: editRowIcon,
                     iconSensors: iconSensors,
                     inAll: inAll,
                     outAll: outAll,
                     iconSensorEnabled: iconSensorEnabled
                  }}
                  rowClassRules={{
                     'row-error-blink': function (params) {

                        if (params.data.alive === 0 && params.data.enabled === 1) {
                           return true
                        }
                        return false

                     }
                  }}
                  getRowId={function (data) {
                     return data.data.device_id

                  }}
                  localeText={AG_GRID_LOCALE_PL}
                  rowData={rowData}
                  onGridReady={onGridReady}
               >
                  <AgGridColumn cellRenderer='statusIcon'
                     width={50}
                     minWidth={50}
                     flex={0}
                  />

                  <AgGridColumn field="device_name" headerName='Nazwa' filter='agTextColumnFilter' />
                  <AgGridColumn field="description" headerName='Opis' filter='agTextColumnFilter' />
                  <AgGridColumn field="id" headerName='Id' filter='agTextColumnFilter' />
                  <AgGridColumn field="ip_address" headerName='IP' filter='agTextColumnFilter' />
                  <AgGridColumn field="owner" headerName='Właściciel' filter='agTextColumnFilter' />
                  <AgGridColumn field="slot_count" headerName='Ilość slotów' filter='agTextColumnFilter' />
                  <AgGridColumn field="auth" headerName='Auth' filter='agTextColumnFilter' />

                  <AgGridColumn cellRenderer='iconSensors' headerName='Czujniki' width={200}
                     minWidth={200}
                     flex={0} />

                  <AgGridColumn cellRenderer='iconSensorEnabled' headerName='Czujnik'
                     width={130}
                     minWidth={130}
                     flex={0}
                  />
                  <AgGridColumn cellRenderer='isactiveIcon'
                     headerName='Powiadomienia'
                     width={110}
                     minWidth={110}
                  />
                  <AgGridColumn field="modified_at" width={150}
                     minWidth={150} flex={0} headerName='Ostatni odczyt' filter='agTextColumnFilter' />




                  <AgGridColumn cellRenderer='inAll' headerName='Wejścia'
                     width={100}
                     minWidth={100}
                     flex={0}
                  />
                  <AgGridColumn cellRenderer='outAll' headerName='Wyjścia'
                     width={100}
                     minWidth={100}
                     flex={0}
                  />

                  <AgGridColumn cellRenderer='editRowIcon'
                     width={45}
                     minWidth={45}
                     flex={0}
                  />


                  <AgGridColumn cellRenderer='delRowIcon'
                     width={45}
                     minWidth={45}
                     flex={0}
                  />


               </AgGridReact>
            </div>
         </Segment>
      </>
   );
}

export default SwitchDevices;