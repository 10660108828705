import React from 'react'
import {Header, Segment } from 'semantic-ui-react';


function NotFoundView(props) {
  return (
    <Segment placeholder textAlign='center' style={{ marginBottom: 10,minHeight: props.minHeight,height:1  }}>
        <Header style={{ fontSize: 60 }}>
          BŁĄD 404
          <Header.Subheader style={{ fontSize: 30 }}>
            UPS! NIE UDAŁO SIĘ ZNALEŹĆ FUNKCJI...

          </Header.Subheader>
          <Header.Subheader>
            Skontaktuj się z adminem.
          </Header.Subheader>
        </Header>
    </Segment>
  );
}

export default NotFoundView;