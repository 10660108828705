import React, { useEffect, useState, useContext } from 'react';
import { Label, Message, Segment } from 'semantic-ui-react';
import { AG_GRID_LOCALE_PL } from '../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authContext';
import { cfgToast } from '../../myFunc';


import AGGridActivity from '../AGGrid/AGGridActivity';





function GetWarehouse(props) {
   const authContext = useContext(AuthContext);
   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);



   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };



   function getAllItems(user_id, client_id) {
      if (gridApi && gridApi.destroyCalled === false) {
         gridApi.showLoadingOverlay();
      }
      setRowData(null)
      let json
      if (user_id) {
         json = { user_id: user_id }
      } else if (client_id) {
         json = { client_id: client_id }
      } else {
         json = null
      }
      ConnectWS("/warehouse/warehouses_elements", "get", authContext.tokenApp, null, json, function (response, logout) {
         if (logout === 1) {
            toast.error("Brak autoryzacji 401", cfgToast);
            if (gridApi) {
               gridApi.showNoRowsOverlay();
            }
         } else if (Number(response.status) === 200) {

            if (response.data.length > 0) {
               setRowData(response.data)
               if (gridApi) {
                  gridApi.hideOverlay();
               }
            } else {
               setRowData([])
               if (gridApi) {
                  gridApi.showNoRowsOverlay();
               }
            }

         } else {
            toast.error(response, cfgToast);
            console.log(response);
            if (gridApi) {
               gridApi.showNoRowsOverlay();
            }
         }


      })
   }



   useEffect(() => {
      if (props.user_id) {
         getAllItems(props.user_id, null)
      }
      if (props.client_id) {
         getAllItems(null, props.client_id)
      }

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [props.user_id, props.client_id]);// eslint-disable-line react-hooks/exhaustive-deps





   function warehouseTMP(props) {
      return <span>{props.data.owner_client ? props.data.owner_client : props.data.owner_user ? props.data.owner_user : "n/n"}</span>
   }


   return (
      <div style={{ minHeight: props.minHeight }}>
         <Segment  >
            <Label color='blue' ribbon>
               MAGAZYN
            </Label>
            {rowData && rowData.length === 0 ? <Message
               header='Magazyn pusty'
               content='Brak elementów przypisanych do magazynu'
            /> : <>

               <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
               <div
                  id="myGrid"
                  style={{
                     height: props.minHeight - 100,
                     // height:  '100%',
                     width: '100%',
                     marginTop: 5
                  }}
                  className="ag-theme-balham"
               >
                  <AgGridReact
                     //rowHeight={rowHeightAGG}
                     rowSelection={'single'}

                     suppressRowClickSelection={true}

                     animateRows={true}
                     pagination={true}
                     defaultColDef={{
                        editable: false,
                        sortable: true,
                        filter: false,
                        width: 100,
                        minWidth: 100,
                        //przyśpiesza odświeżanie
                        //wrapText: true,
                        //autoHeight: true,
                        resizable: true,
                        floatingFilter: false,
                        flex: 1,
                     }}
                     frameworkComponents={{
                        warehouseTMP: warehouseTMP,
                     }}

                     getRowId={function (data) {
                        return data.data.warehouse_element_id


                     }}
                     localeText={AG_GRID_LOCALE_PL}
                     rowData={rowData}
                     onGridReady={onGridReady}
                  >
                     {/*
                           <AgGridColumn cellRenderer='warehouseTMP'
                              width={49}
                              minWidth={49}
                              flex={0}
                           />
                        */}
                     <AgGridColumn field="element_id" headerName='Identyfikator baza' filter='agTextColumnFilter' />
                     <AgGridColumn
                        field="name" headerName='Nazwa' filter='agTextColumnFilter' />
                     <AgGridColumn field="short_name" headerName='Skrócona nazwa' filter='agTextColumnFilter' />
                     <AgGridColumn field="description" headerName='Opis' filter='agTextColumnFilter' />
                     <AgGridColumn field="ean_number" headerName='Ean' filter='agTextColumnFilter' />
                     <AgGridColumn field="serial_number" headerName='Numer seryjny' filter='agTextColumnFilter' />
                     <AgGridColumn field="unit" headerName='Jednostka' filter='agTextColumnFilter' />
                     <AgGridColumn field="quantity" headerName='Ilość sztuk w magazynie' filter='agNumberColumnFilter' />
                     <AgGridColumn field="quantity_reserves" headerName='Ilość zablokowana' filter='agNumberColumnFilter' />
                     <AgGridColumn cellRenderer='warehouseTMP' headerName='Magazyn' filter='agTextColumnFilter'
                        filterParams={{
                           valueGetter: params => {
                              return params.data.owner_client ? params.data.owner_client : params.data.owner_user
                           }
                        }}
                     />
                  </AgGridReact>
               </div>

            </>}

         </Segment>
      </div>
   );
}

export default GetWarehouse;