import React, { useEffect, useState, useContext } from 'react';
import {  Divider,  Message } from 'semantic-ui-react';

import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authContext';
import { cfgToast } from '../../myFunc';
import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';
import { Button as ButtonPrime } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Card } from 'primereact/card';
import { InputMask } from 'primereact/inputmask';


function AppEditUser(props) {
   const authContext = useContext(AuthContext);
   const defaultValues = { 
      user_id: 0,
      username: "",
      surname: "",
      name: "",
      enabled: 0,
      email: "",
      phone:"",
      notification:0,

   }
   const defaultValuesPassword = {
      password: "",
      password2: "",
      user_id: 0,

   }

   const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });
   const { control: control2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, setValue: setValue2 } = useForm({ defaultValuesPassword });

   const [isloading, setisloading] = useState(false);
   const [isloadingPassword, setisloadingPassword] = useState(false);
   const [checked, setChecked] = useState(false);
   const [checkedNotification, setcheckedNotification] = useState(false); // eslint-disable-line no-unused-vars
   const [ErrorPassword, setErrorPassword] = useState(false);

   const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };

   const getFormErrorMessage2 = (name) => {
      return errors2[name] && <small className="p-error">{errors2[name].message}</small>
   };

   useEffect(() => {
     
      if (props.userInfo.allInfo) {
         console.log(props.userInfo.allInfo)
         setValue("email", props.userInfo.allInfo.email)
         setValue("enabled", props.userInfo.allInfo.enabled ? 1 : 0)
         setValue("name", props.userInfo.allInfo.name)
         setValue("username", props.userInfo.allInfo.username)
         setValue("surname", props.userInfo.allInfo.surname)
         setValue("user_id", props.userInfo.allInfo.user_id)
         setValue("phone", props.userInfo.allInfo.phone)
         setValue2("user_id", props.userInfo.allInfo.user_id)
         setChecked(props.userInfo.allInfo.enabled ? 1 : 0)
         setValue("notification", props.userInfo.allInfo.notification)
         setcheckedNotification(props.userInfo.allInfo.notification ? 1 : 0)
      }

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [props]);// eslint-disable-line react-hooks/exhaustive-deps


   const submit = (data) => {
      setisloading(true)

      ConnectWS("/users/change_data", "put", authContext.tokenApp, null,data, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp+1)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })


   }

   const submitPassword = (data) => {
      setisloadingPassword(true)
      if (data.password === data.password2) {
         setErrorPassword(false)

         ConnectWS("/users/change_password", "put", authContext.tokenApp, null,data, function (response, logout) {
            if (logout === 1) {
               toast.error(response, cfgToast);
            } else if (Number(response.status) === 200) {
               toast.success(response.data.detail, cfgToast);
               authContext.changeUpdataApp(authContext.updataApp+1)
            } else {
               toast.error(response, cfgToast);
               console.log(response);
            }
            setisloadingPassword(false)
         })

        
      } else {
         setErrorPassword('Hasła różnią się od siebie!')
         setisloadingPassword(false)
      }

   }

   const passwordFooter = (
      <React.Fragment>
         <Divider />
         <p className="mt-2">Wymagane</p>
         <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
            <li>Minimum 8 znaków</li>
            <li>Dopuszczalne znaki specjalne .!#@$&?</li> 
         </ul>
      </React.Fragment>
   );

   return (
      <>
         <Card className="bgcard">
            <form onSubmit={handleSubmit(submit)} className="p-fluid">

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-edit" />
                     <Controller name="name" control={control} rules={{ required: 'Imie jest wymagane' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Imie*</label>
                  </span>
                  {getFormErrorMessage('name')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-edit" />
                     <Controller name="surname" control={control} rules={{ required: 'Nazwisko jest wymagane' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="surname" className={classNames({ 'p-error': errors.surname })}>Nazwisko*</label>
                  </span>
                  {getFormErrorMessage('surname')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-phone" />
                     <Controller name="phone" control={control}  render={({ field, fieldState }) => (

                        <InputMask mask="999999999" slotChar="xxxxxxxxx" id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })}></InputMask>
 
                     )} />
                     <label htmlFor="phone" className={classNames({ 'p-error': errors.phone })}>Telefon</label>
                  </span>
                  {getFormErrorMessage('phone')}
               </div>

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-envelope" />
                     <Controller name="email" control={control}
                        rules={{ required: 'Email jest wymagany.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Błędny email, przykład: example@email.com' } }}
                        render={({ field, fieldState }) => (
                           <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                     <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>Email*</label>
                  </span>
                  {getFormErrorMessage('email')}
               </div>
               <div className="field-checkbox" style={{ paddingTop: 10 }}>
                  <Controller name="notification" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                     <InputSwitch inputId={field.name} onChange={(e) => {
                        field.onChange(e.value ? 1 : 0)
                        setcheckedNotification(e.value ? 1 : 0)
                     }} checked={field.value ? true : false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                  )} />
                  <label htmlFor="notification" className={classNames({ 'p-error': errors.notification })}>{checkedNotification ? "POWIADOMIENIA AKTYWNE" : "POWIADOMIENIA NIEAKTYWNE"}</label>

               </div>           

               <div className="field-checkbox" style={{ paddingTop: 10 }}>
                  <Controller name="enabled" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                     <InputSwitch inputId={field.name} onChange={(e) => {
                        field.onChange(e.value ? 1 : 0)
                        setChecked(e.value ? 1 : 0)
                     }} checked={field.value ? true : false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                  )} />
                  <label htmlFor="enabled" className={classNames({ 'p-error': errors.enabled })}>{checked ? "KONTO AKTYWNE" : "KONTO NIEAKTYWNE"}</label>

               </div>

               <ButtonPrime loading={isloading} type="submit" label="Zapisz dane" className="mt-2 p-button" />
            </form>


         </Card>
         <Divider style={{ marginTop: 15, marginBottom: 15 }} horizontal>
            <span className='dc'>ZMIANA HASŁA</span>  </Divider>
         <Card className="bgcard">
            <form onSubmit={handleSubmit2(submitPassword)} className="p-fluid">


               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">
                     <Controller name="password" control={control2} 
                     rules={{ required: 'Hasło jest wymagane.', pattern: { value: /^[A-Z0-9.!#@$&?]{8,20}$/i, message: 'Hasło nie spełnia wymagań' } }}
                        render={({ field, fieldState }) => (

                           <Password promptLabel="Wpisz Hasło" weakLabel="Słabe hasło" mediumLabel="Średnie hasło" strongLabel="Śilne hasło" {...field} ref={(ref) => { }} feedback={true} footer={passwordFooter} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} />

                        )}
                     />
                     <label htmlFor="password" className={classNames({ 'p-error': errors2.password })}>Hasło*</label>
                  </span>
                  {getFormErrorMessage2('password')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">
                     <Controller name="password2" control={control2}
                     rules={{ required: 'Hasło jest wymagane.', pattern: { value: /^[A-Z0-9.!#@$&?]{8,20}$/i, message: 'Hasło nie spełnia wymagań' } }}

                        render={({ field, fieldState }) => (

                           <Password promptLabel="Wpisz Hasło" weakLabel="Słabe hasło" mediumLabel="Średnie hasło" strongLabel="Śilne hasło" id={field.name} {...field} ref={(ref) => { }} feedback={true} footer={passwordFooter} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} />

                        )}
                     />
                     <label htmlFor="password2" className={classNames({ 'p-error': errors2.password2 })}>Powtórz Hasło*</label>
                  </span>
                  {getFormErrorMessage2('password2')}
               </div>

               {ErrorPassword !== false ? <Message error
                  icon='exclamation circle'
                  header={ErrorPassword}

               /> : ""}

               <ButtonPrime loading={isloadingPassword} type="submit" label="Zmiana Hasła" className="mt-2 p-button" />
            </form>
         </Card>
      </>
   );
}

export default AppEditUser;