import React, { useEffect, useState, useContext } from 'react';
import { Dropdown as DropdownSemantic, Divider, Grid, Icon, Label, Segment, Header, List, Message, Modal, Button } from 'semantic-ui-react';

import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/authContext';
import { cfgToast,  changeFormatDateTimeDBns, } from '../../../myFunc';

import { Button as ButtonPrime } from 'primereact/button';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';

import { Dropdown } from 'primereact/dropdown';
import { Editor } from 'primereact/editor';
import ModalUI from '../../../layout/ModalUI';
import AddFileCommisionToCommision from './AddFileCommisionToCommision';


function AddSubCommision(props) {
   const authContext = useContext(AuthContext);
   const [isloading, setisloading] = useState(false);
   const [isSubServiceman, setisSubServiceman] = useState(0);
   const [filesTemplate, setFilesTemplate] = useState([]);
   const [textTemplate, setTextTemplate] = useState([]);
   const [commision_id, setcommision_id] = useState(0);

   const [openModalAddFiles, setopenModalAddFiles] = useState(false);


   const [formListFile, setFormListFile] = useState({
      listFile: [],
      fileNameTmp: null,
      fileNameTmpDB: null,
   });

   const [formListText, setformListText] = useState({
      listText: [],
      textDescription: null,
   });

   const Overtime = [
      { value: 1, label: 'TAK' },
      { value: 0, label: 'NIE' },
   ]


   const defaultValues = {
      service_id: 0,
      name: "",
      description: "",
      extserviceid: "",
      overtime: 0,
      description_worker: "",
      dateFrom: null,
      dateTo: null,
      clientInfo: null,
      typeService: null,
      masterServiceman: null,
      subServiceman: null,
   }
   const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });
   const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };


   const submit = (data) => {
      setisloading(true)

      let client_id = data.clientInfo.client_id
      let name = data.name
      let description = data.description
      let description_worker = data.description_worker
      let user_id_owner = data.masterServiceman.user_id
      let status_id = 1
      let type_id = JSON.parse(data.typeService).commision_type_id
      let overtime = data.overtime
      let scheduled_start_at = data.dateFrom
      let scheduled_end_at = data.dateTo
      let file_list = formListFile.listFile
      let fields_list = formListText.listText
      let extserviceid = data.extserviceid
      let workers = []
      if(data.subServiceman && data.subServiceman.length>0){
         data.subServiceman.forEach(row => workers.push(row.user_id))
      }
      
      let data1 = {
         client_id: client_id, description: description, description_worker: description_worker, user_id_owner: user_id_owner, status_id: status_id, type_id: type_id, overtime: overtime,
         scheduled_start_at: scheduled_start_at, scheduled_end_at: scheduled_end_at, workers: workers, file_list: file_list,fields_list:fields_list, extserviceid: extserviceid, name: name
      }


      ConnectWS("/commisions", "post", authContext.tokenApp, null, data1, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            setcommision_id(response.data.commision_id)
            setopenModalAddFiles(true)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })


   }

   

   function addTextToList() {
      console.log(formListText)
      let arrtmp = formListText.listText !== null ? formListText.listText : [];
      arrtmp.push({ name: formListText.textDescription })
      setformListText({ ...formListText, listText: arrtmp })
   }

   function addFileToList() {
      let arrtmp = formListFile.listFile !== null ? formListFile.listFile : [];
      arrtmp.push({ description: formListFile.fileNameTmp, file_name: formListFile.fileNameTmpDB, })
      setFormListFile({ ...formListFile, listFile: arrtmp })
   }

   
   function removeTextToList(index) {
      let arrtmp = formListText.listText !== null ? formListText.listText : [];
      arrtmp.splice(index, 1)
      setformListText({ ...formListText, listText: arrtmp })
   }

   function removeFileToList(index) {
      let arrtmp = formListFile.listFile !== null ? formListFile.listFile : [];
      arrtmp.splice(index, 1)
      setFormListFile({ ...formListFile, listFile: arrtmp })
   }


   function normalizeFileName(txt) {
      txt = txt.replace(/ /g, '_').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ł/g, "l");
      return txt
   }

   useEffect(() => {
      getCommisionsFilesTemplate()
      getCommisionsTextTemplate()
      if (props.form1) {
         setValue("dateFrom", changeFormatDateTimeDBns(props.form1.dateFrom))
         setValue("dateTo", changeFormatDateTimeDBns(props.form1.dateTo))
      }
      if (props.form2) {
         console.log(props.form2)
         setValue("clientInfo", props.form2.clientInfo)
         setValue("typeService", props.form2.typeService)
         // na zyczenie Przemka
         
         setValue("extserviceid", props.form2.clientInfo.external_id ? props.form2.clientInfo.external_id : "")
         setValue("description_worker", props.form2.clientInfo.address ? props.form2.clientInfo.address : "")

      }

      if (props.form3) {
         setValue("masterServiceman", props.form3.masterServiceman)
         setValue("subServiceman", props.form3.subServiceman)


         if (props.form3.subServiceman && props.form3.subServiceman.length > 0) {
            setisSubServiceman(props.form3.subServiceman.length)
         } else {
            setisSubServiceman(0)
         }
      }
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps


   const renderHeader = () => {
      return (
         <>
            <span className="ql-formats">
               <button className="ql-bold" aria-label="Bold"></button>
               <button className="ql-italic" aria-label="Italic"></button>
               <button className="ql-underline" aria-label="Underline"></button>
               <button className="ql-list" aria-label="Ordered List" value="ordered"></button>
               <button className="ql-list" aria-label="Unordered List" value="bullet"></button>
            </span>

         </>
      );
   }

   const header = renderHeader();

   //templatki plików
   function getCommisionsFilesTemplate() {
      ConnectWS("/commisions/files_template", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {

            let tmparr = []
            tmparr.push({
               key: 0,
               text: "BRAK",
               value: JSON.stringify([])
            })
            response.data.forEach((row, i) => {
               tmparr.push({
                  key: i + 1,
                  text: row.name,
                  value: JSON.stringify(row.commision_files_template_def),

               },)
            })

            setFilesTemplate(tmparr);


         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         //onSelectionChanged()

      })
   }

   //templatki Opisów
   function getCommisionsTextTemplate() {
      ConnectWS("/commisions/fields_template", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {

            let tmparr = []
            tmparr.push({
               key: 0,
               text: "BRAK",
               value: JSON.stringify([])
            })
            response.data.forEach((row, i) => {
               tmparr.push({
                  key: i + 1,
                  text: row.name,
                  value: JSON.stringify(row.commision_fields_template_def),

               },)
            })

            setTextTemplate(tmparr);


         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         //onSelectionChanged()

      })
   }

   function createFilesList(arr) {
      if (JSON.parse(arr).length < 1) {
         setFormListFile({ ...formListFile, listFile: [] })
      } else {
         let arrtmp = [];
         JSON.parse(arr).forEach((row) => {
            arrtmp.push({ description: row.description, file_name: row.file_name, })
         })
         setFormListFile({ ...formListFile, listFile: arrtmp })

      }


   }
   function createFilesText(arr) {
      console.log(arr)
      if (JSON.parse(arr).length < 1) {
         setformListText({ ...formListText, listText: [] })
      } else {
         let arrtmp = [];
         JSON.parse(arr).forEach((row) => {
            arrtmp.push({ name: row.name })
         })
         setformListText({ ...formListText, listText: arrtmp })
      }


   }

   

   function closeModalFiles(){
      setopenModalAddFiles(false)
      authContext.changeUpdataApp(authContext.updataApp + 1)
      authContext.changeAppView({ appViewCategory: 'ZLECENIA-MAGAZYN', appView: 'commission_commissions', appModal: 0, appViewName: 'function_name', appViewIcon: 'wrench' });
            
   }

   return (
      <>   <Modal
         onClose={() => ""}
         open={openModalAddFiles}
         centered={false}
      >
         <Modal.Header>
            <ModalUI propsUiName={"Zakończ zlecenie"} />
         </Modal.Header>

         <Modal.Content className='bg'>
            <Message>
               <Message.Header>Dodaj pliki</Message.Header>
               <p>
                  Czy chcesz dodać pliki do zlecenia?
               </p>
            </Message>
            <AddFileCommisionToCommision commision_id={commision_id} />
         </Modal.Content>
         <Modal.Actions>
            <Button size='tiny' onClick={() => closeModalFiles()}>
               ZAMKNIJ
            </Button>

         </Modal.Actions>
      </Modal>
         <Segment className='bgcard'>
            <Label color='blue' ribbon style={{ marginBottom: 10 }}>
               Zlecenie
            </Label>
            <Grid verticalAlign='middle' centered stackable divided>
               <Grid.Row textAlign='center' columns={6} stretched>
                  <Grid.Column >
                     <Controller name="dateFrom" control={control} render={({ field }) => (
                        <Header as='h6' icon>
                           <Icon name='calendar' color='blue' />
                           Data rozpoczęcia serwisu
                           <Header.Subheader>
                              {field.value}
                           </Header.Subheader>
                        </Header>
                     )} />
                  </Grid.Column>
                  <Grid.Column >
                     <Controller name="dateTo" control={control} render={({ field }) => (
                        <Header as='h6' icon>
                           <Icon name='calendar' color='blue' />
                           Planowana data zakończenia serwisu
                           <Header.Subheader>
                              {field.value}
                           </Header.Subheader>
                        </Header>
                     )} />
                  </Grid.Column>
                  <Grid.Column>
                     <Controller name="clientInfo" control={control} render={({ field }) => (
                        <Header as='h6' icon>
                           <Icon name='users' color='blue' />
                           Klient
                           <Header.Subheader>
                              {field.value ? field.value.name : "?"}
                           </Header.Subheader>
                        </Header>
                     )} />
                  </Grid.Column>
                  <Grid.Column>
                     <Controller name="typeService" control={control} render={({ field }) => (
                        <Header as='h6' icon>
                           <Icon name='wrench' color='blue' />
                           Typ zlecenia
                           <Header.Subheader>
                              {field.value ? JSON.parse(field.value).description : ""}
                           </Header.Subheader>
                        </Header>
                     )} />
                  </Grid.Column>
                  <Grid.Column>
                     <Controller name="masterServiceman" control={control} render={({ field }) => (
                        <Header as='h6' icon>
                           <Icon.Group  >
                              <Icon name='user' color="blue" />
                              <Icon corner color="blue" name='wrench' loading size='small' />
                           </Icon.Group><br />
                           Główny serwisant
                           <Header.Subheader>
                              {field.value ? field.value.name : "?"}
                           </Header.Subheader>
                        </Header>
                     )} />
                  </Grid.Column>
                  <Grid.Column>
                     <Controller name="subServiceman" control={control} render={({ field }) => (
                        <Header as='h6' icon>
                           <Icon.Group  >
                              <Icon name='users' color={isSubServiceman > 0 ? 'blue' : "grey"} />
                              <Icon corner color={isSubServiceman > 0 ? 'blue' : "grey"} name='wrench' loading size='small' />
                           </Icon.Group><br />

                           Pozostali serwisanci
                           <Header.Subheader>
                              {isSubServiceman > 0 ?
                                 field.value.length > 0 ? field.value.map((row, i) => { return <div key={i}>{row.name}</div> }) : "?"
                                 : "Nie wybrani"}
                           </Header.Subheader>
                        </Header>

                     )} />
                  </Grid.Column>

               </Grid.Row>
            </Grid>
            <Divider />
            <Label color='blue' ribbon style={{ marginBottom: 10 }}>
               Szczegóły zlecenia
            </Label>
            <form onSubmit={handleSubmit(submit)} className="p-fluid">
               
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="name" control={control} rules={{ required: 'Nazwa jest wymagane' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Nazwa*</label>
                  </span>
                  {getFormErrorMessage('name')}
               </div>
               
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="extserviceid" control={control} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="extserviceid" className={classNames({ 'p-error': errors.extserviceid })}>Identyfikator zlecenia np JIRA</label>
                  </span>
                  {getFormErrorMessage('extserviceid')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="description_worker" control={control} rules={{ required: 'Adres usługi jest wymagany' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="description_worker" className={classNames({ 'p-error': errors.description_worker })}>Adres usługi*</label>
                  </span>
                  {getFormErrorMessage('description_worker')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-phone" />
                     <Controller name="overtime" control={control} render={({ field, fieldState }) => (
                        <Dropdown options={Overtime} id={field.name} value={field.value} onChange={(e) => {
                           field.onChange(e.value)
                        }} className={classNames({ 'p-invalid': fieldState.invalid })} placeholder="Nadgodziny" />

                     )} />
                     <label htmlFor="overtime" className={classNames({ 'p-error': errors.overtime })}>Nadgodziny</label>
                  </span>
                  {getFormErrorMessage('overtime')}
               </div>

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-phone" />
                     <Controller name="description" control={control} render={({ field, fieldState }) => (
                        <Editor headerTemplate={header} style={{ height: '320px' }} id={field.name} value={field.value} onTextChange={(e) => field.onChange(e.htmlValue)} className={classNames({ 'p-invalid': fieldState.invalid })} />

                     )} />
                     <label htmlFor="description" className={classNames({ 'p-error': errors.description })}></label>
                  </span>
                  {getFormErrorMessage('description')}
               </div>
               <Label color='blue' ribbon style={{ marginBottom: 20 }}>
                  Pliki do dodania przez instalatora
               </Label>
               <br />
               Szablon plików:
               <DropdownSemantic
                  style={{ marginBottom: 10 }}
                  placeholder='Szablon plików'
                  fluid
                  search

                  selection
                  onChange={(e, d) => createFilesList(d.value)}
                  options={filesTemplate}

               />

               <div className="p-inputgroup">
                  <ButtonPrime label="Dodaj plik do listy" icon="pi pi-plus" iconPos="right" type='button' onClick={() => addFileToList()} disabled={formListFile.fileNameTmp === null || formListFile.fileNameTmp.length < 3 || formListFile.fileNameTmpDB === null || formListFile.fileNameTmp.lengthDB < 3 ? true : false} className="p-button-secondary" />
                  <InputText placeholder="Opis pliku do wyświetlenia" value={formListFile.fileNameTmp} onChange={(e) => setFormListFile({ ...formListFile, fileNameTmp: e.target.value, fileNameTmpDB: normalizeFileName(e.target.value) })} />
                  <InputText placeholder="Nazwa pliku" value={formListFile.fileNameTmpDB} onChange={(e) => setFormListFile({ ...formListFile, fileNameTmpDB: normalizeFileName(e.target.value) })} />
               </div>

               <List divided >
                  {formListFile.listFile && formListFile.listFile.length > 0 ? formListFile.listFile.map((data, index) => {
                     return <List.Item style={{ position: "relative" }}>
                        <List.Icon name='file' size='large' verticalAlign='middle' />
                        <List.Content>
                           <List.Header >Opis pliku: {data.description}</List.Header>
                           <List.Description>Nazwa pliku: {data.file_name}</List.Description>
                        </List.Content>
                        <div className='actionFileCommisions cursorPointer'><Icon name="trash" onClick={() => removeFileToList(index)} /></div>
                     </List.Item>
                  })
                     : <Message>
                        <Message.Header>Brak plików</Message.Header>
                        <p>
                           Brak listy plików, które beda musieli dodać instalatorzy podczas kończenia serwisu.
                        </p>
                     </Message>}
               </List>

               <Label color='blue' ribbon style={{ marginBottom: 20 }}>
                  Opisy do dodania przez instalatora
               </Label>
               <br />
               Szablon opisów:
               <DropdownSemantic
                  style={{ marginBottom: 10 }}
                  placeholder='Szablon opisów'
                  fluid
                  search
                  selection
                  onChange={(e, d) => createFilesText(d.value)}
                  options={textTemplate}

               />

               <div className="p-inputgroup">
                  <ButtonPrime label="Dodaj nazwę do listy" icon="pi pi-plus" iconPos="right" type='button' onClick={() => addTextToList()} disabled={formListText.textDescription === null || formListText.textDescription.length < 3 ? true : false} className="p-button-secondary" />
                  <InputText placeholder="Nazwa do wyświetlenia" value={formListText.textDescription} onChange={(e) => setformListText({ ...formListText, textDescription: e.target.value})} />
               </div>

               <List divided >
               {formListText.listText && formListText.listText.length > 0 ? formListText.listText.map((data, index) => {
                     return <List.Item style={{ position: "relative" }}>
                        <List.Icon name='file' size='large' verticalAlign='middle' />
                        <List.Content>
                           <List.Header >Nazwa pola: {data.name}</List.Header>
                        </List.Content>
                        <div className='actionFileCommisions2 cursorPointer'><Icon name="trash" onClick={() => removeTextToList(index)} /></div>
                     </List.Item>
                  })
                     : <Message>
                        <Message.Header>Brak opisów</Message.Header>
                        <p>
                           Brak listy opisów, które beda musieli dodać instalatorzy podczas kończenia serwisu.
                        </p>
                     </Message>}
               </List>   


               <Label color='blue' ribbon style={{ marginBottom: 10 }}>
                  Zatwierdzenie zlecenia
               </Label>
               <ButtonPrime loading={isloading} type="submit" label="Dodaj zlecenie" className="mt-2 p-button" />
            </form>
         </Segment>



      </>
   );
}

export default AddSubCommision;