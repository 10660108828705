import React, { useEffect, useState, useContext } from 'react';
import { Button, Grid, Icon,  Modal, Segment } from 'semantic-ui-react';
import { AG_GRID_LOCALE_PL } from '../../../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { ConnectWS } from '../../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../../context/authContext';
import { cfgToast } from '../../../../myFunc';

import ModalUI from '../../../../layout/ModalUI';
import AGGridActivity from '../../../AGGrid/AGGridActivity';

import EditItem from './EditItem';
import AddItem from './AddItem';
import InfoItem from './InfoItem';
import ChangeWhItem from './ChangeWhItem';



function Items(props) {
   const authContext = useContext(AuthContext);
   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);

   const [open, setOpen] = useState(false)
   const [openType, setOpenType] = useState(0)
   const [propsUiNameData, setpropsUiNameData] = useState(null);


   const [isloading1, setisloading1] = useState(false) // eslint-disable-line no-unused-vars


   const [form, setForm] = useState({
      element_id: 0,
      allInfo: null
   });

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };



   function getAllItems() {
      setisloading1(true)
    //  const items = Array.from({ length: 100000 }).map((_, i) => ({ element_id: i+1,iddb:i+1,  name: `Nazwa ${i+1}`,  description: `Opis ${i+1}`,short_name:`sss ${i+1}`, ean_number: Math.floor(5000 * Math.random()),serial_number:Math.floor(5000 * Math.random()),jednostka:"asdasd",countm:Math.floor(Math.random()* 3),enabled: 1,warehouse:"ELA",itemblockcount:1}));
     // setRowData(items)
    //  return
      ConnectWS("/warehouse/warehouses_elements", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
              setRowData(response.data)

         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         onSelectionChanged()
         setisloading1(false)
      })
   }



   useEffect(() => {

      getAllItems()
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps


   const onSelectionChanged = () => {
      if (gridApi) {
         var selectedRows = gridApi.getSelectedRows();
         if (selectedRows.length === 1) {
            let element_id = selectedRows[0]["element_id"]
            setForm({ ...form, element_id: element_id, allInfo: selectedRows[0] })
         }
         else {
            setForm({ ...form, element_id: 0, allInfo: null })
         }
      }
   };






   function editItem() {

      if (form.element_id > 0) {
         setpropsUiNameData("Edycja elementu")
         setOpen(true)
         setOpenType(1)
      } else {
         toast.warning("Proszę zaznaczyć element w tabeli", cfgToast);
      }
   }

  

   function shwoInfo(data) {
      setpropsUiNameData("Informacje o elemencie")
      setForm({ ...form, element_id: 0, allInfo: data })
      setOpen(true)
      setOpenType(3)
   }

   function changeWhItem() {
      if (form.element_id > 0) {
         setpropsUiNameData("Edycja magazynu")
         setOpen(true)
         setOpenType(4)
      } else {
         toast.warning("Proszę zaznaczyć element w tabeli", cfgToast);
      }
   }


   function warehouseTMP(props){
      return  <span>{props.data.owner_client ? props.data.owner_client : props.data.owner_user ? props.data.owner_user : "n/n"}</span>
      }

      const iconItem = props => {
         return <Icon name='barcode' color='grey' size='large' className='cursorPointer' onClick={() => shwoInfo(props.data)} />
   
         //return <Icon name="user" color='grey' size='large' className='cursorPointer' onClick={() => shwoInfo(props.data)} />
      };


   return (
      <div style={{ minHeight: props.minHeight }}>
         <Modal
            onClose={() => setOpen(false)}
            open={open}
            centered={false}
         >
            <Modal.Header>
               <ModalUI propsUiName={propsUiNameData} />

            </Modal.Header>

            <Modal.Content className='bg'>
               {openType === 1 ? <EditItem userInfo={form} setOpen={setOpen}/> : openType === 2 ? <AddItem /> : openType === 3 ? <InfoItem minHeight={props.minHeight} userInfo={form} /> : openType === 4 ? <ChangeWhItem minHeight={props.minHeight} userInfo={form} setOpen={setOpen}/> : ""}
            </Modal.Content>
            <Modal.Actions>
               <Button size='tiny' onClick={() => setOpen(false)}>
                  ZAMKNIJ
               </Button>

            </Modal.Actions>
         </Modal>
         <Segment  >
            <Grid stackable style={{ minHeight: props.minHeight }}>
               <Grid.Row>
                  <Grid.Column >


                     <Grid className='clearmp' >
                        <Grid.Row className='clearmp' stretched >
                           <Grid.Column style={{ paddingLeft: 0 }} width={6}>
                              <Button fluid size='tiny' className='appbtnmtd' onClick={() => getAllItems()}>Pobierz elementy</Button>
                           </Grid.Column>
                           <Grid.Column style={{ paddingRight: 0 }} width={5}>
                              <Button fluid size='tiny' disabled={form.element_id > 0 ? false : true} className='appbtnmtd' color='blue' onClick={() => changeWhItem()}>Zmień magazyn</Button>
                           </Grid.Column>
                           <Grid.Column style={{ paddingRight: 0 }} width={5}>
                              <Button fluid size='tiny' disabled={form.element_id > 0 ? false : true} className='appbtnmtd' color='blue' onClick={() => editItem()}>Edytuj element</Button>
                           </Grid.Column>
                           {/*
                           <Grid.Column style={{ paddingRight: 0 }} width={4}>
                              <Button fluid size='tiny' className='appbtnmtd' color='blue' onClick={() => addItem()}>Dodaj element</Button>
                           </Grid.Column>
                           */}
                           
                        </Grid.Row >
                     </Grid>
                     <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
                     <div
                        id="myGrid"
                        style={{
                           height: props.minHeight - 100,
                           // height:  '100%',
                           width: '100%',
                           marginTop: 5
                        }}
                        className="ag-theme-balham"
                     >
                        <AgGridReact
                           //rowHeight={rowHeightAGG}
                           rowSelection={'single'}
                           onSelectionChanged={onSelectionChanged}
                           suppressRowClickSelection={true}

                           animateRows={true}
                           pagination={true}
                           defaultColDef={{
                              editable: false,
                              sortable: true,
                              filter: false,
                              width: 100,
                              minWidth: 100,
                        //przyśpiesza odświeżanie
                              //wrapText: true,
                              //autoHeight: true,
                              resizable: true,
                              floatingFilter: false,
                              flex: 1,
                           }}
                           frameworkComponents={{
                              iconItem: iconItem,
                              warehouseTMP: warehouseTMP,
                           }}
                           

                           getRowId={function (data) {
                              return data.data.warehouse_element_id
                           }}
                           localeText={AG_GRID_LOCALE_PL}
                           rowData={rowData}
                           onGridReady={onGridReady}
                        >
                           {/*
                           <AgGridColumn cellRenderer='warehouseTMP'
                              width={49}
                              minWidth={49}
                              flex={0}
                           />
                        */}
                        <AgGridColumn cellRenderer='iconItem'
                              width={49}
                              minWidth={49}
                              flex={0}
                           />
                         <AgGridColumn headerCheckboxSelection={true}
                              checkboxSelection={true} field="element_id" headerName='Identyfikator baza' filter='agTextColumnFilter' />
                           <AgGridColumn 
                              field="name" headerName='Nazwa' filter='agTextColumnFilter' />
                              <AgGridColumn field="short_name" headerName='Skrócona nazwa' filter='agTextColumnFilter' />
                           <AgGridColumn field="description" headerName='Opis' filter='agTextColumnFilter' />
                           <AgGridColumn field="ean_number" headerName='Ean' filter='agTextColumnFilter' />
                           <AgGridColumn field="serial_number" headerName='Numer seryjny' filter='agTextColumnFilter' />
                           <AgGridColumn field="unit" headerName='Jednostka' filter='agTextColumnFilter' />
                           <AgGridColumn field="quantity" headerName='Ilość sztuk w magazynie' filter='agNumberColumnFilter' />
                           <AgGridColumn field="quantity_reserves" headerName='Ilość zablokowana' filter='agNumberColumnFilter' />
                           <AgGridColumn cellRenderer='warehouseTMP' headerName='Magazyn' filter='agTextColumnFilter'
                            filterParams={ {
                              valueGetter: params => {
                                return params.data.owner_client ? params.data.owner_client : params.data.owner_user
                              }
                            }}
                           />       
                        </AgGridReact>
                     </div>
                  </Grid.Column>

               </Grid.Row>


            </Grid>



         </Segment>
      </div>
   );
}

export default Items;